import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import images from 'res'
import {mars700, sky500, assetServiceMap, assetApiUrlMap} from 'constant'

import {
  fetchGcpBuckets,
  fetchGcpFindingsByAsset,
  fetchGcpInstances,
  fetchGcpKms,
  fetchGcpApiKeys,
  fetchGcpPrincipals,
  fetchGcpServiceAccountKeys,
  fetchGcpSqlInstances,
} from 'actions'

import {
  apiKeysColumns,
  bucketsColumns,
  findingsColumns,
  instancesColumns,
  kmsColumns,
  principalsColumns,
  serviceAccountKeysColumns,
  sqlInstanceColumns,
} from './columns'

import Spinner from 'components/partials/Spinner'
import InnerTable from './InnerTable'
import config from 'config'

export default function AssetsTable(props) {
  const gcpApiKeysState = useSelector(state => state.gcpApiKeys)
  const gcpStatsState = useSelector(state => state.gcpStats)
  const gcpFindingsByAssetState = useSelector(state => state.gcpFindingsByAsset)
  const gcpBucketsState = useSelector(state => state.gcpBuckets)
  const gcpKmsState = useSelector(state => state.gcpKms)
  const gcpPrincipalsState = useSelector(state => state.gcpPrincipals)
  const gcpInstancesState = useSelector(state => state.gcpInstances)
  const gcpServiceAccountKeysState = useSelector(
    state => state.gcpServiceAccountKeys
  )
  const gcpSqlInstancesState = useSelector(state => state.gcpSqlInstances)

  const dispatch = useDispatch()

  const [openAccordion, setOpenAccordion] = useState('')
  const [tabs, setTabs] = useState({})

  const toggleAccordion = asset => {
    openAccordion === asset ? setOpenAccordion('') : setOpenAccordion(asset)
  }

  const handleTab = (eventTarget, asset) => {
    if (eventTarget === tabs[asset]) return

    setTabs({...tabs, [asset]: eventTarget})
  }

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {getTableProps, getTableBodyProps, prepareRow, page} = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 10,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  function fetchAssets(asset) {
    /*
     * Every time a new asset is included it must be also added to the
     * reducer's listener CLEAR_GCP_ASSETS_DATA, so the props gets cleared
     * when the user goes back to the GCP overview.
     */

    switch (asset) {
      case 'apiKeys':
        !gcpApiKeysState && dispatch(fetchGcpApiKeys(props.projectId))
        break
      case 'storageBuckets':
        !gcpBucketsState && dispatch(fetchGcpBuckets(props.projectId))
        break
      case 'kmsKeys':
        !gcpKmsState && dispatch(fetchGcpKms(props.projectId))
        break
      case 'principals':
        !gcpPrincipalsState && dispatch(fetchGcpPrincipals(props.projectId))
        break
      case 'vmInstances':
        !gcpInstancesState && dispatch(fetchGcpInstances(props.projectId))
        break
      case 'serviceAccountKeys':
        !gcpServiceAccountKeysState &&
          dispatch(fetchGcpServiceAccountKeys(props.projectId))
        break
      case 'sqlInstances':
        !gcpSqlInstancesState && dispatch(fetchGcpSqlInstances(props.projectId))
        break
      default:
        return
    }
  }

  function handleRowClick(asset) {
    if (
      !gcpFindingsByAssetState ||
      (gcpFindingsByAssetState && !gcpFindingsByAssetState[asset])
    )
      dispatch(
        fetchGcpFindingsByAsset({
          projectId: props.projectId,
          asset,
        })
      )
    fetchAssets(asset)

    // set the findings tab as default on row click
    handleTab('findings', asset)

    toggleAccordion(asset)
  }

  const innerTableProps = {
    apiKeys: {
      data: gcpApiKeysState || [],
      columns: useMemo(() => apiKeysColumns, []),
    },
    storageBuckets: {
      data: gcpBucketsState || [],
      columns: useMemo(() => bucketsColumns, []),
    },
    kmsKeys: {
      data: gcpKmsState || [],
      columns: useMemo(() => kmsColumns, []),
    },
    principals: {
      data: gcpPrincipalsState || [],
      columns: useMemo(() => principalsColumns, []),
    },
    vmInstances: {
      data: gcpInstancesState || [],
      columns: useMemo(() => instancesColumns, []),
    },
    findings: {columns: useMemo(() => findingsColumns, [])},
    serviceAccountKeys: {
      data: gcpServiceAccountKeysState || [],
      columns: useMemo(() => serviceAccountKeysColumns, []),
    },
    sqlInstances: {
      data: gcpSqlInstancesState || [],
      columns: useMemo(() => sqlInstanceColumns, []),
    },
  }

  return (
    <div>
      <div className="tableWrap relative">
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    onClick={() => handleRowClick(row.original.asset)}
                    {...row.getRowProps()}
                    className="flex flex-wrap w-full rounded-lg border-2 border-sideral-50 my-2"
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'name') {
                        return (
                          <td
                            className="uppercase flex cursor-pointer text-left text-sideral-700 font-medium text-sm items-center p-4"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? cell.render('Cell') : '-'}
                          </td>
                        )
                      }
                      if (cell.column.id === 'findings') {
                        return (
                          <td
                            className="flex cursor-pointer items-center p-4 text-sm"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <figure className="pr-1">
                              <images.FindingIcon width="20" />
                            </figure>
                            {typeof cell.row.original.findings === 'number'
                              ? cell.row.original.findings
                              : '-'}{' '}
                            {cell.value && cell.row.original.findings > 1
                              ? i18next.t('misc.findings').toLowerCase()
                              : i18next.t('misc.finding').toLowerCase()}
                          </td>
                        )
                      }
                      if (cell.column.id === 'chevron')
                        return (
                          <td
                            className="flex cursor-pointer items-center w-20 p-4"
                            key={uuidv4()}>
                            {latestCheckFailed({
                              assetName: row.original.asset,
                              gcpStatsState,
                              projectId: props.projectId,
                            }) ? (
                              <figure className="mr-4">
                                <images.WarningIcon
                                  width="20"
                                  color={mars700}
                                />
                              </figure>
                            ) : (
                              <></>
                            )}
                            <figure className="ml-auto">
                              <images.Chevron
                                width="12"
                                color={sky500}
                                direction={
                                  openAccordion === row.original.asset
                                    ? 'up'
                                    : 'down'
                                }
                              />
                            </figure>
                          </td>
                        )
                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}

                    {openAccordion === row.original.asset ? (
                      <AccordionContent
                        tabs={tabs}
                        row={row}
                        innerTableProps={innerTableProps}
                        handleTab={handleTab}
                        props={props}
                      />
                    ) : (
                      <></>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

function AccordionContent({tabs, row, innerTableProps, handleTab, props}) {
  const gcpStatsState = useSelector(state => state.gcpStats)
  const gcpFindingsByAssetState = useSelector(state => state.gcpFindingsByAsset)

  const serviceIsLoading = asset => {
    return !gcpFindingsByAssetState || !gcpFindingsByAssetState[asset]
  }

  function getFindingsData(asset) {
    return (gcpFindingsByAssetState && gcpFindingsByAssetState[asset]) || []
  }

  return (
    <>
      {serviceIsLoading(row.original.asset) ? (
        <td className="w-full -mt-4">
          <Spinner />
        </td>
      ) : (
        <>
          {latestCheckFailed({
            assetName: row.original.asset,
            gcpStatsState,
            projectId: props.projectId,
          }) ? (
            <td
              className="bg-astral-50 border-t-2 border-sideral-50 w-full p-10"
              onClick={e => e.stopPropagation()}>
              <CheckFailed
                assetName={row.original.asset}
                integrationId={props.integrationId}
                log={getServiceLog({
                  gcpStatsState,
                  projectId: props.projectId,
                  assetName: row.original.asset,
                })}
              />
            </td>
          ) : (
            <td
              className="relative bg-astral-50 border-t-2 border-sideral-50 w-full p-4"
              onClick={e => e.stopPropagation()}>
              <div className="flex text-sky-500 text-sm mb-4">
                <button
                  value="findings"
                  className={`${
                    tabs[row.original.asset] === 'findings' ||
                    !tabs[row.original.asset]
                      ? ' border-sky-500 text-sky-900'
                      : 'border-transparent'
                  } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                  onClick={e => {
                    handleTab(e.target.value, row.original.asset)
                  }}>
                  {i18next.t('misc.findings')}
                </button>
                <button
                  value="assets"
                  className={`${
                    tabs[row.original.asset] === 'assets'
                      ? ' border-sky-500 text-sky-900'
                      : 'border-transparent'
                  } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                  onClick={e => {
                    handleTab(e.target.value, row.original.asset)
                  }}>
                  {i18next.t('misc.assets')}
                </button>
              </div>
              <div className="flex flex-wrap w-full">
                {tabs[row.original.asset] === 'assets' && (
                  <>
                    <InnerTable
                      data={innerTableProps[row.original.asset].data}
                      columns={innerTableProps[row.original.asset].columns}
                      tab={tabs[row.original.asset]}
                      rowId={row.original.asset}
                    />
                  </>
                )}{' '}
                {tabs[row.original.asset] === 'findings' && (
                  <>
                    <InnerTable
                      data={getFindingsData(row.original.asset)}
                      columns={innerTableProps.findings.columns}
                      tab={tabs[row.original.asset]}
                      rowId={row.original.asset}
                    />
                  </>
                )}
              </div>
            </td>
          )}
        </>
      )}
    </>
  )
}

function getServiceLog({assetName, gcpStatsState, projectId}) {
  return gcpStatsState[projectId]?.latestCheck?.logs[
    assetServiceMap.gcp[assetName]
  ]
}

function latestCheckFailed({assetName, gcpStatsState, projectId}) {
  const log = getServiceLog({assetName, gcpStatsState, projectId})
  if (log?.code !== 'ok') return true
}

function CheckFailed({assetName, log, integrationId}) {
  const errorCorde = log?.code
  let errorContent = ''

  switch (errorCorde) {
    case 'DISABLED_API':
      errorContent = (
        <>
          <div className="mx-1">
            {i18next.t('cloud.gcp.errors.disabledApi.accordionWarningMessage')}
          </div>
          <Link
            to={assetApiUrlMap.gcp[assetName]}
            target={'_blank'}
            className="underline font-light">
            {assetApiUrlMap.gcp[assetName]}
          </Link>
        </>
      )

      break

    default:
      errorContent = (
        <>
          <div>{i18next.t('errors.checkPermissions')}</div>
          <Link
            to={`${config.CLIENT_URL}/cloud/gcp/${integrationId}/update`}
            target={'_blank'}
            className="underline font-light">
            {`${config.CLIENT_URL}/cloud/gcp/${integrationId}/update`}
          </Link>
        </>
      )
  }

  return (
    <>
      <div className="items-center text-center text-mars-500">
        {errorContent}
      </div>
    </>
  )
}

AccordionContent.propTypes = {
  handleTab: PropTypes.func,
  innerTableProps: PropTypes.object,
  projectId: PropTypes.string,
  integrationId: PropTypes.string,
  props: PropTypes.object,
  row: PropTypes.object,
  tabs: PropTypes.object,
}

AssetsTable.propTypes = {
  projectId: PropTypes.string,
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  integrationId: PropTypes.string,
}

CheckFailed.propTypes = {
  assetName: PropTypes.string,
  log: PropTypes.object,
  integrationId: PropTypes.string,
}
