import i18next from 'i18next'
import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import {clearNameServerDetails, fetchNameServerDetails} from 'actions'
import images from 'res'
import {parseAndJoin} from '../utils'
import {sideral300} from 'constant'

import BackButton from 'components/partials/buttons/BackButton'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import NameServerDetailsTable from './nameServerDetailsTable'
import TableWrapper from 'components/partials/tables/TableWrapper'
import Spinner from 'components/partials/Spinner'
import {Page} from 'components/partials/Page'
import Timestamp from 'components/partials/Timestamp'

export default function NameServerDetails() {
  const dispatch = useDispatch()
  const nameServerDetails = useSelector(state => state.nameServerDetails)
  const params = useParams()

  useEffect(() => {
    if (params.id) dispatch(fetchNameServerDetails(params.id))

    return () => dispatch(clearNameServerDetails())
  }, [])

  const nameServerDetailsColumns = useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name').toUpperCase(),
            accessor: 'name',
            width: 80,
          },
          {
            Cell: cell => parseAndJoin(cell.value),
            Header: i18next.t('tables.value').toUpperCase(),
            accessor: 'data',
          },
          {
            Cell: cell => <Timestamp date={new Date(cell.value)} fromNow />,
            Header: i18next.t('tables.seen').toUpperCase(),
            accessor: 'lastResolution',
            width: 80,

            sortType: (a, b) => {
              return (
                new Date(a.original.lastResolution) -
                new Date(b.original.lastResolution)
              )
            },
          },
        ],
      },
    ],
    []
  )

  if (!nameServerDetails) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.dns')}>
      <div className="flex w-full rounded-lg border border-sideral-100 bg-white font-medium text-sideral-700 p-4">
        <images.DnsIcon width="50" color="#90A4AE" />
        <div className="flex flex-col justify-between flex-wrap pl-4">
          <BackButton fallbackLink={'/dns'} />
          <p className="w-full">
            {parseAndJoin(nameServerDetails.nameServerCategory)}
          </p>
        </div>
      </div>

      <TableWrapper
        title={i18next.t('dns.nameServerDnsRecords')}
        loaded={nameServerDetails && nameServerDetails.data}
        table={
          <NameServerDetailsTable
            columns={nameServerDetailsColumns}
            data={nameServerDetails.data}
            pageNumber={0}
            pageSize={10}
            pageIndex={0}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin={'m-2 mt-4'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin={'m-2 mt-4'}
              />
            }
          />
        }
        margin="mt-4"
      />
    </Page>
  )
}
