import React, {useRef, useEffect, useState, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {v4 as uuidv4} from 'uuid'
import i18next from 'i18next'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

import {tableStyles, sky500, tooltipStyle} from 'constant'
import {getSeverityString} from 'utils/score'
import {urlContainsWildcard} from 'utils'
import InfoIcon from 'res/icons/infoIcon'
import Spinner from 'components/partials/Spinner'

import ExternalLink from 'res/icons/externalLink'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import PropTypes from 'prop-types'
import ScoreBadge from 'components/partials/ScoreBadge'

const WebAppsTable = props => {
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [items, setItems] = useState([])
  const [dynamicHeight, setDynamicHeight] = useState('156')
  const [loading, setLoading] = useState(true)

  const tableRef = useRef()

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    pageCount,

    canPreviousPage,
    canNextPage,

    gotoPage,

    nextPage,
    previousPage,

    preGlobalFilteredRows,
    setGlobalFilter,

    state: {pageIndex, sortBy},
  } = useTable(
    {
      columns: props.columns,
      data: items,
      defaultColumn,

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: props.pageCount,

      initialState: {
        pageIndex: props.pageIndex || 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const refreshTableData = (filterValue, sortBy) => {
    props.fetchData({
      filterValue,
      pageIndex: pageIndex,
      pageSize: props.pageSize,
      sortBy,
    })
  }

  const debouncedRefreshTable = useMemo(() => {
    return AwesomeDebouncePromise(refreshTableData, 1000)
  }, [])

  const customHandleFilter = e => {
    setLoading(true)

    const searchValue = e.target.value

    gotoPage(0)
    props.setFilterValue(searchValue)
    setGlobalFilter(searchValue)

    debouncedRefreshTable(searchValue, sortBy)
  }

  const handlePreviousPage = () => {
    setLoading(true)

    props.fetchData({
      filterValue: props.filterValue,
      pageIndex: pageIndex - 1,
      pageSize: props.pageSize,
      sortBy,
    })

    previousPage()
  }

  const handleNextPage = () => {
    setLoading(true)

    props.fetchData({
      filterValue: props.filterValue,
      pageIndex: pageIndex + 1,
      pageSize: props.pageSize,
      sortBy,
    })

    nextPage()
  }

  useEffect(() => setFirstPageLoad(false), [])
  useEffect(() => setLoading(false), [props.data])

  useEffect(() => {
    setLoading(false)
    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    if (tableRef && tableRef.current)
      setDynamicHeight(tableRef.current.clientHeight)
  }, [items])

  useEffect(() => {
    if (!firstPageLoad) {
      setLoading(true)
      props.setSortOptions(sortBy)
      refreshTableData(props.filterValue, sortBy)
    }
  }, [sortBy])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('protocol')) {
      props.setFilterValue(params.get('protocol'))
      setGlobalFilter(params.get('protocol'))
    }
  }, [])

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex flex-wrap justify-end sm:justify-between items-center pb-2">
          <div className="w-full sm:w-auto order-last sm:order-none mt-2 sm:mt-0">
            <GlobalFilter
              globalFilter={props.filterValue}
              customHandleFilter={customHandleFilter}
              preGlobalFilteredRows={preGlobalFilteredRows}
              filterWidth="w-full sm:w-64"
            />
          </div>

          <Pagination
            handlePrevious={handlePreviousPage}
            handleNext={handleNextPage}
            canPreviousPage={loading ? false : canPreviousPage}
            canNextPage={loading ? false : canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={props.total}
          />
        </div>
        {page && page.length && !loading ? (
          <div className="overflow-x-auto">
            <table ref={tableRef} {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()}></tr>

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => {
                        if (column.hidden) {
                          return <th key={uuidv4()}></th>
                        }

                        if (column.id === 'final') {
                          return (
                            <th
                              className="dm-mono text-left text-sideral-300 text-sm pl-10"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={uuidv4()}>
                              {column.render('Header')}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' ↓'
                                    : ' ↑'
                                  : ''}
                              </span>
                            </th>
                          )
                        }
                        return (
                          <th
                            className="dm-mono text-sideral-300 text-sm"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={uuidv4()}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' ↓'
                                  : ' ↑'
                                : ''}
                            </span>
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={tableStyles.tableRow}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (cell.column.hidden) return <td key={uuidv4()}></td>

                        if (cell.column.id === 'final')
                          return (
                            <td
                              className="flex group ml-4 text-left"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <div className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
                                <Link
                                  to={`/web/app/${cell.row.original.id}`}
                                  className="break-normal">
                                  {cell.value}
                                </Link>
                              </div>
                              <div
                                className={`hidden group-hover:block text-xs px-2 pt-1`}>
                                {urlContainsWildcard(cell.value) ? (
                                  <div className="tooltip -mt-1">
                                    <InfoIcon width="20" color={sky500} />
                                    <div
                                      className={`${tooltipStyle.default} ml-6 -mt-5`}>
                                      {i18next.t('web.wildcardTooltip')}
                                    </div>
                                  </div>
                                ) : (
                                  <a
                                    href={cell.value}
                                    rel="noreferrer noopener"
                                    target="_blank">
                                    <ExternalLink width="12" color={sky500} />
                                  </a>
                                )}
                              </div>
                            </td>
                          )

                        if (cell.column.id === 'score') {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className="flex items-center justify-center">
                              <ScoreBadge letter={cell.value} />
                            </td>
                          )
                        }

                        if (
                          cell.column.id === 'severity' &&
                          cell.value &&
                          typeof cell.value === 'number'
                        )
                          return (
                            <td
                              className="text-center pr-2 capitalize"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {getSeverityString(cell.value)}
                            </td>
                          )

                        return (
                          <td
                            className={
                              cell.column.classes
                                ? cell.column.classes
                                : 'flex items-center justify-center truncate break-word pr-2'
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {typeof cell.value === 'undefined' ||
                            cell.value === null
                              ? '—'
                              : cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : loading ? (
          <div
            className="flex items-center justify-center"
            style={{height: `${dynamicHeight}px`}}>
            <Spinner />
          </div>
        ) : (
          <>
            {props.filterValue
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

WebAppsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  setSortOptions: PropTypes.func,
  total: PropTypes.number,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  fetchData: PropTypes.func,
  defaultSorted: PropTypes.array,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  paginationClass: PropTypes.string,
}

export default WebAppsTable
