import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {i18next} from 'translate/i18n'
import {getDisplayName} from 'utils'
import {useParams} from 'react-router-dom'

import {fetchCompanyMembers, clearSuccess, loading} from 'actions'

import BackButton from 'components/partials/buttons/BackButton'
import ContentBox from 'components/partials/ContentBox'
import FindingAssignment from 'components/partials/FindingAssignment'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import Spinner from 'components/partials/Spinner'
import ChangeFindingStatusModal from './ChangeFindingStatusModal'
import {Page} from 'components/partials/Page'
import Timestamp from 'components/partials/Timestamp'

export default function GenericFinding(props) {
  const csrfState = useSelector(state => state.csrf)
  const successState = useSelector(state => state.success)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()
  const params = useParams()

  const [currentAssignee, setCurrentAssignee] = useState({})
  const [status, setStatus] = useState(null)
  const [newStatus, setNewStatus] = useState('')
  const [modalVisibility, setModalVisibility] = useState('hidden')

  const toggleModal = () => {
    modalVisibility === 'hidden'
      ? setModalVisibility('block')
      : setModalVisibility('hidden')
  }

  const handleSelectChange = event => {
    const {value} = event

    if (value === props.finding.status) return

    if (value === 'open') {
      //modal should not open if newStatus value is open
      setNewStatus(value)

      dispatch(loading({updatePeopleFinding: true}))

      return dispatch(
        props.updateFinding({
          findingId: props.finding.id,
          updateStatusMessage: getUpdateStatusMessage(
            props.finding.status,
            'open'
          ),
          reason: {
            option: 'open',
            description: 'open',
          },
          status: 'open',
          _csrf: csrfState,
        })
      )
    }

    toggleModal()
    setNewStatus(value)
  }

  const getStatusLabel = value => {
    return i18next.t(`status.${value}`)
  }

  const getUpdateStatusMessage = (prev, current) => {
    return `${i18next.t('finding.statusUpdatedFrom')} ${i18next.t(
      `status.${prev}`
    )} ${i18next.t('misc.to')} ${i18next.t(`status.${current}`)}`
  }

  useEffect(() => {
    if (successState && modalVisibility !== 'hidden')
      setModalVisibility('hidden')
  }, [successState, loadingState.updatePeopleFinding])

  useEffect(() => {
    if (params.id) {
      dispatch(props.fetchFinding(params.id))
      dispatch(props.fetchFindingActivity(params.id))
    }
  }, [dispatch, params.id])

  useEffect(() => {
    if (!props.finding) return

    setStatus({
      value: props.finding.status,
      label: getStatusLabel(props.finding.status),
    })
  }, [props.finding])

  useEffect(() => {
    dispatch(clearSuccess())
    dispatch(fetchCompanyMembers())
  }, [])

  const Activity = () => {
    return (
      <div className="py-4 px-2 mb-2">
        {props.findingActivity &&
          props.findingActivity.map(action => {
            if (action.type === 'assign') {
              return (
                <div
                  className="flex border-b last:border-none py-3"
                  key={action.createdAt}>
                  <div className="w-1/2">
                    <span className="mr-1">
                      {getDisplayName(action.firstName, action.lastName)}
                    </span>
                    {action.assignedToName?.trim() ? (
                      <>
                        {i18next.t('finding.assignedTo')}
                        <span className="font-medium mx-1">
                          {action.assignedToName}
                        </span>
                      </>
                    ) : (
                      <>{i18next.t('finding.unassign')}</>
                    )}
                  </div>
                  <div className="w-1/2 text-right">
                    <span className="text-xs">
                      <Timestamp date={new Date(action.createdAt)} fromNow />
                    </span>
                  </div>
                </div>
              )
            }

            if (action.type === 'status') {
              return (
                <div
                  className="flex border-b last:border-none py-3"
                  key={action.createdAt}>
                  <div className="w-1/2">
                    {getDisplayName(action.firstName, action.lastName)}
                    {i18next.t('finding.movedFrom')}
                    <span className="font-medium mx-1">
                      {i18next.t(
                        `status.${
                          action.previousState ||
                          action.previousStatus ||
                          action.previous
                        }`
                      )}
                    </span>
                    {i18next.t('misc.to')}
                    <span className="font-medium mx-1">
                      {i18next.t(
                        `status.${
                          action.newState || action.newStatus || action.new
                        }`
                      )}
                    </span>
                    {action.newStatus === 'closed' && action.justification ? (
                      <div className="mt-2">
                        <span className="font-medium mr-1">
                          {i18next.t('misc.justification')}:
                        </span>
                        <span className="font-light">
                          {action.justification}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="w-1/2 text-right">
                    <span className="text-xs">
                      <Timestamp date={new Date(action.createdAt)} fromNow />
                    </span>
                  </div>
                </div>
              )
            }
          })}
      </div>
    )
  }
  return (
    <Page pageTitle={i18next.t('pageTitles.people')} helpPath="people">
      {!props.finding ? <Spinner /> : <></>}

      {props.finding ? (
        <>
          <div className="rounded-lg border border-sideral-100 mb-6">
            <div
              className={
                props.findingDetails
                  ? 'border-b border-sideral-100 flex'
                  : 'border-sideral-100 flex'
              }>
              {props.findingIcon()}
              <div className="w-11/12 p-4 md:flex">
                <div className="w-2/3">
                  <BackButton
                    fallbackLink={props.fallbackLink || '/management'}
                  />
                  <div className="capitalize-first max-w-md font-medium text-lg pt-2">
                    {props.finding.title}
                  </div>
                </div>

                <div className="w-1/3 md:mt-0 mt-8">
                  <div className="flex float-right">
                    <FindingAssignment
                      currentAssignee={currentAssignee}
                      finding={props.finding}
                      findingType={props.findingType}
                      setCurrentAssignee={setCurrentAssignee}
                      fetchFinding={props.fetchFinding}
                      fetchFindingActivity={props.fetchFindingActivity}
                      params={params}
                    />
                    <div>
                      <PrimarySelect
                        className="text-sm"
                        marginBottom={8}
                        name="status"
                        width="16rem"
                        options={props.allowedStatuses}
                        onChange={handleSelectChange}
                        padding={1}
                        text={i18next.t('labels.status')}
                        value={status}
                        disabled={props.finding.status === 'closed'}
                      />
                      <ChangeFindingStatusModal
                        modalVisibility={modalVisibility}
                        setModalVisibility={setModalVisibility}
                        history={history}
                        finding={props.finding}
                        newFindingStatus={newStatus}
                        updateFinding={props.updateFinding}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.findingDetails(props.finding)}
          </div>

          <div className="template-markdown">
            {props.findingBody(props.finding)}
          </div>

          {props.findingActivity && props.findingActivity.length ? (
            <div className="template-markdown mb-6">
              <ContentBox
                header={i18next.t('titles.activity')}
                content={<Activity />}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Page>
  )
}

BackButton.propTypes = {
  fallbackLink: PropTypes.string,
}

GenericFinding.propTypes = {
  allowedStatuses: PropTypes.array,
  fallbackLink: PropTypes.string,
  fetchFindingActivity: PropTypes.func,
  fetchFinding: PropTypes.func,
  finding: PropTypes.object,
  findingActivity: PropTypes.array,
  findingBody: PropTypes.any,
  findingDetails: PropTypes.any,
  findingIcon: PropTypes.any,
  findingType: PropTypes.string,
  history: PropTypes.object,
  updateFinding: PropTypes.func,
}
