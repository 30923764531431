import i18next from 'i18next'
import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {clearAction} from 'actions'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import {sky500} from 'constant'
import images from 'res'
import TrashIcon from 'res/icons/trashIcon'
import DocumentsDownloadModal from './DocumentsDownloadModal'
import DocumentRemoveModal from './DocumentRemoveModal'
import {getDocumentDate, getDocumentTitle} from 'utils'
import {useDispatch, useSelector} from 'react-redux'
import DocumentRemoveFeedbackModal from './DocumentRemoveFeedbackModal'
import Timestamp from 'components/partials/Timestamp'

const DocumentsList = ({data}) => {
  const dispatch = useDispatch()

  const removeDocumentFeedbackState = useSelector(
    state => state.removeDocumentFeedback
  )

  const [removeDocumentModal, setRemoveDocumentModal] = useState({
    selected: null,
    showModal: false,
  })

  const [removeDocumentFeedbackModal, setRemoveDocumentFeedbackModal] =
    useState({
      selected: null,
      showModal: false,
    })

  const [downloadDocumentModal, setDownloadDocumentModal] = useState({
    selected: null,
    showModal: false,
  })

  const onClickDownload = id => {
    setDownloadDocumentModal({
      selected: {id},
      showModal: true,
    })
  }

  const handleDisplayRemovalModal = ({id, name, documentDate}) => {
    setRemoveDocumentModal({
      selected: {id, name},
      showModal: true,
    })

    setRemoveDocumentFeedbackModal({
      selected: {name, documentDate},
      showModal: false,
    })
  }

  useEffect(() => {
    if (removeDocumentFeedbackState?.questionnaires?.length > 0) {
      setRemoveDocumentFeedbackModal({
        selected: {
          ...removeDocumentFeedbackModal.selected,
          questionnaires: removeDocumentFeedbackState?.questionnaires,
        },
        showModal: true,
      })
    }

    // clear state after leaving the page
    return () => {
      dispatch(clearAction('removeDocumentFeedback'))
    }
  }, [removeDocumentFeedbackState])

  const columns = useMemo(
    () => [
      {
        id: 'documentType',
        accessorKey: 'data',
        header: i18next.t('documents.table.document'),
        minSize: 250,
        cell: cell => {
          const consultancy = cell.row.original.data.consultancy
          const documentTitle = getDocumentTitle(cell.row.original.type)

          return (
            <div>
              {documentTitle}
              {consultancy && (
                <div className="flex text-xs text-sideral-400">
                  {consultancy}
                </div>
              )}
            </div>
          )
        },
        meta: {
          align: 'left',
        },
      },

      {
        id: 'documentDate',
        accessorKey: 'data',
        header: i18next.t('documents.table.documentDate'),
        cell: cell => (
          <Timestamp
            date={getDocumentDate(
              cell.row.original.type,
              cell.row.original.data
            )}
            format="ll"
          />
        ),
        enableSorting: false,
      },

      {
        accessorKey: 'userFirstName',
        header: i18next.t('documents.table.uploadedBy'),
        cell: cell => {
          const firstName = cell.row.original.userFirstName
          const lastName = cell.row.original.userLastName

          const fullName = lastName ? `${firstName} ${lastName}` : firstName

          return fullName
        },
      },

      {
        accessorKey: 'createdAt',
        header: i18next.t('documents.table.uploadDate'),
        cell: cell => <Timestamp date={cell.getValue()} fromNow />,
      },

      {
        id: 'actionButtons',
        accessorKey: 'id',
        header: '',
        maxSize: 10,
        cell: cell => (
          <div className="flex items-center justify-end z-100">
            <div className="mx-2">
              <button
                onClick={() => onClickDownload(cell.getValue())}
                title={i18next.t('documents.table.downloadFile')}>
                <images.DownloadIcon height="28" width="28" color={sky500} />
              </button>
            </div>

            <div className="mx-2">
              <button
                onClick={() => {
                  const documentTitle = getDocumentTitle(cell.row.original.type)

                  handleDisplayRemovalModal({
                    id: cell.getValue(),
                    name: documentTitle,
                    documentDate: getDocumentDate(
                      cell.row.original.type,
                      cell.row.original.data
                    ),
                  })
                }}
                title={i18next.t('misc.remove')}>
                <TrashIcon height="22" width="22" color={sky500} />
              </button>
            </div>
          </div>
        ),
      },

      {
        accessorKey: 'data.consultancy',
        id: 'consultancy',
      },
    ],
    []
  )

  const columnVisibility = {consultancy: false}

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={data || []}
        columnVisibility={columnVisibility}
        emptyStateWithFilter={
          <GenericEmptyState
            icon={
              <i className={`icofont-ui-folder text-6xl text-sideral-300`} />
            }
            title={i18next.t('tables.nothingFoundWithSearch')}
            body={i18next.t('tables.searchSomethingElse')}
          />
        }
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={
              <i className={`icofont-ui-folder text-6xl text-sideral-300`} />
            }
            title={i18next.t('documents.noResultsTitle')}
            body={i18next.t('documents.noResultsDescription')}
          />
        }
      />

      {downloadDocumentModal.showModal && (
        <DocumentsDownloadModal
          downloadDocumentModal={downloadDocumentModal}
          setDownloadDocumentModal={setDownloadDocumentModal}
        />
      )}

      {removeDocumentModal.showModal && (
        <DocumentRemoveModal
          removeDocumentModal={removeDocumentModal}
          setRemoveDocumentModal={setRemoveDocumentModal}
        />
      )}

      {removeDocumentFeedbackModal.showModal && (
        <DocumentRemoveFeedbackModal
          removeDocumentModal={removeDocumentFeedbackModal}
          setRemoveDocumentModal={setRemoveDocumentFeedbackModal}
        />
      )}
    </div>
  )
}

export default DocumentsList

DocumentsList.propTypes = {
  data: PropTypes.array,
}
