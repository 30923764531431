import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {respondSupplierInvite} from 'actions'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Timestamp from 'components/partials/Timestamp'

export default function SupplierInvites({invites}) {
  const dispatch = useDispatch()
  const csrfState = useSelector(state => state.csrf)

  const handleInvite = (id, status) => {
    dispatch(
      respondSupplierInvite({
        sessionId: id,
        status,
        _csrf: csrfState,
      })
    )
  }

  return (
    <div className="p-6">
      {invites &&
        invites.map(invite => (
          <div
            key={invite.id}
            className="flex items-center justify-between border-b pb-6 mb-6 last:border-none last:pb-0 last:mb-0">
            <div className="flex items-center">
              <div className="mr-4">
                {invite.companyLogo ? (
                  <img
                    src={invite.companyLogo}
                    alt="Company logo"
                    className="w-10"
                  />
                ) : (
                  <i className="icofont-building-alt opacity-50 text-4xl"></i>
                )}
              </div>
              <div>
                <div className="font-normal">
                  <Trans
                    i18nKey="suppliers.invites.acceptSupplierOfCompany"
                    values={{
                      companyName: invite.companyName,
                    }}
                  />
                </div>
                <div className="text-sm text-sideral-400">
                  <Trans
                    i18nKey="suppliers.invites.description"
                    values={{
                      name: invite.senderFirstName,
                    }}
                  />
                  <Timestamp date={invite.contactCreatedAt} fromNow />
                </div>
              </div>
            </div>
            <div className="flex">
              <PrimaryButton
                text={i18next.t('suppliers.invites.acceptBtn')}
                size="sm"
                margin="mr-2"
                iconClass="icofont-check text-xl"
                onClick={() => handleInvite(invite.id, 'accepted')}
              />
              <PrimaryButton
                text={i18next.t('suppliers.invites.declineBtn')}
                theme="blue-outline"
                size="sm"
                iconClass="icofont-error text-lg"
                onClick={() => handleInvite(invite.id, 'rejected')}
              />
            </div>
          </div>
        ))}
    </div>
  )
}

SupplierInvites.propTypes = {
  invites: PropTypes.array,
}
