import PropTypes from 'prop-types'
import React, {useState} from 'react'
import i18next from 'i18next'
import {useSelector, useDispatch} from 'react-redux'

import {integrateGcp, loading, genericError} from 'actions'
import {
  awsAccountId,
  gcpPermissions,
  integrationStyles,
  borderStyle,
  assetsApisNamesMap,
  assetApiUrlMap,
  linkStyle,
} from 'constant'
import config from 'config'
import images from 'res'

import Dropzone from './Dropzone'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import {Trans} from 'react-i18next'
import CopyToClipboard from 'components/partials/CopyToClipboard'
import {isLoading} from 'utils'

export default function GcpManualIntegration() {
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()

  const [file, setFile] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const copyToClipboard = arg => {
    switch (arg) {
      case 'unxpose-account-id-button': {
        navigator.clipboard.writeText(awsAccountId)
        return
      }
    }
  }

  const handleClickIntegrate = e => {
    e.preventDefault()

    setFormSubmitted(true)

    if (!file) return

    dispatch(loading({gcpIntegration: true}, {_csrf: csrfState}))

    try {
      const configFile = JSON.parse(file)

      dispatch(
        integrateGcp({
          _csrf: csrfState,
          config: configFile,
          version: config.gcpIntegrationVersion,
        })
      )
    } catch (err) {
      dispatch(
        genericError({
          message: i18next.t(
            'cloud.gcp.manualIntegration.stepFour.invalidConfigFile'
          ),
          loading: {gcpIntegration: false},
        })
      )
      console.trace(err)
    }
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      <BackButton fallbackLink={'/cloud'} />

      <div className={`${borderStyle} bg-white p-6 mt-4`}>
        <div className="lg:w-8/12">
          <div className={integrationStyles.mainTitle}>
            {i18next.t('cloud.gcp.manualIntegration.title')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.intro.title')}
          </div>
          <div className="text-sm">
            <p className="mb-4">
              {i18next.t('cloud.gcp.manualIntegration.intro.inOrderToDiscover')}
            </p>
            <div className="mb-4">
              <ul className="list-disc ml-8">
                <li className="mb-1">
                  {i18next.t('cloud.gcp.manualIntegration.intro.stepOne')}
                </li>
                <li className="mb-1">
                  {i18next.t('cloud.gcp.manualIntegration.intro.stepTwo')}
                </li>
                <li className="mb-1">
                  {i18next.t('cloud.gcp.manualIntegration.intro.stepThree')}
                </li>
                <li className="mb-1">
                  {i18next.t('cloud.gcp.manualIntegration.intro.stepFour')}
                </li>
                <li className="mb-1">
                  {i18next.t('cloud.gcp.manualIntegration.intro.stepFive')}
                </li>
              </ul>
            </div>
            <p className="mb-4">
              {i18next.t(
                'cloud.gcp.manualIntegration.intro.theFollowingTutorial'
              )}{' '}
              <a
                href="mailto:support@unxpose.com"
                className="text-sky-500 font-medium">
                {i18next.t('cloud.gcp.manualIntegration.intro.supportEmail')}
              </a>
            </p>
          </div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.stepOne.title')}
          </div>
          <div className="text-sm">
            <p>
              {i18next.t('cloud.gcp.manualIntegration.stepOne.theFirstThing')}{' '}
              <a
                className="text-sky-500 font-medium"
                href="https://console.cloud.google.com"
                target="_blank"
                rel="noreferrer">
                https://console.cloud.google.com
              </a>
              .{' '}
              {i18next.t(
                'cloud.gcp.manualIntegration.stepOne.selectYourOrganization'
              )}
            </p>
            <figure>
              <img src={images.gcp1} className={integrationStyles.image} />
            </figure>
            <p>
              {i18next.t('cloud.gcp.manualIntegration.stepOne.onceYouClick')}
            </p>
            <figure>
              <img src={images.gcp2} className={integrationStyles.image} />
            </figure>
            <p className="mb-4">
              {i18next.t('cloud.gcp.manualIntegration.stepOne.onceYouSelected')}{' '}
              <b>
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepOne.highlights.iamAdmin'
                )}
              </b>{' '}
              {i18next.t('cloud.gcp.manualIntegration.stepOne.andThen')}
              <b>
                {' '}
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepOne.highlights.roles'
                )}
                .
              </b>
            </p>
            <ul className="list-disc ml-8">
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepOne.clickOn')}
                <span className="font-medium ml-1">
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepOne.highlights.createRole'
                  )}
                </span>

                <figure>
                  <img src={images.gcp3} className={integrationStyles.image} />
                </figure>
              </li>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepOne.chooseARole')}
              </li>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepOne.clickOn')}
                <span className="font-medium ml-1">
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepOne.highlights.addPermissions'
                  )}
                </span>

                <figure>
                  <img src={images.gcp4} className={integrationStyles.image} />
                </figure>
              </li>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepOne.selectEachOf')}
              </li>
              <pre className={integrationStyles.codeTextArea}>
                {gcpPermissions.map(permission => {
                  return <div key={permission}>{permission}</div>
                })}
              </pre>
              <figure>
                <img src={images.gcp5} className={integrationStyles.image} />
              </figure>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepOne.finishThisStep'
                )}
                <b>
                  {' '}
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepOne.highlights.create'
                  )}
                </b>
                {i18next.t('cloud.gcp.manualIntegration.stepOne.button')}.
              </li>
            </ul>
          </div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.stepTwo.title')}
          </div>
          <p className="text-sm mb-4">
            {i18next.t('cloud.gcp.manualIntegration.stepTwo.firstlyYouNeed')}
          </p>

          <div className="text-sm">
            <p>
              {i18next.t('cloud.gcp.manualIntegration.stepTwo.nowYouNeed')}
              <b>
                {' '}
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepTwo.highlights.iamServiceAccounts'
                )}
              </b>
            </p>
            <ul className="list-disc ml-8 mt-2">
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepTwo.clickOn')}
                <b>
                  {' '}
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepTwo.highlights.createServiceAccount'
                  )}
                </b>
                <figure>
                  <img src={images.gcp6} className={integrationStyles.image} />
                </figure>
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepTwo.giveTheService'
                )}
              </li>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepTwo.whileCreating')}

                <figure>
                  <img src={images.gcp7} className={integrationStyles.image} />
                </figure>
              </li>
            </ul>
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.stepThree.title')}
          </div>
          <div className="text-sm">
            <p className="mb-4">
              {i18next.t('cloud.gcp.manualIntegration.stepThree.evenThough')}
            </p>
            <ul className="list-disc ml-8">
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepThree.steps.organizationSelect'
                )}
              </li>
              <li className="mb-1">
                <Trans i18nKey="cloud.gcp.manualIntegration.stepThree.steps.accessIAM" />
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepThree.steps.grantAccess'
                )}
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepThree.steps.newPrincipal'
                )}
              </li>
              <li className="mb-1">
                <Trans i18nKey="cloud.gcp.manualIntegration.stepThree.steps.selectRole" />
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepThree.steps.saveButton'
                )}
              </li>
            </ul>
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.stepFour.title')}
          </div>
          <div className="text-sm">
            <p className="pb-4">
              {i18next.t(
                'cloud.gcp.manualIntegration.stepFour.aWorkloadIdentity'
              )}
            </p>
            <p className="pb-4">
              {i18next.t('cloud.gcp.manualIntegration.stepFour.toReadMore')}{' '}
              <a
                href="https://cloud.google.com/iam/docs/workload-identity-federation"
                className="text-sky-500 font-medium"
                target="_blank"
                rel="noreferrer">
                https://cloud.google.com/iam/docs/workload-identity-federation
              </a>
            </p>
            {i18next.t('cloud.gcp.manualIntegration.stepFour.withTheProject')}{' '}
            <b>
              {' '}
              {i18next.t(
                'cloud.gcp.manualIntegration.stepFour.highlights.iamWorkload'
              )}
            </b>
            <ul className="list-disc ml-8 mt-2">
              <img src={images.gcp13} className={integrationStyles.image} />
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepFour.clickOn')}{' '}
                <b>
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepFour.highlights.createPool'
                  )}
                </b>
              </li>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepFour.chooseAPool')}
              </li>
              <li className="mb-1">
                {' '}
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepFour.addAAwsProvider'
                )}
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepFour.chooseAProvider'
                )}

                <CopyToClipboard
                  content={awsAccountId}
                  onClick={() => copyToClipboard('unxpose-account-id-button')}
                  classes="my-3"
                />
              </li>
              <li className="mb-1">
                <Trans i18nKey="cloud.gcp.manualIntegration.stepFour.attributeMapping" />
                <img src={images.gcp10} className={integrationStyles.image} />
              </li>
              <li className="mb-1 mt-3">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepFour.finishThisStep'
                )}{' '}
                <b>
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepFour.highlights.save'
                  )}
                </b>
                {i18next.t('cloud.gcp.manualIntegration.stepFour.button')}.
              </li>
            </ul>
          </div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.stepFive.title')}
          </div>
          <div className="text-sm">
            <p>
              {i18next.t('cloud.gcp.manualIntegration.stepFive.nowThatYou')}
            </p>
            <ul className="list-disc mt-2 ml-8">
              <figure>
                <img src={images.gcp8} className={integrationStyles.image} />
              </figure>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepFive.clickOn')}{' '}
                <b>
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepFive.highlights.grantAccess'
                  )}
                </b>
                <figure>
                  <img src={images.gcp9} className={integrationStyles.image} />
                </figure>
              </li>
              <li className="mb-1">
                <Trans i18nKey="cloud.gcp.manualIntegration.stepFive.selectImpersonation" />
                <figure>
                  <img src={images.gcp12} className={integrationStyles.image} />
                </figure>
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepFive.selectTheService'
                )}
              </li>
              <li className="mb-1">
                <Trans i18nKey="cloud.gcp.manualIntegration.stepFive.attributeMatching" />
                <figure>
                  <img src={images.gcp11} className={integrationStyles.image} />
                </figure>
              </li>
              <li className="mb-1">
                {i18next.t('cloud.gcp.manualIntegration.stepFive.onTheModal')}
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.manualIntegration.stepFive.uploadTheConfig'
                )}

                <div className="mr-4 mt-4">
                  <Dropzone setFile={setFile} file={file} />
                  {formSubmitted && !file ? (
                    <div className="text-mars-700 text-center my-1">
                      You must upload the config file provided by GCP.
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.stepSix.title')}
          </div>
          <div className="text-sm">
            <p>{i18next.t('cloud.gcp.manualIntegration.stepSix.text')}</p>
            <ul className="list-disc ml-8 mt-2">
              {Object.entries(assetApiUrlMap.gcp).map(([key, value]) => (
                <li key={assetsApisNamesMap.gcp[key]}>
                  <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={linkStyle}>
                    {assetsApisNamesMap.gcp[key]}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.manualIntegration.theEnd.title')}
          </div>
          <div className="text-sm">
            <p>
              {i18next.t('cloud.gcp.manualIntegration.theEnd.congratulations')}
            </p>
            <div className="w-full mt-10 md:w-56 md:ml-auto">
              <PrimaryButton
                text={i18next.t(
                  'cloud.gcp.manualIntegration.theEnd.monitorMyAccount'
                )}
                type="submit"
                onClick={handleClickIntegrate}
                theme="blue"
                size="full"
                loading={isLoading(loadingState.gcpIntegration, false)}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

GcpManualIntegration.propTypes = {
  history: PropTypes.object,
}
