import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {documentTypesLabels, languages} from 'constant'

export const isLoggedIn = userState =>
  userState && Object.entries(userState).length > 0

export const hasFeature = (user, feature) => {
  if (
    user &&
    user.company &&
    user.company.features &&
    user.company.features[feature]
  )
    return true
}

export const getFeatureSubscription = (user, feature) => {
  const value = user?.subscription?.features?.[feature]

  return {
    limit: Number(value),
    hasAccess: value === 'UNLIMITED' || Number(value) > 0,
  }
}

export const hasMultipleCompanies = user => {
  const isMultipleCompanyUser = user?.companies?.length > 0
  const isAdminUser = isMultipleCompanyUser && user?.role === 'admin'

  return isMultipleCompanyUser || isAdminUser
}

export const isJsonString = item => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item

  try {
    item = JSON.parse(item)
  } catch (e) {
    return false
  }

  if (typeof item === 'object' && item !== null) {
    return true
  }

  return false
}

export const generateWebAppName = (items, checkedWebApps) => {
  const urls = {}
  let shortest = null

  items.forEach(item => {
    if (checkedWebApps.includes(item.id)) {
      let url

      if (item.name) url = item.name
      else url = getUrlFromTarget(item)

      if (!urls[url]) urls[url] = 0

      if (!url.startsWith('http')) urls[url] = urls[url] + 5

      if (item.port === 80 || item.port === 443) ++urls[url]

      if (item.appProtocol === 'https') ++urls[url]

      if (!shortest) shortest = url

      if (shortest && url.length < shortest.length) {
        shortest = url
        ++urls[url]
      }
    }
  })

  return Object.keys(urls).reduce((a, b) => (urls[a] > urls[b] ? a : b))
}

export const getUrlFromTargetV2 = target => {
  const port = target.port
  const host = [80, 443].includes(parseInt(target.port, 10))
    ? target.host
    : `${target.host}:${port}`

  const path = target.path !== '/' ? target.path : ''

  return `${target.protocol}://${host}${path}`
}

export const getUrlFromTarget = target => {
  if (target.url) return target.url

  if (!target.dnsRecordId && !target.hostId && target.name) return target.name

  if (target.arn) return target.arn

  if (target.resourceId) return target.resourceId

  if (!target.appProtocol && !target.port) return target.hostname

  let appProtocol = null

  if (target.transportProtocol) {
    appProtocol = target.protocol
  } else if (target.appProtocol) {
    appProtocol = target.appProtocol
  } else {
    appProtocol = 'http'
  }

  const port = target.port
  let hostname = target.dnsRecordId
    ? target.dnsRecordId.replace(':A', '').replace(':CNAME', '')
    : target.hostId

  hostname = [80, 443].includes(target.port) ? hostname : `${hostname}:${port}`

  return `${appProtocol}://${hostname}`
}

export const hasMultipleServices = app => {
  return (
    app &&
    app.services &&
    app.httpServices &&
    app.services.length + app.httpServices.length > 1
  )
}

export const isPublicView = () => {
  return (
    window.location.pathname === '/login' ||
    window.location.pathname === '/mfa' ||
    window.location.pathname === '/terms' ||
    window.location.pathname === '/privacy-policy' ||
    window.location.pathname === '/privacy' ||
    window.location.pathname.match(/\/forgot/) ||
    window.location.pathname.match(/\/register/) ||
    window.location.pathname.match(/\/signup/) ||
    window.location.pathname.match(/\/supplier-invite/)
  )
}

export const restrictedRoles = [
  {
    name: 'questionnaires-only',
    allowedPaths: ['/questionnaires', '/settings', '/redirect'],
  },
  {
    name: 'suppliers-questionnaires',
    allowedPaths: ['/suppliers', '/questionnaires', '/settings', '/redirect'],
  },
]

export const arrayBufferToString = buf => {
  return String.fromCharCode.apply(null, new Uint8Array(buf))
}

export const extractProjectId = saUrl => {
  const baseUrl =
    'https://iamcredentials.googleapis.com/v1/projects/-/serviceAccounts/'

  const saAtProject = '[a-zA-Z0-9_-]+@([a-zA-Z0-9_-]+).'

  const regex = new RegExp(baseUrl + saAtProject)

  const match = saUrl.match(regex)

  return match && match[1]
}

export const extractResourceGroup = resourceId => {
  try {
    const arr = resourceId.split('/')
    const resourceGroupsIndex = arr.indexOf('resourceGroups') + 1

    return arr[resourceGroupsIndex] || '-'
  } catch (err) {
    console.trace(err)
    return '-'
  }
}

export const hasCloudIntegration = (
  awsStatsState,
  gcpStatsState,
  azureStatsState
) => {
  return (
    (awsStatsState &&
      awsStatsState.general &&
      awsStatsState.general.integrations) ||
    (gcpStatsState &&
      gcpStatsState.general &&
      gcpStatsState.general.integrations) ||
    (azureStatsState &&
      azureStatsState.general &&
      azureStatsState.general.integrations)
  )
}

export const viewFindingsByCategory = (category, extraParams = '') => {
  const defaultFilters = 'status=open,reopened,fixed,stale'
  const sectionFilter = `type=${category}`

  return `/management?${defaultFilters}&${sectionFilter}${extraParams}`
}

export const updateUserPreferences = newData => {
  if (!newData || typeof newData !== 'object') {
    console.error('newData must be an object')
    return
  }

  const storedData = localStorage.getItem('userPreferences')

  let mergedData = storedData ? JSON.parse(storedData) : {}

  mergedData = {...mergedData, ...newData}

  localStorage.setItem('userPreferences', JSON.stringify(mergedData))
}

export const getDisplayName = (firstName, lastName) => {
  if (firstName && lastName) return firstName + ' ' + lastName

  if (firstName || lastName) return firstName || lastName

  return 'Unxpose'
}

export const anchorLinkMarkdown = ({href, children}) => {
  return (
    <a
      href={href}
      className="text-sky-500"
      rel="noopener noreferrer"
      target="_blank">
      {children}
    </a>
  )
}

export const getLocationToRedirect = userRole => {
  const restrictedRole = restrictedRoles.find(role => role.name === userRole)
  if (restrictedRole) return restrictedRole.allowedPaths[0]

  const location = localStorage.getItem('redirect')

  if (location) {
    localStorage.removeItem('redirect')
    return location
  }

  return '/'
}

export const urlContainsWildcard = url => {
  return /^https?:\/\/\*/.test(url)
}

export const usePageTitle = pageTitle => {
  let envId = ''

  switch (process.env.REACT_APP_ENV) {
    case 'development':
      envId = '(Dev)'
      break

    case 'staging':
      envId = '(QA)'
      break
  }

  useEffect(() => {
    document.title = `${i18next.t(
      'pageTitles.unxpose'
    )} - ${pageTitle} ${envId}`

    // Runs after component unmount to ensure title reset
    return () =>
      (document.title = `${i18next.t('pageTitles.unxpose')} ${envId}`)
  }, [pageTitle])
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return screenSize
}

export const resetUIComponentsState = () => {
  localStorage.setItem('planRenewalModalHasBeenDisplayed', false)
  localStorage.setItem('alertHasBeenDisplayed', false)
  localStorage.setItem('paymentAlertHasBeenDisplayed', false)
  localStorage.setItem('surveyHasBeenDisplayed', false)
}

export const getLocaleFromStorage = storageKey => {
  const locale = localStorage.getItem(storageKey)

  switch (locale) {
    case 'en-US':
      return 'en'
    case 'pt':
    case 'pt-BR':
    case 'pt-br':
      return 'pt-br'
    default:
      return 'en'
  }
}

export const getDefaultLanguage = storageKey => {
  const locale = getLocaleFromStorage(storageKey)

  return languages.find(language => language.value === locale)
}

export const getDocumentTitle = type => {
  return documentTypesLabels.find(item => type === item.value)?.label
}

export const getDocumentDate = (type, data) => {
  switch (type) {
    case 'pentest':
    case 'pentestSummaryReport':
      return data.endDate
    default:
      return data.documentDate
  }
}

export const getWordCount = message => message.trim().split(/\s+/).length

export const getToastAutoCloseTime = message => {
  const wordCount = getWordCount(message)

  switch (true) {
    case wordCount > 15:
      return 8000

    case wordCount > 25:
      return 10000

    default:
      return 4000
  }
}

export const isLoading = (loadingState, initialLoadingState = true) => {
  // WHEN THE STATE NEEDS TO START AS FALSE, LIKE BUTTONS
  if (initialLoadingState === false) return loadingState

  // WHEN THE STATE NEEDS TO START AS TRUE, LIKE ON THE FIRST PAGE RENDER
  return loadingState !== false
}

export const capitalizeWord = word => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * @param {Object} user
 * @returns {String}
 */
export function getUserLocale(user) {
  const locale = user?.locale?.toLowerCase() || 'pt-br'

  if (locale.startsWith('pt')) {
    return 'pt-br'
  } else {
    return 'en'
  }
}

/**
 * Given an object that contains a property for each locale, attempt to get the
 * given locale or fallback to another.
 *
 * @param {Object} object
 * @param {String} locale
 * @returns {String}
 */
export function getPropByLocale(object) {
  // We are migrating titles to become strings instead of objects,
  // this will help us make the transition without crashing users.
  //
  // Remove this when it is done.
  if (typeof object === 'string') {
    return object
  }

  const locale = i18next.language

  if (object[locale]) {
    return object[locale]
  }

  const fallbackLocale = locale === 'pt-br' ? 'en' : 'pt-br'

  return object[fallbackLocale] || ''
}

export function getMarkdownContent(path) {
  const [content, setContent] = useState('')

  fetch(path)
    .then(response => response.text())
    .then(data => setContent(data))
    .catch(err => console.error('Failed to load Markdown file:', err))

  return content
}

export function getBrowserLanguage() {
  return navigator.language?.startsWith('en') ? 'en' : 'pt'
}
