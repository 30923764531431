import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {sky500} from 'constant'

import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import DownloadIcon from 'res/icons/downloadIcon'
import EditIcon from 'res/icons/editIcon'
import TrashIcon from 'res/icons/trashIcon'
import Spinner from 'components/partials/Spinner'
import QuestionnaireTemplateEditModal from './QuestionnaireTemplateEditModal'
import QuestionnaireTemplateRemoveModal from './QuestionnaireTemplateRemoveModal'
import {fetchQuestionnaireDetails} from 'actions'
import {generateQuestionnaireCSV} from '../../../questionnaires/questionnairesUtils'
import Timestamp from 'components/partials/Timestamp'

export default function QuestionnaireTemplatesList() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)

  const questionnaireTemplatesState = useSelector(
    state => state.questionnaireTemplates
  )

  const [editModal, setEditModal] = useState({
    selected: null,
    showModal: false,
  })

  const [removeModal, setRemoveModal] = useState({
    selected: null,
    showModal: false,
  })

  const handleGenerateCSV = questionnaireId => {
    dispatch(
      fetchQuestionnaireDetails({
        questionnaireId,
        onSuccess: data => generateQuestionnaireCSV(userState, data),
      })
    )
  }

  const handleDisplayEditModal = ({id, name, description}) => {
    setEditModal({
      selected: {
        id,
        name,
        description,
      },
      showModal: true,
    })
  }

  const handleDisplayRemoveModal = ({id, name}) => {
    setRemoveModal({
      selected: {
        id,
        name,
      },
      showModal: true,
    })
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: i18next.t('questionnaires.templates.table.name'),
        minSize: 250,
        meta: {
          align: 'left',
        },
      },

      {
        id: 'questionsCount',
        accessorKey: 'questionsCount',
        header: i18next.t('questionnaires.templates.table.questions'),
      },

      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: i18next.t('questionnaires.templates.table.created'),
        cell: cell => <Timestamp date={cell.getValue()} fromNow />,
      },

      {
        id: 'actionButtons',
        accessorKey: 'id',
        header: '',
        maxSize: 10,
        cell: cell => (
          <div className="flex items-center justify-end z-100">
            <div className="mx-2">
              <button
                title={i18next.t('misc.edit')}
                onClick={() => {
                  handleDisplayEditModal({
                    id: cell.getValue(),
                    name: cell.row.original.name,
                    description: cell.row.original.description,
                  })
                }}>
                <EditIcon height="22" width="22" color={sky500} />
              </button>
            </div>

            <div className="mx-2">
              <button
                onClick={() => {
                  handleGenerateCSV(cell.getValue())
                }}
                title={i18next.t('buttons.downloadCSV')}>
                <DownloadIcon height="28" width="28" color={sky500} />
              </button>
            </div>

            <div className="mx-2">
              <button
                title={i18next.t('misc.remove')}
                onClick={() => {
                  handleDisplayRemoveModal({
                    id: cell.getValue(),
                    name: cell.row.original.name,
                  })
                }}>
                <TrashIcon height="22" width="22" color={sky500} />
              </button>
            </div>
          </div>
        ),
        disableGlobalFilter: true,
        isButton: true,
      },
    ],
    []
  )

  if (!questionnaireTemplatesState) return <Spinner />

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={questionnaireTemplatesState}
        emptyStateWithFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.nothingFoundWithSearch')}
            body={i18next.t('tables.searchSomethingElse')}
          />
        }
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.noResultsFound')}
            body={i18next.t('questionnaires.templates.noTemplates')}
          />
        }
      />

      {editModal.showModal && (
        <QuestionnaireTemplateEditModal
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}

      {removeModal.showModal && (
        <QuestionnaireTemplateRemoveModal
          removeModal={removeModal}
          setRemoveModal={setRemoveModal}
        />
      )}
    </div>
  )
}
