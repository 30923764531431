import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import {i18next} from 'translate/i18n'
import {useParams} from 'react-router-dom'

import {fetchHost, fetchHostFindings, toggleRestrictionModal} from 'actions'
import images from 'res'
import {getPropByLocale, viewFindingsByCategory} from 'utils'
import PlanUtils from 'utils/plan'
import {statusList, tooltipStyle} from 'constant'

import Map from '../partials/Map'
import {generateCSV} from 'utils/csv'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'
import {borderStyle, sideral300, sky500} from 'constant'
import NetworkIcon from 'res/icons/networkIcon'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import FindingIcon from 'res/icons/findingIcon'
import Spinner from 'components/partials/Spinner'
import DownloadIcon from 'res/icons/downloadIcon'
import BackButton from 'components/partials/buttons/BackButton'
import {Page} from 'components/partials/Page'
import PlaceholderContent from 'components/partials/PlaceholderContent'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import Timestamp from 'components/partials/Timestamp'

export default function Host() {
  const userState = useSelector(state => state.user)
  const hostState = useSelector(state => state.host)
  const hostFindingsState = useSelector(state => state.hostFindings)

  const dispatch = useDispatch()
  const params = useParams()

  const [findings, setFindings] = useState([])
  const [servicesDetected, setServicesDetected] = useState([])

  const planUtils = PlanUtils()

  const hostId = params.id

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const companyName = encodeURIComponent(userState.company.name.split(' ')[0])

    const csvData = findings.map(finding => {
      const row = {}

      row.checkedAt = finding.checkedAt
      row.host = finding.name
      row.port = finding.port
      row.severity = getSeverityLabel(getSeverityString(finding.severity))
      row.status = statusList[finding.status]
      row.title = getPropByLocale(finding.title)

      return row
    })

    generateCSV({
      columnNames: [
        i18next.t('tables.name'),
        i18next.t('tables.finding'),
        i18next.t('tables.port'),
        i18next.t('tables.severity'),
        i18next.t('tables.status'),
        i18next.t('tables.seen'),
      ],
      columns: ['host', 'title', 'port', 'severity', 'status', 'checkedAt'],
      data: csvData,
      filename: `${companyName}-${
        hostState[hostId].name
      }-${new Date().getTime()}.csv`,
    })
  }

  useEffect(() => {
    if (params.id) {
      dispatch(fetchHost(params.id))

      if (!planUtils.hasPlanRestriction())
        dispatch(fetchHostFindings(params.id))
    }
  }, [dispatch, params.id])

  useEffect(() => {
    try {
      if (!(hostState && hostState[hostId])) return

      const clonedRecords = hostState[hostId].dnsRecords
        ? JSON.parse(JSON.stringify(hostState[hostId].dnsRecords))
        : []
      const clonedServices = hostState[hostId].services
        ? JSON.parse(JSON.stringify(hostState[hostId].services))
        : []

      const recordsServices = clonedRecords.flatMap(record => record.services)

      // set to duplicated port/protocol pairs
      const set = new Set()

      const allServices = [...recordsServices, ...clonedServices]

      const deduplicatedServices = allServices
        .filter(x => {
          if (!x) {
            return false
          }

          if (set.has(x.port + x.protocol)) {
            return false
          }

          set.add(x.port + x.protocol)

          return true
        })
        .map(service => {
          // if there is at least one service live we will not show the icon
          service.status = allServices.find(
            s =>
              s.port === service.port &&
              s.protocol === service.protocol &&
              s.status === 'live'
          )
            ? 'live'
            : 'idle'
          return service
        })
        .sort((a, b) => {
          return a.port - b.port
        })

      setServicesDetected(deduplicatedServices)
      setFindings(hostFindingsState)
    } catch (err) {
      console.trace(err)
      setServicesDetected([])
      setFindings([])
    }
  }, [hostState, hostFindingsState])

  const findingsCol = React.useMemo(
    () => [
      {
        header: i18next.t('tables.title'),
        accessorKey: 'title',
        maxWidth: 200,
        cell: cell => (
          <div className="flex items-center max-w-md">
            <span
              className={`${getSeverityColor(
                cell.row.original.severity
              )} severity-indicator flex-shrink-0 mr-2`}></span>
            <p className="truncate">{getPropByLocale(cell.getValue())}</p>
          </div>
        ),
      },
      {
        header: i18next.t('tables.dnsRecord'),
        accessorKey: 'name',
        cell: cell => (
          <div className="max-w-xs break-words whitespace-pre-wrap">
            {cell.getValue()}
          </div>
        ),
      },
      {
        header: i18next.t('web.port'),
        accessorKey: 'port',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: cell => statusList[cell.getValue()],
      },
      {
        header: i18next.t('tables.severity'),
        accessorKey: 'severity',
        cell: cell => {
          const value = cell.getValue()

          return typeof value === 'number'
            ? getSeverityLabel(getSeverityString(value))
            : '-'
        },
      },
      {
        header: i18next.t('tables.seen'),
        accessorKey: 'id',
        sortingFn: (a, b) =>
          new Date(a.original.checkedAt) - new Date(b.original.checkedAt),
        cell: cell => {
          const moment =
            cell.row.original.lastNmapScan ||
            cell.row.original.checkedAt ||
            cell.row.original.updatedAt

          return <Timestamp date={new Date(moment)} fromNow />
        },
      },
    ],

    []
  )

  return (
    <Page pageTitle={i18next.t('pageTitles.network')} helpPath="network">
      <div>
        {!(hostState && hostState[hostId]) ? (
          <div className="py-4">
            <Spinner />
          </div>
        ) : (
          <>
            <div className={borderStyle}>
              <div className="flex bg-white text-astral-900 border-b rounded-b-none rounded-lg p-4">
                <figure className="-ml-1">
                  <NetworkIcon height="60" width="60" color={sideral300} />
                </figure>
                <div className="flex flex-wrap flex-col justify-around ml-3">
                  <BackButton fallbackLink={'/network'} />
                  <p className="w-full text-sideral-700 font-medium text-lg leading-none">
                    {hostState[hostId].name || '-'}
                  </p>
                </div>
              </div>

              <div className="flex flex-wrap rounded-lg bg-white p-4">
                <div className="lg:w-5/12 w-full relative rounded-lg bg-white overflow-hidden">
                  <AsNumber host={hostState[hostId]} />
                  <AsOrg host={hostState[hostId]} />
                  <Hostname host={hostState[hostId]} />
                  <Owner host={hostState[hostId]} />
                  <OwnerId host={hostState[hostId]} />
                  <Responsible host={hostState[hostId]} />
                  <City host={hostState[hostId]} />
                  <Region host={hostState[hostId]} />
                  <Country host={hostState[hostId]} />
                  {hostState &&
                    hostState[hostId] &&
                    hostState[hostId].geolocation && (
                      <div className="h-64 w-100 mb-2 mt-4">
                        <Map hostId={hostId} />
                      </div>
                    )}
                </div>

                <div className="lg:w-2/12 w-full text-astral-900 font-light">
                  {servicesDetected.length ? (
                    <>
                      <div className="font-medium">
                        {i18next.t('network.services')}
                      </div>
                      <div className="text-sm mb-4 font-normal">
                        {servicesDetected.map((service, index) => {
                          return (
                            <div
                              className="flex items-center py-1"
                              key={`${service.port}-${service.protocol}-${index}`}>
                              <div>
                                {service.port}/{service.protocol}
                              </div>
                              <div
                                className={`${
                                  service.status === 'live' ? 'hidden' : ''
                                } tooltip cursor-pointer mb-px`}>
                                <figure className="flex items-center justify-center ml-px mt-px pt-px">
                                  <images.InfoIcon
                                    color={sideral300}
                                    width="18"
                                  />
                                </figure>
                                <span
                                  className={`${tooltipStyle.default} text-xs transform -translate-x-1/2 -translate-y-full -mt-5`}>
                                  {i18next.t(
                                    'network.errors.serviceNotResponding'
                                  )}
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="lg:w-5/12 w-full text-astral-900 font-light pl-6">
                  {hostState[hostId].dnsRecords &&
                  hostState[hostId].dnsRecords.length ? (
                    <>
                      <div className="font-medium">
                        {i18next.t('tables.dnsRecords')}
                      </div>
                      <div className="text-sm font-normal">
                        {hostState[hostId].dnsRecords.map((record, index) => {
                          return (
                            <div
                              key={`${record.name}-${record.hostId}-${index}`}
                              className="pb-1">
                              {record.name}
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${borderStyle} relative hidden md:block bg-astral-50 text-astral-900 mt-8`}>
              <div className="border-b p-4 flex">
                <div className="dm-mono text-sideral-700 uppercase flex-1 font-semibold">
                  {i18next.t('misc.findings')}
                </div>
              </div>
              {planUtils.hasPlanRestriction() ? (
                <PlaceholderContent sectionId="findingsTable" />
              ) : !findings ? (
                <Spinner />
              ) : !findings.length ? (
                <div className="rounded-lg bg-white p-6">
                  <GenericEmptyState
                    icon={<FindingIcon width="55" color="#90A4AE" />}
                    body={i18next.t('network.noFindingsInHost')}
                    buttonText={i18next.t('buttons.viewAllFindings')}
                    linkPath={viewFindingsByCategory('network')}
                  />
                </div>
              ) : (
                <div className="rounded-lg bg-white">
                  <button
                    className="absolute top-0 right-0 m-4"
                    onClick={handleGenerateCSV}>
                    <DownloadIcon height="24" width="24" color={sky500} />
                  </button>

                  <div className="p-4">
                    <PrimaryTableV8
                      columns={findingsCol}
                      data={findings || []}
                      pageSize={5}
                      detailPath={row => `/network/finding/${row.original.id}`}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Page>
  )
}

const labelStyle = 'font-medium mr-1'
const boxStyle = 'text-sm text-astral-900 font-light pb-1'

const Hostname = props => {
  if (!props.host || !props.host.hostname) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>{i18next.t('network.hostname')}:</label>
      <span className="h-8 break-all">{props.host.hostname}</span>
    </div>
  )
}

const Responsible = props => {
  if (!props.host || !props.host.responsible) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>{i18next.t('network.responsible')}:</label>
      <span>{props.host.responsible || '-'}</span>
    </div>
  )
}

const Region = props => {
  if (!props.host || !props.host.region) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>{i18next.t('network.region')}:</label>
      <span>{props.host.region || '-'}</span>
    </div>
  )
}

const Country = props => {
  if (!props.host || !props.host.country) return <></>
  return (
    <div className={boxStyle + ' flex items-center'}>
      <label className={labelStyle}>{i18next.t('labels.country')}:</label>
      <img
        src={`/countries/${props.host.country}.png`}
        className="mr-1"
        style={{maxWidth: '14px'}}
        alt={props.host.country}
      />{' '}
      <span>{props.host.country}</span>
    </div>
  )
}

const City = props => {
  if (!props.host || !props.host.city) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>{i18next.t('misc.city')}</label>
      <span>{props.host.city || '-'}</span>
    </div>
  )
}

const AsNumber = props => {
  if (!props.host || (!props.host.asNum && !props.host.autNum)) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>AS Number:</label>
      <span>{props.host.asNum || props.host.autNum || '-'}</span>
    </div>
  )
}

const AsOrg = props => {
  if (!props.host || !props.host.asOrg) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>AS Org:</label>
      <span>{props.host.asOrg || '-'}</span>
    </div>
  )
}

const Owner = props => {
  if (!props.host || !props.host.owner) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>Owner:</label>
      {props.host.owner && props.host.owner.replace(/�/g, '')}
    </div>
  )
}

const OwnerId = props => {
  if (!props.host || !props.host.ownerid) return <></>
  return (
    <div className={boxStyle}>
      <label className={labelStyle}>Owner ID:</label>
      <span>{props.host.ownerid || '-'}</span>
    </div>
  )
}

AsNumber.propTypes = {host: PropTypes.object}
AsOrg.propTypes = {host: PropTypes.object}
City.propTypes = {host: PropTypes.object}
Country.propTypes = {host: PropTypes.object}
Hostname.propTypes = {host: PropTypes.object}
Owner.propTypes = {host: PropTypes.object}
OwnerId.propTypes = {host: PropTypes.object}
Region.propTypes = {host: PropTypes.object}
Responsible.propTypes = {host: PropTypes.object}

Host.propTypes = {
  history: PropTypes.object,
}
