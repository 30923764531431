import AwesomeDebouncePromise from 'awesome-debounce-promise'
import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import i18next from 'i18next'
import {Link} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import images from 'res'
import {tableStyles} from 'constant'
import {mappedSourcesNames} from './peopleUtils'

import FilterMenu from './FilterMenu'
import Pagination from 'components/partials/tables/Pagination'
import Spinner from 'components/partials/Spinner'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import FilterButton from 'components/partials/buttons/FilterButton'

function TableAccounts(props) {
  const [filterMenuVisibility, setFilterMenuVisibility] = useState('hidden')
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, sortBy},

    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: items,
      defaultColumn,

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: props.pageCount,

      initialState: {
        pageIndex: props.pageIndex || 0,
        pageSize: props.pageSize || 10,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const handlePrevious = () => {
    setLoading(true)
    props.fetchData({
      filterValue: props.filterValue,
      filterOptions: prepareAppliedFilters(props.filterForm),
      pageIndex: pageIndex - 1,
      limit: props.pageSize,
      sortBy: sortBy,
    })
    previousPage()
  }

  const handleNext = () => {
    setLoading(true)
    props.fetchData({
      filterValue: props.filterValue,
      filterOptions: prepareAppliedFilters(props.filterForm),
      pageIndex: pageIndex + 1,
      limit: props.pageSize,
      sortBy: sortBy,
    })
    nextPage()
  }

  const refreshTableData = (filterValue, filterForm, sortBy, pageIndex) => {
    gotoPage(0)
    setLoading(true)
    props.fetchData({
      pageIndex: pageIndex,
      limit: props.pageSize,
      filterValue: filterValue,
      filterOptions: prepareAppliedFilters(filterForm),
      sortBy: sortBy,
    })
  }

  const debouncedRefreshTable = useMemo(() => {
    return AwesomeDebouncePromise(refreshTableData, 1000)
  }, [])

  const toggleFilterMenu = menuVisibility => {
    menuVisibility === 'hidden'
      ? setFilterMenuVisibility('block')
      : setFilterMenuVisibility('hidden')
  }

  function closeFilterMenu(e) {
    const filterMenu = document.querySelector('#filter-menu')
    const filterButton = document.querySelector('#filter-button')

    if (filterButton?.contains(e.target)) {
      return
    }

    if (!filterMenu.contains(e.target)) {
      setFilterMenuVisibility('hidden')
    }
  }

  function countAppliedFilters(obj) {
    const appliedFilters = []
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        for (const subKey in obj[key]) {
          if (obj[key][subKey]) {
            appliedFilters.push(subKey)
          }
        }
      }
    }
    return appliedFilters.length
  }

  function prepareAppliedFilters(obj) {
    const appliedFilters = {}
    for (const key in obj) {
      const keyProps = Object.entries(obj[key])
        .filter(([, subValue]) => subValue === true)
        .map(([subKey]) => subKey)

      if (keyProps.length) appliedFilters[key] = keyProps
    }
    return appliedFilters
  }

  useEffect(() => {
    setFirstPageLoad(false)
    document.addEventListener('mouseup', closeFilterMenu)
    return () => document.removeEventListener('mouseup', closeFilterMenu)
  }, [])

  useEffect(() => {
    if (!firstPageLoad) {
      props.setSortOptions(sortBy)
      refreshTableData(props.filterValue, props.filterForm, sortBy, pageIndex)
    }
  }, [sortBy])

  useEffect(() => {
    setLoading(false)

    if (firstPageLoad) return

    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    if (!firstPageLoad)
      debouncedRefreshTable(props.filterValue, props.filterForm, sortBy)
  }, [debouncedRefreshTable, props.filterValue])

  useEffect(() => {
    if (!firstPageLoad) {
      props.setFilterForm(props.appliedFilters)
    }
  }, [filterMenuVisibility])

  const filtersCount = countAppliedFilters(props.filterForm)

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex flex-wrap lg:flex-nowrap justify-end lg:justify-between items-center mb-4">
          <div className="flex md:flex-nowrap items-center justify-between md:justify-start gap-4 order-last lg:order-none w-full">
            <GlobalFilter
              globalFilter={props.filterValue}
              gotoPage={gotoPage}
              setFilterValue={props.setFilterValue}
              setGlobalFilter={setGlobalFilter}
              setLoading={setLoading}
              totalPages={props.total}
              filterWidth="w-full lg:w-2/3 xl:w-2/3"
            />

            <div className="relative">
              <FilterButton
                filtersCount={filtersCount}
                onClick={() => toggleFilterMenu(filterMenuVisibility)}
              />
              <FilterMenu
                appliedFilters={props.appliedFilters}
                setAppliedFilters={props.setAppliedFilters}
                filterMenuVisibility={filterMenuVisibility}
                setFilterMenuVisibility={setFilterMenuVisibility}
                filterForm={props.filterForm}
                setFilterForm={props.setFilterForm}
                initialFormState={props.initialFormState}
                refreshTableData={refreshTableData}
                filterValue={props.filterValue}
              />
            </div>
          </div>

          <div className="mb-2 lg:mb-0 shrink-0">
            <Pagination
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              canPreviousPage={loading ? false : canPreviousPage}
              canNextPage={loading ? false : canNextPage}
              paginationClass={props.paginationClass}
              pageIndex={pageIndex}
              pageCount={pageCount}
              total={props.total}
            />
          </div>
        </div>
        {props.data && props.data.length && !loading ? (
          <div className="overflow-x-auto">
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()}></tr>

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => (
                        <th
                          className="dm-mono text-sideral-300 text-sm"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header').toUpperCase()}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={tableStyles.tableRow}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (cell.column.id === 'numberOfBreaches')
                          return (
                            <td
                              align="center"
                              className="flex items-center justify-center p-0"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                className="hover:no-underline w-full h-full flex items-center justify-center"
                                to={`/people/account/${row.original.id}`}>
                                {cell.row.original.numberOfBreaches}
                              </Link>
                            </td>
                          )

                        if (cell.column.id === 'sources') {
                          return (
                            <td
                              {...cell.getCellProps()}
                              align="center"
                              className={
                                'flex flex-wrap items-center justify-center p-0'
                              }
                              key={uuidv4()}>
                              <Link
                                to={`/people/account/${row.original.id}`}
                                className="hover:no-underline w-full h-full flex flex-wrap items-center justify-center">
                                {cell.row.original.sources.map(source => {
                                  return (
                                    <span
                                      key={uuidv4()}
                                      className="flex items-center justify-center w-full py-2">
                                      {mappedSourcesNames[source]}
                                    </span>
                                  )
                                })}
                              </Link>
                            </td>
                          )
                        }

                        if (cell.column.id === 'isAdmin') {
                          const positiveIcon = (
                            <div className="group relative w-full">
                              <span className="group-hover:block hidden truncate absolute top-0 rounded text-xs bg-sideral-700 text-white px-2 p-1 -mt-6 ml-2">
                                {i18next.t('people.tooltips.isAdmin')}
                              </span>
                              <span className="icofont-check text-sideral-300 text-xl"></span>
                            </div>
                          )
                          const negativeIcon = (
                            <div className="group relative w-full">
                              <span className="group-hover:block hidden truncate absolute top-0 rounded text-xs bg-sideral-700 text-white px-2 p-1 -mt-6 ml-2">
                                {i18next.t('people.tooltips.isNotAdmin')}
                              </span>
                              <span className="icofont-close text-sideral-300 text-xl"></span>
                            </div>
                          )
                          const noInfoIcon = (
                            <div className="group relative w-full">
                              <span className="group-hover:block hidden truncate absolute top-0 rounded text-xs bg-sideral-700 text-white px-2 p-1 -mt-6 ml-2">
                                {i18next.t('people.tooltips.noDataAvailable')}
                              </span>
                              <span className="icofont-minus text-sideral-300 text-xl"></span>
                            </div>
                          )
                          return (
                            <td
                              {...cell.getCellProps()}
                              align="center"
                              className={
                                'overflow-visible flex flex-wrap items-center justify-center p-0'
                              }
                              key={uuidv4()}>
                              <Link
                                to={`/people/account/${row.original.id}`}
                                className="hover:no-underline w-full h-full flex flex-wrap items-center justify-center">
                                {cell.row.original.isAdmin.map(item => {
                                  return (
                                    <span
                                      key={uuidv4()}
                                      className="flex items-center justify-center w-full">
                                      {item === null
                                        ? noInfoIcon
                                        : item
                                        ? positiveIcon
                                        : negativeIcon}
                                    </span>
                                  )
                                })}
                              </Link>
                            </td>
                          )
                        }

                        if (cell.column.id === 'hasMfa') {
                          const positiveIcon = (
                            <div className="group relative w-full">
                              <span className="group-hover:block hidden truncate absolute top-0 rounded text-xs bg-sideral-700 text-white px-2 p-1 -mt-6 ml-2">
                                {i18next.t('people.tooltips.mfaEnabled')}
                              </span>
                              <span className="icofont-check text-sideral-300 text-xl"></span>
                            </div>
                          )
                          const negativeIcon = (
                            <div className="group relative w-full">
                              <span className="group-hover:block hidden truncate absolute top-0 rounded text-xs bg-sideral-700 text-white px-2 p-1 -mt-6 ml-2">
                                {i18next.t('people.tooltips.mfaDisabled')}
                              </span>
                              <span className="icofont-close text-sideral-300 text-xl"></span>
                            </div>
                          )
                          const noInfoIcon = (
                            <div className="group relative w-full">
                              <span className="group-hover:block hidden truncate absolute top-0 rounded text-xs bg-sideral-700 text-white px-2 p-1 -mt-6 ml-2">
                                {i18next.t('people.tooltips.noDataAvailable')}
                              </span>
                              <span className="icofont-minus text-sideral-300 text-xl"></span>
                            </div>
                          )
                          return (
                            <td
                              {...cell.getCellProps()}
                              align="center"
                              className={
                                'overflow-visible flex flex-wrap items-center justify-center p-0'
                              }
                              key={uuidv4()}>
                              <Link
                                to={`/people/account/${row.original.id}`}
                                className="hover:no-underline w-full h-full flex flex-wrap items-center justify-center">
                                {cell.row.original.hasMfa.map(item => {
                                  return (
                                    <span
                                      key={uuidv4()}
                                      className="flex items-center justify-center w-full">
                                      {item === null
                                        ? noInfoIcon
                                        : item
                                        ? positiveIcon
                                        : negativeIcon}
                                    </span>
                                  )
                                })}
                              </Link>
                            </td>
                          )
                        }

                        return (
                          <td
                            align="center"
                            className={`${
                              cell.column.id === 'name'
                                ? 'break-words'
                                : 'break-all'
                            } flex items-center justify-center p-0`}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link
                              to={`/people/account/${row.original.id}`}
                              className="hover:no-underline  box-content w-full h-full flex items-center justify-center">
                              {getValueFromUser(cell)}
                            </Link>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {props.filterValue || countAppliedFilters(props.filterForm)
                  ? props.emptyStateWithFilter
                  : props.emptyStateWithoutFilter}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

function getValueFromUser(cell) {
  const attention = <images.AttentionSignal height="20" width="20" />
  const shield = <images.SecurityShield height="20" width="20" />

  if (typeof cell.value === 'undefined' || cell.value === null) {
    return '–'
  }

  if (cell.column.id === 'passwordStrength') {
    if (!cell.row.original.googleWorkspace && !cell.row.original.microsoft365)
      return '-'
    return cell.value === 'STRONG' ? shield : attention
  }

  if (cell.column.id === 'mfa') {
    if (!cell.row.original.googleWorkspace && !cell.row.original.microsoft365)
      return '-'
    return cell.value ? shield : attention
  }

  return cell.value
}

TableAccounts.propTypes = {
  appliedFilters: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  fetchData: PropTypes.func,
  filterForm: PropTypes.object,
  filterValue: PropTypes.string,
  headerClasses: PropTypes.string,
  initialFormState: PropTypes.object,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  paginationClass: PropTypes.string,
  rowClasses: PropTypes.string,
  setAppliedFilters: PropTypes.func,
  setFilterForm: PropTypes.func,
  setFilterValue: PropTypes.func,
  setSortOptions: PropTypes.func,
  storageRetrieved: PropTypes.bool,
  total: PropTypes.number,
}

export default React.memo(TableAccounts)
