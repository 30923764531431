import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'
import {useNavigate} from 'react-router-dom'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import Pagination from 'components/partials/tables/Pagination'
import GlobalFilter from 'components/partials/tables/GlobalFilter'

import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'
import {statusList, tableStyles} from 'constant'
import Timestamp from 'components/partials/Timestamp'

export default function Table(props) {
  const navigate = useNavigate()

  const [filterValue, setFilterValue] = useState('')
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    state: {pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  return (
    <div>
      <div className="tableWrap relative">
        <div className={tableStyles.filterWrapper}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={filterValue}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
          />

          <Pagination
            handlePrevious={previousPage}
            handleNext={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={rows.length || 0}
          />
        </div>
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className={tableStyles.tableHeader}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {column.render('Header').toUpperCase()}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={tableStyles.tableRow + ' cursor-pointer'}
                    key={uuidv4()}
                    onClick={() => navigate(`/dns/finding/${row.original.id}`)}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'title') {
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <span
                              className={`${getSeverityColor(
                                getSeverityString(cell.row.original.severity)
                              )} severity-indicator inline-flex flex-shrink-0 mr-2`}></span>
                            {cell.row.original.title}
                          </td>
                        )
                      }

                      if (
                        cell.column.id === 'severity' &&
                        cell.value &&
                        typeof cell.value === 'number'
                      )
                        return (
                          <td
                            className={`${tableStyles.tableData} capitalize`}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {getSeverityLabel(getSeverityString(cell.value))}
                          </td>
                        )
                      if (cell.column.id === 'status')
                        return (
                          <td
                            className={`${tableStyles.tableData} transform capitalize`}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {statusList[cell.value]}
                          </td>
                        )

                      if (cell.column.id === 'checkedAt')
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={cell.column.classes || ''}>
                            <Timestamp date={new Date(cell.value)} fromNow />
                          </td>
                        )

                      return (
                        <td
                          className={
                            cell.column.classes || tableStyles.tableData
                          }
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            {filterValue
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
}
