import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {i18next} from 'translate/i18n'
import {useNavigate, useLocation, Link} from 'react-router-dom'

import {getPropByLocale, isLoading, viewFindingsByCategory} from 'utils'
import PlanUtils from 'utils/plan'
import {getRequestParams} from 'utils/pagination'
import {sideral300, sideral700, sky500, whatsImportantFeedLimit} from 'constant'
import config from 'config'
import images from 'res'

import FindingIcon from 'res/icons/findingIcon'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import PlaceholderContent from 'components/partials/PlaceholderContent'
import ScoreBox from 'components/partials/ScoreBox'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import StatsBox from 'components/partials/StatsBox'
import WebAppsTable from './WebAppsTable'
import TableWrapper from 'components/partials/tables/TableWrapper'
import {getFilterFromUrl} from 'utils/filters'

import {
  fetchWebStats,
  fetchWebApps,
  fetchWebAppsFingerprints,
  fetchWebImportantFindings,
  toggleRestrictionModal,
  addWebApp,
  loading,
  clearAction,
} from 'actions'
import {Page} from 'components/partials/Page'
import {DefaultModal} from 'components/partials/DefaultModal'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import Timestamp from 'components/partials/Timestamp'

const PAGE_SIZE = 20
const PAGE_INDEX = 0

export default function WebOverview() {
  const dispatch = useDispatch()
  const location = useLocation()

  const initialFormState = {webApp: ''}

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const fingerprints = useSelector(state => state.webAppsFingerprints)
  const statsWeb = useSelector(state => state.statsWeb)
  const importantFindingsWeb = useSelector(state => state.webImportantFindings)
  const webAppsState = useSelector(state => state.webApps)
  const addWebAppDoneState = useSelector(state => state.addWebAppDone)

  const [webApps, setWebApps] = useState([])
  const [feed, setFeed] = useState([])
  const [fetching, setFetching] = useState(false)
  const [filterValue, setFilterValue] = useState('')
  const [sortOptions, setSortOptions] = useState([])
  const [showAddWebAppModal, setShowAddWebAppModal] = useState(false)
  const [form, setForm] = useState(initialFormState)

  const planUtils = PlanUtils()

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({
          contentId: 'downloadCsvBtn',
          showModal: true,
        })
      )
    const url = new URL(`${config.SERVER_URL}/web/apps`)

    url.search = new URLSearchParams(
      getRequestParams({
        format: 'csv',
        pageIndex: 0,
        filterValue: filterValue,
        sortBy: sortOptions,
      })
    ).toString()

    window.location.href = url
  }

  const fingerprintsColumns = React.useMemo(
    () => [
      {
        header: i18next.t('web.tables.product'),
        accessorKey: 'product',
      },
      {
        header: i18next.t('web.tables.versions'),
        accessorKey: 'versions',
        size: 80,
      },
      {
        header: i18next.t('web.tables.total'),
        accessorKey: 'total',
        size: 60,
      },
    ],
    []
  )

  const webAppsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name').toUpperCase(),
            accessor: 'final',
            width: 200,
          },
          {
            Header: i18next.t('misc.findings').toUpperCase(),
            accessor: 'findingsCount',
            width: 40,
          },
          {
            Header: 'Score'.toUpperCase(),
            accessor: 'score',
            width: 40,
          },
        ],
      },
    ],
    []
  )

  const handleInputChange = e => {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  const onCloseModal = () => {
    setShowAddWebAppModal(false)
    setForm(initialFormState)
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({addWebApp: true}))
    dispatch(addWebApp({url: form.webApp, _csrf: csrfState}))
  }

  const onClickAddWebApp = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'addWebAppBtn', showModal: true})
      )

    setForm(initialFormState)
    setShowAddWebAppModal(true)
  }

  useEffect(() => {
    if (webAppsState.data) setWebApps(webAppsState)
  }, [webAppsState])

  useEffect(() => {
    if (addWebAppDoneState) {
      setShowAddWebAppModal(false)
      dispatch(clearAction('addWebAppDone'))
    }
  }, [addWebAppDoneState])

  useEffect(() => {
    dispatch(fetchWebStats({version: 2}))
    dispatch(fetchWebAppsFingerprints())

    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchWebImportantFindings())
    }

    dispatch(
      fetchWebApps({
        filterValue: getFilterFromUrl('protocol', location),
        pageIndex: PAGE_INDEX,
        limit: PAGE_SIZE,
      })
    )

    setFetching(true)
  }, [dispatch])

  useEffect(() => {
    if (fetching && importantFindingsWeb) {
      const arr = [...importantFindingsWeb.findings]
      setFeed(arr)
      setFetching(false)
    }
  }, [importantFindingsWeb])

  useEffect(() => {
    if (!statsWeb || !webApps || !fingerprints) return

    const params = new URLSearchParams(window.location.search)

    if (params.has('protocol')) {
      document.getElementById('webapps-table').scrollIntoView()
    }
  }, [statsWeb, webApps, fingerprints])

  return (
    <Page
      pageTitle={i18next.t('pageTitles.web')}
      button={
        <SecondaryButton
          testId="add-web-app-btn"
          text={i18next.t('web.addWebApps.addWebAppBtn')}
          onClick={onClickAddWebApp}
          crownIcon={planUtils.hasPlanRestriction()}
          plusIcon
        />
      }>
      {statsWeb && (
        <div className="grid md:grid-cols-2 xl:grid-cols-7 gap-4 w-full">
          <StatsBox
            classContainer="md:col-span-2 xl:col-span-3"
            slots={1}
            title={`/${i18next.t('web.webApplications')}`}
            data={statsWeb.webAppsCount || 0}
            size="sm"
          />

          <StatsBox
            classContainer="xl:col-span-2"
            slots={1}
            title={`/${i18next.t('misc.findings')}`}
            data={statsWeb.findingsCount || 0}
            size="sm"
            link={{
              text: i18next.t('buttons.viewAll'),
              onClick: () => viewFindingsByCategory('web'),
            }}
          />

          <ScoreBox
            classContainer="xl:col-span-2"
            score={statsWeb && statsWeb.scores}
          />
        </div>
      )}
      <WhatsImportant feed={feed} setFeed={setFeed} fetching={fetching} />

      <TableWrapper
        title={i18next.t('Fingerprints breakdown')}
        loaded={fingerprints}
        margin="mt-8"
        table={
          <PrimaryTableV8
            data={fingerprints}
            columns={fingerprintsColumns}
            detailPath={row => `/web/fingerprint/${row.original.id}`}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin={'m-2 mt-4'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin={'m-2 mt-4'}
              />
            }
          />
        }
      />

      <TableWrapper
        id="webapps-table"
        title={i18next.t('web.identifiedWebApplications')}
        loaded={webApps.data}
        margin="mt-8"
        button={
          <button
            className="ml-auto"
            onClick={handleGenerateCSV}
            title={i18next.t('buttons.downloadCSV')}>
            <images.DownloadIcon height="24" width="24" color={sky500} />
          </button>
        }
        table={
          <WebAppsTable
            fetchData={props =>
              dispatch(
                fetchWebApps({
                  ...props,
                  limit: PAGE_SIZE,
                })
              )
            }
            columns={webAppsColumns}
            data={webApps.data}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setSortOptions={setSortOptions}
            pageSize={PAGE_SIZE}
            pageIndex={PAGE_INDEX}
            pageCount={webApps.pages}
            total={webApps.total}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.WebIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin="m-2 mt-4"
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.WebIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin="m-2 mt-4"
              />
            }
          />
        }
      />

      {showAddWebAppModal && (
        <form onSubmit={handleSubmit}>
          <DefaultModal
            id="addWebAppModal"
            title={i18next.t('web.addWebApps.modalTitle')}
            body={
              <>
                <div className="text-sm text-sideral-600 mb-3">
                  {i18next.t('web.addWebApps.helperText')}
                </div>

                <PrimaryInput
                  text={i18next.t('web.addWebApps.urlInput')}
                  type="text"
                  name="webApp"
                  placeholder="http://your-web-app.unxpose:8080"
                  value={form.webApp}
                  onChange={handleInputChange}
                  size="sm"
                  autofocus
                  required
                />
              </>
            }
            primaryBtnText={i18next.t('buttons.add')}
            secondayBtnText={i18next.t('buttons.cancel')}
            onClickSecondaryBtn={onCloseModal}
            onCloseModal={onCloseModal}
            primaryButtonLoading={isLoading(loadingState.addWebApp, false)}
            primaryButtonType="submit"
            shouldShowFeebackMessages
          />
        </form>
      )}
    </Page>
  )
}

const WhatsImportant = ({feed, fetching}) => {
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  return (
    <div className="bg-white border rounded-md border-sky-900 mt-8">
      <h2 className="dm-mono text-lg bg-sky-900 rounded-t text-white p-4">
        {i18next.t('titles.whatsImportant')}
      </h2>

      <div className="bg-white rounded-b-md">
        {planUtils.hasPlanRestriction() ? (
          <PlaceholderContent sectionId="whatsImportant" />
        ) : !fetching ? (
          <div className="p-6 pt-0">
            {feed.slice(0, whatsImportantFeedLimit).map((value, index) => {
              return (
                <div key={index + 'webFinding'}>
                  <div className="rounded-md border border-sideral-50 text-sm mt-6">
                    <div className="flex flex-nowrap items-center leading-none w-full p-4 border-sideral-50">
                      <figure className="hidden lg:flex">
                        <FindingIcon height="20" width="20" />
                      </figure>
                      <div className="flex flex-wrap pl-2 w-full">
                        <Link
                          className="text-sky-900 w-full"
                          to={`/web/finding/${value.id}`}>
                          {getPropByLocale(value.title)}
                        </Link>
                        <Link
                          to={`/web/app/${value.webAppId}`}
                          className="text-xs text-ink font-thin pt-1 pr-4 text-ellipsis overflow-hidden whitespace-nowrap">
                          {value.final}
                        </Link>
                      </div>
                    </div>
                    <div className="flex leading-6 font-light text-sideral-200 text-sm border-t border-sideral-50 p-4">
                      <div className="flex">
                        <images.ClockIcon width="20" color={sideral700} />
                        <Timestamp
                          date={value.checkedAt}
                          fromNow
                          className="ml-2"
                        />
                      </div>
                      <SecondaryButton
                        text={i18next.t('buttons.details')}
                        direction="right"
                        size="sm"
                        onClick={() => {
                          navigate(`/web/finding/${value.id}`)
                        }}
                        margin="ml-auto"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            {feed.length > whatsImportantFeedLimit && (
              <SecondaryButton
                text={i18next.t('buttons.viewAll')}
                margin="mt-4 text-center"
                size="sm"
                direction="right"
                redirect={viewFindingsByCategory('web', '&sort=severity:desc')}
              />
            )}
            {!feed.length && (
              <div className="pt-6">
                <GenericEmptyState
                  icon={<FindingIcon width="55" color="#90A4AE" />}
                  body={i18next.t('web.noImportantFindings')}
                  buttonText={i18next.t('buttons.viewAllFindings')}
                  linkPath={viewFindingsByCategory('web')}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="pt-4">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}

WhatsImportant.propTypes = {
  feed: PropTypes.array,
  setFeed: PropTypes.func,
  fetching: PropTypes.bool,
  state: PropTypes.object,
}
