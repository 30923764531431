import React, {useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {Trans} from 'react-i18next'
import {clearAction, clearSuccess, fetchApiKeys, loading} from 'actions'
import {useSelector, useDispatch} from 'react-redux'
import {sky500} from 'constant'
import TableWrapper from 'components/partials/tables/TableWrapper'
import i18next from 'i18next'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import keyIcon from './../../../res/icons/keyIcon.svg'
import Badge from 'components/partials/Badge'
import TrashIcon from 'res/icons/trashIcon'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import RemoveApiKeyModal from './RemoveApiKeyModal'
import GenerateApiKeyModal from './GenerateApiKeyModal'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import Timestamp from 'components/partials/Timestamp'

const ApiKeys = () => {
  const apiKeysState = useSelector(state => state.apiKeys)

  const [generateModal, setGenerateModal] = useState({
    name: null,
    expirationDate: null,
    showModal: false,
  })
  const [removeModal, setRemoveModal] = useState({
    id: null,
    name: null,
    showModal: false,
  })

  const dispatch = useDispatch()

  const handleDisplayRemovalModal = ({id, name, showModal}) => {
    setRemoveModal({
      id,
      name,
      showModal,
    })
  }

  const columns = useMemo(
    () => [
      {
        id: 'key-icon',
        accessorKey: 'id',
        header: '',
        size: 20,
        cell: () => (
          <div className="w-12">
            <img className="w-full mr-2" src={keyIcon} />
          </div>
        ),
        meta: {
          align: 'center',
        },
      },
      {
        id: 'name',
        accessorKey: 'label',
        header: i18next.t('settings.apiKeys.columns.name'),
        cell: cell => (
          <>
            <div>{cell.getValue()}</div>
            <div className="text-sideral-400 text-xs">
              <Trans
                i18nKey="settings.apiKeys.generatedAt"
                values={{
                  dateTime: moment(cell.row.original.createdA).format('L'),
                }}
                shouldUnescape
              />
            </div>
          </>
        ),
        meta: {
          align: 'left',
        },
      },

      {
        id: 'secretId',
        accessorKey: 'secretId',
        header: i18next.t('settings.apiKeys.columns.keyId'),
      },

      {
        id: 'expiration',
        accessorKey: 'expiration',
        header: i18next.t('settings.apiKeys.columns.expiration'),
        cell: cell => <Timestamp date={cell.getValue()} format="ll" />,
      },

      {
        id: 'status',
        accessorKey: 'id',
        header: i18next.t('settings.apiKeys.columns.status'),
        cell: cell => {
          const {createdAt, expiration} = cell.row.original

          return moment(createdAt).isAfter(expiration) ? (
            <Badge style="error" label={i18next.t('status.expired')} />
          ) : (
            <Badge style="success" label={i18next.t('status.active')} />
          )
        },
      },
      {
        id: 'actions',
        accessorKey: 'id',
        header: '',
        cell: cell => (
          <button
            onClick={() => {
              dispatch(clearSuccess())
              handleDisplayRemovalModal({
                id: cell.getValue(),
                name: cell.row.original.label,
                showModal: true,
              })
            }}
            title={i18next.t('misc.remove')}>
            <TrashIcon height="22" width="22" color={sky500} />
          </button>
        ),
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(loading({apiKeys: true}))
    dispatch(fetchApiKeys())
  }, [])

  return (
    <>
      <TableWrapper
        id="api-keys"
        title={i18next.t('settings.apiKeys.title')}
        loaded={apiKeysState}
        button={
          <SecondaryButton
            text={i18next.t('settings.apiKeys.generateApiKeyBtn')}
            size="sm"
            margin="ml-auto"
            onClick={() => {
              dispatch(clearAction('generatedApiKey'))
              setGenerateModal({showModal: true})
            }}
            plusIcon
          />
        }
        table={
          <PrimaryTableV8
            columns={columns}
            data={apiKeysState}
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<img className="w-16" src={keyIcon} />}
                title={i18next.t('settings.apiKeys.messages.noApiKeys')}
                body={i18next.t(
                  'settings.apiKeys.messages.generateFirstApiKey'
                )}
              />
            }
            hidePagination={apiKeysState?.length === 0}
            hideSearch
          />
        }
      />

      {generateModal.showModal && (
        <GenerateApiKeyModal
          modal={generateModal}
          setModal={setGenerateModal}
        />
      )}

      {removeModal.showModal && (
        <RemoveApiKeyModal modal={removeModal} setModal={setRemoveModal} />
      )}
    </>
  )
}

export default ApiKeys
