import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {respondQuestionnaireInvite} from 'actions'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Timestamp from 'components/partials/Timestamp'

export default function QuestionnaireInvites({invites}) {
  const dispatch = useDispatch()
  const csrfState = useSelector(state => state.csrf)

  const handleInvite = id => {
    dispatch(
      respondQuestionnaireInvite({
        questionnaireRequestId: id,
        _csrf: csrfState,
      })
    )
  }

  return (
    <div className="p-6">
      {invites &&
        invites.map(invite => (
          <div
            key={invite.id}
            className="flex items-center justify-between border-b pb-6 mb-6 last:border-none last:pb-0 last:mb-0">
            <div className="flex items-center">
              <div className="mr-4">
                {invite.companyLogo ? (
                  <img
                    src={invite.companyLogo}
                    alt="Company logo"
                    className="w-10"
                  />
                ) : (
                  <i className="icofont-building-alt opacity-50 text-4xl"></i>
                )}
              </div>
              <div>
                <div className="font-normal">
                  <Trans
                    i18nKey="questionnaires.invites.acceptRequestOfCompany"
                    values={{
                      inviteName: invite.name,
                      inviteCompany: invite.companyName,
                    }}
                  />
                </div>
                <div className="text-sm text-sideral-400">
                  <Trans
                    i18nKey="questionnaires.invites.description"
                    values={{
                      name: invite.requestedBy,
                    }}
                  />
                  <Timestamp date={invite.createdAt} fromNow />
                </div>
              </div>
            </div>
            <div className="flex">
              <PrimaryButton
                text={i18next.t('questionnaires.invites.acceptBtn')}
                size="sm"
                iconClass="icofont-check text-xl"
                onClick={() => handleInvite(invite.id, 'accepted')}
              />
            </div>
          </div>
        ))}
    </div>
  )
}

QuestionnaireInvites.propTypes = {
  invites: PropTypes.array,
}
