import {v4 as uuidv4} from 'uuid'
import i18next from 'i18next'
import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import {fetchServiceDetails, fetchServiceDetailsFindings} from 'actions'
import {sideral300} from 'constant'
import images from 'res'

import BackButton from 'components/partials/buttons/BackButton'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import TableWrapper from 'components/partials/tables/TableWrapper'
import Spinner from 'components/partials/Spinner'
import ServiceDetailsTable from './tables/ServiceDetailsTable'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import Timestamp from 'components/partials/Timestamp'

export default function ServiceDetails() {
  const dispatch = useDispatch()
  const params = useParams()

  const planUtils = PlanUtils()

  const serviceDetails = useSelector(state => state.serviceDetails)
  const serviceDetailsFindings = useSelector(
    state => state.serviceDetailsFindings
  )

  const findingsColumns = useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.title').toUpperCase(),
            accessor: 'title',
          },
          {
            Header: 'status'.toUpperCase(),
            accessor: 'status',
            width: 50,
          },
          {
            Header: i18next.t('tables.severity').toUpperCase(),
            accessor: 'severity',
            width: 50,
          },
          {
            Header: i18next.t('tables.seen').toUpperCase(),
            accessor: 'checked_at',
            width: 50,
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(fetchServiceDetails(params.id))

    if (!planUtils.hasPlanRestriction())
      dispatch(fetchServiceDetailsFindings(params.id))
  }, [])

  const getFingerprints = fingerprints => {
    if (!Array.isArray(fingerprints) || !fingerprints.length) return '–'

    return fingerprints.map(({vendor, product, version}) => {
      console.log(vendor, product, version)
      const arr = [vendor, product, version]
      const result = arr.filter(item => item)

      if (!result.length) return

      return (
        <div
          key={uuidv4()}
          className="rounded-full inline-flex justify-center border border-gray-300 bg-gray-100 hover:bg-gray-200 p-px mb-1 mx-1">
          <span className="text-xs px-2">{result.join(' : ')}</span>
        </div>
      )
    })
  }

  if (!serviceDetails) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.network')} helpPath="network">
      <div className="flex w-full rounded-lg rounded-b-none border border-sideral-100 bg-white font-medium text-sideral-700 p-4">
        <images.NetworkIcon width="50" color={sideral300} />
        <div className="flex flex-col justify-between flex-wrap pl-4">
          <BackButton fallbackLink={'/network'} />
          Network service
        </div>
      </div>
      <div className="flex w-full rounded-lg rounded-t-none border border-t-0 border-sideral-100 bg-white text-sideral-700 p-4">
        <div className="text-ink text-sm w-4/12">
          {serviceDetails.host ? (
            <div className="flex">
              <span className="font-medium mr-1">
                {i18next.t('network.host')}:
              </span>
              <span className="font-light">{serviceDetails.host}</span>
            </div>
          ) : (
            <></>
          )}
          {serviceDetails.port ? (
            <div className="flex mt-1">
              <span className="font-medium mr-1">
                {i18next.t('network.port')}:
              </span>
              <span className="font-light">{serviceDetails.port}</span>
            </div>
          ) : (
            <></>
          )}
          {serviceDetails.protocol ? (
            <div className="flex mt-1">
              <span className="font-medium mr-1">
                {i18next.t('network.protocol')}:
              </span>
              <span className="font-light">
                {serviceDetails.protocol.toUpperCase()}
              </span>
            </div>
          ) : (
            <></>
          )}
          {serviceDetails.fingerprints ? (
            <div className="flex mt-1">
              <span className="font-medium mr-1">
                {i18next.t('network.fingerprints')}:
              </span>
              <span className="font-light">
                {getFingerprints(serviceDetails.fingerprints)}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="text-ink text-sm w-8/12">
          {serviceDetails.createdAt ? (
            <div className="flex">
              <span className="font-medium mr-1">
                {i18next.t('tables.firstSeen')}:
              </span>
              <span className="font-light">
                <Timestamp format="LLL" date={serviceDetails.createdAt} />
              </span>
            </div>
          ) : (
            <></>
          )}
          {serviceDetails.checkedAt ? (
            <div className="flex mt-1">
              <span className="font-medium mr-1">
                {i18next.t('tables.lastSeen')}:
              </span>
              <span className="font-light">
                <Timestamp format="LLL" date={serviceDetails.checkedAt} />
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <TableWrapper
        id="findingsTable"
        title={i18next.t('misc.findings')}
        table={
          <ServiceDetailsTable
            data={serviceDetailsFindings}
            columns={findingsColumns}
            pageNumber={0}
            pageSize={10}
            pageIndex={0}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.NetworkIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin={'m-2 mt-4'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.NetworkIcon width="50" color={sideral300} />}
                title={i18next.t('titles.goodNews')}
                body={i18next.t('network.noFindingsInService')}
                margin={'m-2 mt-4'}
              />
            }
          />
        }
        loaded={serviceDetailsFindings}
        margin="mt-8"
        isPremiumFeature
      />
    </Page>
  )
}
