import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {useFlexLayout, usePagination, useSortBy, useTable} from 'react-table'
import {tableStyles} from 'constant'
import Timestamp from 'components/partials/Timestamp'

function Table(props) {
  const [items, setItems] = useState([])

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,

    state: {pageIndex, sortBy},
  } = useTable(
    {
      columns: props.columns,
      data: items,
      defaultColumn,

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: props.pageCount,

      initialState: {
        pageIndex: props.pageIndex || 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const handlePrevious = () => {
    if (!props.fetchData) return
    props.fetchData({
      pageIndex: pageIndex - 1,
      pageSize: props.pageSize,
    })
    previousPage()
  }

  const handleNext = () => {
    if (!props.fetchData) return
    props.fetchData({
      pageIndex: pageIndex + 1,
      pageSize: props.pageSize,
    })
    nextPage()
  }

  const refreshTableData = sortBy => {
    if (!props.fetchData) return
    props.fetchData({
      pageIndex: pageIndex,
      pageSize: props.pageSize,
      sortBy: sortBy,
    })
  }

  useEffect(() => {
    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    refreshTableData(sortBy)
  }, [sortBy])

  return (
    <div>
      <div className="tableWrap text-astral-900 p-6">
        <div className="flex items-center justify-between float-right text-xs">
          <div className="px-2">
            <span>
              Page&nbsp;
              <strong>
                {pageIndex + 1} of {props.pageCount}
              </strong>
            </span>
            <span>
              &nbsp;|&nbsp;<strong>{props.total}</strong>&nbsp;items
            </span>
          </div>
          <div className={'pagination ' + props.paginationClass}>
            <button onClick={handlePrevious} disabled={!canPreviousPage}>
              <i
                className={
                  'text-xl icofont-rounded-left' +
                  (canPreviousPage ? ' text-gray-600' : ' text-gray-400')
                }></i>
            </button>
            <button onClick={handleNext} disabled={!canNextPage}>
              <i
                className={
                  'text-xl icofont-rounded-right' +
                  (canNextPage ? ' text-gray-600' : ' text-gray-400')
                }></i>
            </button>
          </div>
        </div>
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map(headerGroup => {
              // trick to disable the first headerGroup
              if (headerGroup.headers.length === 1)
                return <tr key={uuidv4()}></tr>

              return (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={uuidv4()}
                  className="pt-2">
                  {headerGroup.headers.map(column => (
                    <th
                      className="dm-mono uppercase text-center text-sideral-300 border-b border-sideral-50 p-2 "
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={uuidv4()}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  className={tableStyles.tableRow}
                  key={uuidv4()}>
                  {row.cells.map(cell => {
                    if (cell.column.id === 'createdAt') {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={uuidv4()}
                          className="text-center">
                          <Timestamp date={new Date(cell.value)} fromNow />
                        </td>
                      )
                    }

                    if (cell.column.id === 'statusCode') {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={uuidv4()}
                          className="text-center ">
                          <span
                            className={
                              'uppercase px-2  ' +
                              (cell.value === '200'
                                ? 'text-leaf-800'
                                : 'text-mars-700')
                            }>
                            {cell.value === '0' ? 'N/A' : cell.value}
                          </span>
                        </td>
                      )
                    }

                    return (
                      <td
                        className={
                          cell.column.classes
                            ? cell.column.classes
                            : 'text-center pr-2 truncate break-word'
                        }
                        {...cell.getCellProps()}
                        key={uuidv4()}>
                        {typeof cell.value === 'undefined'
                          ? '-'
                          : cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  headerClasses: PropTypes.string,
  history: PropTypes.object,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  total: PropTypes.number,
  paginationClass: PropTypes.string,
  fetchData: PropTypes.func,
  rowClasses: PropTypes.string,
}

export default React.memo(Table)
