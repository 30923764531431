import React, {useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import i18next from 'i18next'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import Pagination from 'components/partials/tables/Pagination'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {v4 as uuidv4} from 'uuid'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import {sideral300} from 'constant'
import images from 'res'
import Spinner from 'components/partials/Spinner'
import Timestamp from 'components/partials/Timestamp'

const AuditLogsTable = ({
  fetchData,
  data,
  pages,
  total,
  pageSize,
  filterValue,
  setFilterValue,
  setSortOptions,
}) => {
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [dynamicHeight, setDynamicHeight] = useState('156')

  const tableRef = useRef()

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            id: 'userName',
            accessor: 'userName',
            Header: i18next.t('settings.auditLogs.tableColumns.user'),
            width: 30,
            disableSortBy: true,
          },
          {
            id: 'action',
            accessor: 'displayAction',
            Header: i18next.t('settings.auditLogs.tableColumns.action'),
            width: 30,
            disableSortBy: true,
          },
          {
            id: 'ip',
            accessor: 'ip',
            Header: i18next.t('settings.auditLogs.tableColumns.ip'),
            width: 20,
            disableSortBy: true,
          },
          {
            id: 'userAgent',
            accessor: 'userAgent',
            Header: i18next.t('settings.auditLogs.tableColumns.userAgent'),
            width: 50,
            disableSortBy: true,
          },
          {
            id: 'createdAt',
            accessor: 'createdAt',
            Header: i18next.t('settings.auditLogs.tableColumns.dateTime'),
            width: 20,
            Cell: cell => <Timestamp format="LLL" date={cell.value} />,
          },
        ],
      },
    ],
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex, sortBy},
  } = useTable(
    {
      columns,
      data: items,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize,
      },

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: pages,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const refreshTableData = (filterValue, sortBy, pageIndex) => {
    gotoPage(0)
    setLoading(true)
    fetchData({
      pageIndex: pageIndex,
      filterValue: filterValue,
      sortBy: sortBy,
    })
  }

  const debouncedRefreshTable = useMemo(() => {
    return AwesomeDebouncePromise(refreshTableData, 1000)
  }, [])

  const handlePrevious = () => {
    setLoading(true)
    fetchData({
      filterValue,
      pageIndex: pageIndex - 1,
      limit: pageSize,
      sortBy: sortBy,
    })
    previousPage()
  }

  const handleNext = () => {
    setLoading(true)
    fetchData({
      filterValue,
      pageIndex: pageIndex + 1,
      limit: pageSize,
      sortBy: sortBy,
    })
    nextPage()
  }

  useEffect(() => setFirstPageLoad(false), [])

  useEffect(() => {
    setLoading(false)
    if (data) setItems(() => data)
  }, [data])

  useEffect(() => {
    if (tableRef?.current) setDynamicHeight(tableRef.current.clientHeight)
  }, [items])

  useEffect(() => {
    if (!firstPageLoad) debouncedRefreshTable(filterValue)
  }, [filterValue])

  useEffect(() => {
    if (!firstPageLoad) {
      setSortOptions(sortBy)
      refreshTableData(filterValue, sortBy, pageIndex)
    }
  }, [sortBy])

  return (
    <div className="tableWrap relative text-sm">
      <div className="flex justify-between items-center pb-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={filterValue}
          setFilterValue={setFilterValue}
          setGlobalFilter={setGlobalFilter}
          setLoading={setLoading}
          gotoPage={gotoPage}
          totalPages={total}
        />
        <Pagination
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          canPreviousPage={loading ? false : canPreviousPage}
          canNextPage={loading ? false : canNextPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
          total={total}
        />
      </div>

      {!loading ? (
        <>
          {page && page.length ? (
            <table ref={tableRef} {...getTableProps()} className="w-full mt-4">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()} />

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => (
                        <th
                          className="dm-mono text-sideral-300 text-sm"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header').toUpperCase()}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)

                  return (
                    <tr
                      {...row.getRowProps()}
                      key={uuidv4()}
                      className="flex items-center text-astral-900 border-t border-sideral-50 text-center py-2">
                      {row.cells.map(cell => (
                        <td
                          className="p-0 text-center"
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <>
              {filterValue ? (
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'m-2 mt-4'}
                />
              ) : (
                <GenericEmptyState
                  icon={<images.DnsIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFound')}
                  body={i18next.t('tables.waitAFewMinutes')}
                  margin={'m-2 mt-4'}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div
          className="flex items-center justify-center"
          style={{height: `${dynamicHeight}px`}}>
          <Spinner />
        </div>
      )}
    </div>
  )
}

AuditLogsTable.propTypes = {
  fetchData: PropTypes.func,
  data: PropTypes.array,
  pages: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  setSortOptions: PropTypes.func,
}

export default AuditLogsTable
