import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import images from 'res'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import config from 'config'
import {sky500} from 'constant'
import Timestamp from 'components/partials/Timestamp'

export default function ReportsList({reports}) {
  const columns = React.useMemo(
    () => [
      {
        header: i18next.t('reports.table.date'),
        accessorKey: 'createdAt',
        cell: cell => <Timestamp date={cell.getValue()} format="LLL" />,
        sortingFn: (a, b) => {
          return new Date(a.original.createdAt) - new Date(b.original.createdAt)
        },
      },
      {
        header: i18next.t('reports.table.createdBy'),
        accessorKey: 'firstName',
      },
      {
        header: i18next.t('reports.table.language'),
        accessorKey: 'locale',
        cell: cell =>
          cell.getValue() === 'pt-br'
            ? i18next.t('reports.languages.pt')
            : i18next.t('reports.languages.en'),
      },
      {
        header: '',
        accessorKey: 'id',
        maxSize: 10,
        cell: cell => (
          <div className="z-100">
            <button
              onClick={() => {
                const url = new URL(
                  `${config.SERVER_URL}/report/${cell.getValue()}`
                )
                window.open(url, '_blank')
              }}
              title={i18next.t('reports.table.downloadReport')}>
              <images.DownloadIcon height="28" width="28" color={sky500} />
            </button>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={reports}
        pageSize={10}
        hideSearch
      />
    </div>
  )
}

ReportsList.propTypes = {
  reports: PropTypes.array,
  reportIsLoading: PropTypes.bool,
}
