import config from 'config'

export const messages = {
  en: {
    translations: {
      auth: {
        errors: {
          incorrectPassword: 'Current password is incorrect',
          invalidLink: 'This link is invalid or has expired.',
          notReady: 'Your organization is not ready. Please, contact our team.',
          shortPassword: 'The password should have at least 10 characters',
          unexpectedError: 'An unexpected error occurred, try again.',
          googleAccountNotConnected:
            'Your Google account is not connected to a Unxpose account. If you already have a Unxpose account, login using other method and connect your Google account in Settings.',
          microsoftAccountNotConnected:
            'Your Microsoft account is not connected to a Unxpose account. If you already have a Unxpose account, login using other method and connect your Microsoft account in Settings.',
          emailNotFound:
            'The user selected for authentication is not registered.',
          planExpired:
            "Subscription expired. But don't worry, it's super easy to reactivate it. Contact us at sales@unxpose.com",
          companyExists:
            'Your company is already on Unxpose! Please contact your account administrator for access.',
          authenticationRequired:
            'You must be authenticated to complete this action.',
        },
        success: {
          checkYourMailBox: 'Check your mail box!',
          passwordUpdated: 'Password updated successfully',
        },
      },
      pageTitles: {
        marketplace: 'Marketplace',
        unxpose: 'Unxpose',
        overview: 'Overview',
        internetExposure: 'Internet Exposure',
        dns: 'DNS',
        network: 'Network',
        web: 'Web',
        cloud: 'Cloud',
        compliance: 'Compliance',
        people: 'People',
        information: 'Information',
        allFindings: 'All Findings',
        findingsBulkChange: 'All Findings - Bulk Change',
        reports: 'Reports',
        questionnaires: 'Questionnaires',
        suppliers: 'Suppliers',
        documents: 'Documents',
        settings: 'Settings',
        registration: 'Registration',
        forgotPassword: 'Forgot password',
        chooseCompany: 'Choose company',
        logs: 'Logs',
        planExpired: 'Plan expired',
        help: 'Help',
        whyUnxpose: 'Why choose Unxpose?',
      },
      dropdownMenu: {
        categories: 'Categories',
        profile: 'Profile',
        companySettings: 'Company settings',
        security: 'Security',
        notifications: 'Notifications',
        switchCompany: 'Switch company',
        logout: 'Logout',
      },
      buttons: {
        acceptTerms: 'Accept Terms and Conditions',
        addNewDomain: 'Add new domain',
        addNewMember: 'Add new member',
        addWebhook: 'Add webhook',
        addQuestionnaire: 'Add new',
        apply: 'Apply',
        back: 'Back',
        backLogin: 'Back to login',
        cancel: 'Cancel',
        confirm: 'Confirm',
        save: 'Save',
        remove: 'Remove',
        close: 'Close',
        copy: 'Copy',
        createAccount: 'Create my account',
        createFirstWebhook: 'Create your first webhook',
        createPassword: 'Create new password',
        createWebhook: 'Create webhook',
        declineTerms: 'Decline and log me out',
        delete: 'Delete',
        details: 'Details',
        checkPermissions: 'Check permissions',
        disableTwoFactorAuthentication: 'Disable two-factor authentication',
        disconnectGoogleAccount: 'Click here to disconnect.',
        disconnectMicrosoftAccount: 'Click here to disconnect.',
        enableTwoFactorAuthentication: 'Enable 2FA',
        forgotPassword: 'Forgot password',
        generateNewKey: 'Generate new key',
        generateQrCode: 'Click here to generate a QR Code and enable 2FA',
        generateReport: 'Generate report',
        googleSignIn: 'Sign in with Google',
        googleWorkspaceIntegration: 'Google Workspace Integration',
        integrateYourAccounts: 'Integrate your accounts',
        learnMore: 'Learn more',
        letsTalk: "Yes, let's talk",
        login: 'Login',
        microsoftSignin: 'Sign in with Microsoft',
        monitorDomain: 'Monitor domain',
        needHelp: 'Need help?',
        newIntegration: 'New integration',
        notInterested: 'Not interested, thanks',
        recoverAccess: 'Recover access',
        removeAndTry: 'Remove',
        retry: 'Retry',
        removeUrl: 'Remove URL',
        removeWebhook: 'Remove webhook',
        resendInvite: 'resend invite',
        resending: 'Resending...',
        saveChanges: 'Save changes',
        createWebhookTooltip:
          'You will be able to create only when the webhook is successfully tested',
        saveChangesWebhookTooltip:
          'You will be able to save only when the webhook is successfully tested',
        sendInvite: 'Send invite',
        setUpNow: 'Set up now',
        slackNotifications: 'Notify me on Slack!',
        startMonitoring: 'Start monitoring',
        testWebhook: 'Test webhook',
        unmerge: 'Unmerge',
        unmergeAll: 'Unmerge all',
        viewAll: 'View all',
        viewAllFindings: 'View all findings',
        downloadCSV: 'Download CSV',
        download: 'Download',
        add: 'Add',
        next: 'Next',
        verify: 'Verify',
        startForFree: 'Start for free',
      },
      userRoles: {
        regular: 'Member',
        readOnly: 'Read Only',
        questionnairesOnly: 'Questionnaires Only',
        suppliersQuestionnaires: 'Suppliers and Questionnaires',
      },
      notFound: {
        title: 'Page not found',
        reaction: 'Whoops!',
        body: "The page you're looking for doesn't exist.",
        goToHomeBtn: 'Go to main page',
      },
      sentryFeedbackReport: {
        title: 'Report issue',
        description: 'Description',
        required: '(required)',
        messagePlaceholder: 'What went wrong? Tell us what you expected',
        addScreenshotBtn: 'Add screenshot',
        removeScreenshotBtn: 'Remove screenshot',
        submitBtn: 'Submit',
        successMessageText: 'Thank you! We will investigate.',
      },
      unxpose: {
        support:
          'If you need assistance, just contact us at <a>support@unxpose.com</a>',
        slogan: 'Unxpose simplifies cybersecurity for you.',
      },
      dropFile: {
        dragAndDrop:
          'Drag and drop the file or <br> <strong>click to select</strong>',
        dragHere: 'Drop here',
        fileSelected: 'The file has been <strong>selected</strong>',
      },
      invitationPage: {
        pageTitle: 'View invite',
        title: 'Invite',
        body: '<strong>{{userName}}</strong> has invited you to join <strong>{{companyName}}</strong> workspace at Unxpose. Click the button below to accept the invitation and to have access to the organization.',
        expirationInfo:
          'This invitation will expire in <strong>{{remainingDays}} day(s)</strong>.',
        acceptInvite: 'Accept invite',
      },
      checkboxes: {
        newFindings: 'Activate to be notified whenever there is a new finding.',
        termsAndConditions: 'I agree to the',
        weeklyReport:
          "Activate to receive a weekly report of the company's security status.",
      },
      planInfo: {
        free: {
          menu: {
            title: 'Monitoring turned off',
            body: 'Your company has not been monitored since <strong>{{date}}</strong>.',
            subscribeBtn: 'Subscribe now',
          },
          modal: {
            title:
              'Your company has not been monitored since <strong>{{date}}</strong>',
            body: 'Your company is currently not being monitored. Start a TRIAL to get 7 days free of continuous Unxpose monitoring and access to all features!',
            secondaryBtn: 'Talk to sales',
            ignoreBtn: "I don't want to protect my company now",
            paperform: {
              title: 'Talk to sales',
              subtitle: 'Request a free 7-day trial of the solution!',
            },
          },
          section: {
            title: 'This feature is not available for your plan',
            subtitle:
              'Start the TRIAL now and access the solution without restrictions for 7 days',
            button: 'Learn more',
          },
        },
        paid: {
          menu: {
            title: 'Hey, {{username}}!',
            body: 'You have <strong>{{remainingDays}} days</strong> left on your plan.',
            subscribeBtn: 'Renew now',
          },
          modal: {
            title: 'You have {{remainingDays}} days left on your plan',
            body: 'At the end of your plan, monitoring will be interrupted, and your team will no longer have access to the platform. Renew now to continue keeping your company protected.',
            primaryBtn: 'Receive a proposal via email',
            secondaryBtn: 'Contact sales',
            ignoreBtn: 'Maybe later',
            paperform: {
              title: 'Schedule with us to renew or upgrade your plan',
            },
          },
        },
        proposalSuccess:
          'All set! You will soon receive a proposal in your registered email.',
      },
      sales: {
        subscribeBadge: '7 days free',
        upgradeBadge: 'Update your plan',
        secondaryBtn: 'Why choose Unxpose?',
        talkToSalesBtn: 'Contact Sales',
        footer:
          'Still not sure? You can request access to all features for free for 7 days!',
        stripeFooter:
          'Free 7-day trial! No upfront charges and security guaranteed by Stripe.',
        notAvailable: 'Option not available in the free plan.',
        autoSubscriptionModal: {
          titleFree: 'Unxpose Free for 7 Days',
          titleSubscribe: 'Subscribe to Unxpose',
          body: {
            firstItem: {
              title: 'Enjoy a free trial',
              description:
                'Enjoy 7 days of full access to explore all Unxpose features at no cost.',
            },
            secondItem: {
              title: 'Guaranteed security',
              description:
                'Your payment information is protected by Stripe, one of the world’s most secure and trusted payment platforms.',
            },
            thirdItem: {
              title: 'No Immediate charges',
              description:
                'Add your credit card with peace of mind: no charges will be made until the end of the 7-day trial period.',
            },
            fourthItem: {
              title: 'Cancel anytime',
              description:
                'Decide on your terms! Cancel your subscription at any time without hassle or additional fees.',
            },
          },
          footer:
            'After completing your registration on Stripe, <a>refresh the page</a> to update your account.',
          subscribeBtn: 'Subscribe now',
          expiredTrial:
            'Your TRIAL period ended on <strong>{{expirationDate}}</strong>.',
        },
        csv: {
          downloadCsvBtn: {
            details:
              '<strong>Exporting CSV</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            title: 'Relevant data in just one click',
            listItems: {
              first: `Extract your company's assets lists`,
              second:
                'Export the findings catalog by applying the necessary filters',
              third: 'Take data from Unxpose to compose customized reports',
            },
          },
        },
        dns: {
          addDomainBtn: {
            title: 'Monitor all exposed assets',
            details:
              '<strong>Add Domains</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            domainLimit_zero:
              'You <strong>do not have any limits for adding domains</strong> in your plan. Contact us to upgrade your resources!',
            domainLimit_one:
              'You have reached the <strong>limit of {{count}} domain</strong> for your plan. Contact us to upgrade and get more resources!',
            domainLimit_other:
              'You have reached the <strong>limit of {{count}} domains</strong> for your plan. Contact us to upgrade and get more resources!',
            listItems: {
              first:
                'Discover all assets that can be seen from outside to inside',
              second: 'Understand the security flaws threatening these assets',
              third: 'Follow the tutorials to make corrections',
            },
          },
          detailSections: {
            subdomainsAndDnsRecords: {
              featureName: 'DNS Records and Subdomains Listings',
              title: 'Your daily security',
              details:
                '<strong>DNS Records and Subdomains Listings</strong> are not available on your plan. Start a TRIAL and access all features for free for 7 days.',
              listItems: {
                first:
                  "Discover your company's subdomains daily for a comprehensive audit of your attack surface",
                second:
                  'Understand if there have been changes in any records, which may indicate malicious activity',
                third:
                  "Consult and export your company's list of subdomains and DNS records, updated daily",
              },
            },
          },
        },
        web: {
          addWebAppBtn: {
            title: 'Protect your company’s applications',
            details:
              '<strong>Adding Web App</strong> is not available in your plan. Contact our sales team and get access to all features for free for 7 days.',
            listItems: {
              first: 'Monitor the security of web applications in real time',
              second:
                'Get alerted and understand the vulnerabilities threatening your company’s websites',
              third:
                'See a step-by-step guide on how to fix detected vulnerabilities',
            },
          },
        },
        cloud: {
          cloudIntegrationTutorialBtn: {
            title: 'Monitor your Cloud assets',
            details:
              '<strong>Cloud Asset Monitoring</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            integrationLimit_zero:
              'You <strong>do not have any limits for integrations</strong> in your plan. Contact us to upgrade your resources!',
            integrationLimit_one:
              'You have reached the <strong>limit of {{count}} integration</strong> for your plan. Contact us to upgrade your resources!',
            integrationLimit_other:
              'You have reached the <strong>limit of {{count}} integrations</strong> for your plan. Contact us to upgrade your resources!',
            listItems: {
              first:
                'Understand the vulnerabilities that can expose your environment',
              second: 'See prioritized findings from recognized frameworks',
              third: 'Follow the tutorials to make corrections',
            },
          },
        },
        reports: {
          title: 'Have security in your hands',
          details:
            '<strong>Generate report</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
          listItems: {
            first:
              'Prove that your company is secure with our executive report with graphics, charts and a simple language',
            second: 'Show evidence of what has been done in cybersecurity',
            third: 'Share internally and with business partners',
          },
          exampleFileBtn: 'Example report',
        },
        config: {
          addWebhookBtn: {
            title: 'Create custom alerts',
            details:
              '<strong>Webhook Creation</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            listItems: {
              first: 'Receive alerts in the solutions your team already uses',
              second: 'Choose the severity of the failures to be alerted',
              third: "Integrate Unxpose into the team's daily routine",
            },
          },
          auditLogs: {
            title: 'Track Every Action',
            details:
              '<strong>Audit Logs</strong> are not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            listItems: {
              first:
                'Get a comprehensive record of user activities, enhancing security and compliance',
              second:
                'Review historical data to investigate incidents or track changes over time for better accountability',
              third:
                'Identify trends and patterns in user behavior for informed decision-making',
            },
          },
        },
        people: {
          addIdPBtn: {
            google: {
              details:
                '<strong>Integration with Google Workspace</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            },
            microsoft365: {
              details:
                '<strong>Integration with Microsoft 365</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            },
            integrationLimit_zero:
              'You <strong>do not have any limits for integrations</strong> in your plan. Contact us to upgrade your resources!',
            integrationLimit_one:
              'You have reached the <strong>limit of {{count}} integration</strong> for your plan. Contact us to upgrade your resources!',
            integrationLimit_other:
              'You have reached the <strong>limit of {{count}} integrations</strong> for your plan. Contact us to upgrade your resources!',
            title: 'Monitor the weakest link: people',
            listItems: {
              first: 'Integrate with just a few clicks',
              second:
                "See who is (or isn't) adhering to security best practices",
              third: 'Discover corporate credentials found in breaches',
            },
          },
        },
        overview: {
          securityScoreOverTime: {
            title: "Track Your Company's Evolution",
            details:
              '<strong>Security Score Over Time</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            listItems: {
              first:
                "Show the progress of your organization's cybersecurity health",
              second:
                'Communicate the level of exposure to a cyber attack using easily understandable KPIs',
              third:
                "Understand the impact of changes in your environment on your company's exposure to a cyber attack over time",
            },
          },
          totalClosedOverTime: {
            title: 'Lifecycle of findings',
            details:
              '<strong>Statistics</strong> are not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            listItems: {
              first: `Understand your team's ability to resolve the issues Unxpose discovers over time`,
              second:
                'Use the information to better plan the use of resources that address security fixes',
              third: "Track the evolution of your company's findings over time",
            },
          },
        },
        findings: {
          detailSections: {
            title: "Check your company's findings",
            details:
              '<strong>Viewing findings</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
            listItems: {
              first: 'See prioritized findings based on recognized frameworks',
              second:
                'Understand the potential impact of each and how to address them',
              third: 'Assign corrective actions and export reports',
            },
          },
        },
        whatsImportant: {
          title: 'Find out what are your priorities',
          details: `<strong>What's important</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.`,
          listItems: {
            first:
              'See a list of prioritized Findings based on recognizes frameworks',
            second:
              'Understand the potential impact of each Finding for the business',
            third: 'Follow tutorials on how to remediate each Finding',
          },
        },
        generateAnswerQuestionnaire: {
          title: 'Your team more efficient',
          details:
            '<strong>AI-generated answers</strong> are not available on your plan. Upgrade to a higher plan to access this and other features!',
          listItems: {
            first:
              'Use AI to answer questionnaires and let your team focus on more strategic activities',
            second:
              'Ensure greater accuracy of responses by avoiding human error',
            third:
              'Save time by not having to search through the entire questionnaire history to find an answer',
          },
        },
      },
      processingAlert: {
        content:
          "We've just started monitoring your company! <strong>Wait a few minutes</strong> to view your score changes and much more...",
      },
      paymentFailedAlert: {
        content:
          'There was a problem with your payment. <a>Access your subscription</a> <i></i>',
      },
      survey: {
        title: 'Help us shape the future of cybersecurity! 🚀',
        openBtn: 'Open survey',
      },
      marketingAlert: {
        content:
          'Hello, <strong>{{username}}</strong>. We need <strong>1 minute</strong> of your attention to improve Unxpose! <strong>Join our survey</strong> and help us shape the future of cybersecurity! 🚀',
        primaryBtn: 'Take survey',
      },
      cloud: {
        tags: {
          nonPersonalData: 'non-personal data',
        },
        success: {
          notSensitiveTagAdded: `Tag "non-personal data" added to the asset`,
          notSensitiveTagRemoved: `Tag "non-personal data" removed from asset`,
        },
        markAsNonPersonal: `Mark that this asset does not contain personal data`,
        noIntegrationsBeingMonitored:
          'None of your integrations are being monitored at the moment.',
        accountBeingMonitored: 'Account being monitored',
        accountFailing: 'Account failing',
        accountId: 'Account ID',
        accountsBeingMonitored: 'Accounts being monitored',
        accountsFailing: 'Accounts failing',
        updateAvailable: 'Update available!',
        followOurGuide:
          'Click here and follow our guide to access our latest features.',
        removal: {
          modal: {
            submit: 'Remove',
            title: 'Integration Removal',
            warning:
              'All assets and findings related to this integration will be permanently deleted from Unxpose.',
          },
          alternateActionButton: 'Remove Integration',
          dropdownButton: 'Remove Integration',
        },
        aws: {
          errors: {
            accessDenied: {
              explanation:
                'Access denied. To resolve this issue, follow these steps:',
              listItem1:
                "Make sure the created Role and Policy are named exactly as 'UnxposeRole' and 'UnxposePolicy', respectively.",
              listItem2:
                'Make sure the Account ID used to assume the Role matches the Account ID of Unxpose (708205383577).',
              listItem3:
                'Make sure the External ID entered is the same as the one provided in the integration tutorial ({{externalId}}).',
            },
          },
          cloudFormationIntegration: {
            title:
              'How to integrate Unxpose with your AWS account using CloudFormation',
            intro: {
              title: 'Introduction',
              body: `In this tutorial, we'll walk you through the process of connecting your AWS account with Unxpose using CloudFormation. Don't worry, this process is straightforward and can be completed in just a few minutes. So, let's get started!`,
            },

            stepOne: {
              title: 'Step 1: Create CloudFormation stack',
              body: `{{click_here}} to go to the URL for creating a stack. Once you're on the page, check the checkbox to grant the necessary permission. Please note that you should not modify the parameters field. Finally, click {{create_stack}} to create the stack and finalize the process.`,
              createStack: 'Create stack',
              attention:
                'Attention: wait until the stack is created before proceeding to the next step. This process may take a few minutes. You will see the following message once the stack is ready.',
            },
            stepTwo: {
              title: 'Step 2: Enable account monitoring',
              body: 'Fill the field below with your Amazon account ID (12-digit number) and click the {{monitor_my_account}} button. Congratulations! The integration process will now commence. Make sure to visit the Cloud section later!',
              accountIdHelp:
                '{{click_here}} if you need help finding your account ID.',
              amazonAccountId: 'Amazon account ID',
              monitorMyAccount: 'Monitor my account',
            },
          },
          integrationOptions: {
            chooseMethod: 'Choose one of the following integration methods',
            manualSetup: 'Manual setup',
            manualSetupDescription:
              'Access your AWS console and follow the instructions to complete the integration.',
            selectThisMethod: 'Select this method',
            cloudFormationDescription:
              'Connect your account in just a few clicks using CloudFormation.',
          },
          ebsEncrypted: 'True',
          ebsNotEncrypted: 'False',
          mfaEnabled: 'Enabled',
          mfaNotEnabled: 'Not enabled',
          route53PrivateZoneFalse: 'False',
          route53PrivateZoneTrue: 'True',
          route53AutoRenewEnabled: 'True',
          route53AutoRenewNotEnabled: 'False',
          route53TransferLockEnabled: 'True',
          route53TransferLockNotEnabled: 'False',
          vpcIsDefault: 'True',
          vpcIsNotDefault: 'False',
          integrationRemoved: `Your AWS account is no longer integrated with us. Don't forget to remove the UnxposeRole at your AWS Management Console.`,
          integrationRetried: `Retrying integration. Come back in a few minutes!`,
          integration: {
            title: 'How to integrate Unxpose with your AWS account',
            stepOne: {
              title: 'Step 1: Creating a read-only Policy for Unxpose',
              inOrderToMonitor: `In order to monitor your cloud network, you need to grant us access to some of your AWS resources. Don't worry: we created a special policy that will allow us to scan for vulnerabilities while`,
              toCreateThisPolicy:
                'To create this policy, navigate to the IAM section of the AWS console and select',
              nowClickOn: 'Now click on',
              andOpenThe: 'and open the',
              tab: ' tab',
              theFollowingConfiguration:
                'Copy the following configuration and paste it on the text editor.',
              select: 'Select',
              addSomeTags: 'Add some tags if you want to, and then click on',
              finallyFill: 'Finally, fill the',
              fieldWith: 'field with',
              andClickOn: 'and click on',
              nowLetsMove: `Now, let's move to the next step.`,
              thePolicyNameMust: 'The policy name must be exactly',
              highlights: {
                mantainingTheContent:
                  'mantaining the content from your storage inaccessible.',
                policies: 'Policies.',
                createPolicy: 'Create policy',
                json: 'JSON',
                nextReview: 'Next: Review',
                name: 'Name',
                unxposePolicy: 'UnxposePolicy',
                attention: 'Attention!',
              },
            },
            stepTwo: {
              andThen: 'and then',
              title: 'Step 2: Setting up an IAM role for Unxpose',
              goToTheIam:
                'Go to the IAM section of the AWS console. Once there, select',
              clickOnThe: 'Click on the',
              button: ' button',
              select: 'Select',
              fill: 'Fill',
              fieldWithOur: 'field with our ID',
              selectThe: 'Select the',
              checkbox: ' checkbox',
              fillThe: 'Fill the',
              fieldWithTheFollowing: 'field with the following value (',
              theValueFor: 'the value for',
              updatedTo: 'is updated for each new integration attempt):',
              click: 'Click',
              selectTheFollowing: 'Select the following policy from the list:',
              optionalAddTags:
                '(Optional) Add tags to this role if you wish. Click',
              fieldWith: 'field with',
              theRoleNameMust: 'the Role name must be exactly',
              youCanAlsoAdd: 'You can also add a description if you wish.',
              reviewTheInformation: 'Review the information and click',
              highlights: {
                roles: 'Roles',
                createRole: 'Create role',
                awsAccount: 'AWS Account',
                anotherAwsAccount: 'Another AWS account',
                accountId: 'Account ID',
                requireExternalId: 'Require external ID',
                externalId: 'External ID',
                important: 'IMPORTANT:',
                next: 'Next',
                roleName: 'Role name',
                unxposeRole: 'UnxposeRole',
                unxposePolicy: 'UnxposePolicy',
              },
            },
            stepThree: {
              title: 'Step 3: Integrating Unxpose with your AWS account',
              fillTheField:
                'Fill the field below with your Amazon account ID (12-digit number).',
              clickHere: 'Click here',
              ifYouNeedHelp: ' if you need help finding your account ID.',
              amazonAccountId: 'Amazon account ID',
              monitorMyAccount: 'Monitor my account',
              idAlreadyInUse: 'This account ID is already in use.',
              invalidAccountId: 'Invalid account ID.',
            },
          },
          removeIntegration: {
            tryingToRemoveAccount:
              'You are trying to remove integration with account ID: ',
          },
          integrationUpdate: {
            manual: {
              title: 'How to update your integration on AWS (Manual)',
              description:
                'This guide will help you update permissions for your AWS integration.',
              steps: {
                one: '1. After accessing the [AWS console](https://console.aws.amazon.com/), search for **IAM** in the top search field.',
                two: '2. In the left sidebar, go to **Policies** _(1)_',
                three:
                  '3. In the list of policies, search for **UnxposePolicy** _(2)_',
                four: '4. In the **Permissions** tab *(3)*, you will edit the policy. Click the **Edit** button *(4)*',
                five: '5. Choose the **JSON** option *(5)*. In the text editor *(6)*, replace the content of the policy with the following new content:',
                six: '6. Click **Next** and review the information',
                seven: '7. To finish, click **Save**',
              },
              conclusion:
                'Now you should wait up to 24 hours for Unxpose to perform a new check of this updated integration.',
              copyJson: 'Copy JSON',
            },
            cloudFormation: {
              title: 'How to update your integration on AWS (CloudFormation)',
              description:
                'This guide will help you update the permissions for your integration using the CloudFormation service integration on AWS.',
              steps: {
                one: '1. After accessing the [AWS console](https://console.aws.amazon.com/), search for **CloudFormation** in the top search bar',
                two: '2. Look for the **UnxposeIntegration** stack *(1)* and click on it',
                three: '3. Click the **Update** button',
                four: '4. Select the **Replace current template** option *(3)*',
                five: '5. In the **Amazon S3 URL** field *(4)*, enter the following URL:',
                six: '6. Click **Next** until the last page',
                seven:
                  '7. Check the checkbox *(5)* in the **Capabilities** section that appears at the end of the page',
                eight:
                  '8. Click **Update** and wait for the stack update to be completed',
              },
              errorCase: {
                title: 'What to do if the stack update fails?',
                description:
                  'When there is a failure in updating the stack, you need to remove it and add it again following these steps:',
                steps: {
                  one: '1. Remove the **UnxposeIntegration** stack *(6)*. Wait for a few moments for the removal to be completed',
                  two: '2. Add the stack again using [this link]({{stack_link}})',
                },
                conclusion:
                  'After creating the stack, you should wait up to 24 hours to confirm that the update has been successfully applied.',
              },
            },
          },
        },
        gcp: {
          errors: {
            billingDisabled:
              'Billing is disabled for the project you are trying to integrate. Enable it by visiting https://console.cloud.google.com/billing/projects and associating your project with a billing account.',
            disabledApi: {
              failedIntegrationMessage:
                'This API has not been used before or it is disabled in the project we are checking. If you enabled this API recently, wait a few minutes for the action to propagate to our systems and retry.',
              accordionWarningMessage: 'Enable this API in GCP console',
            },
            notAuthorized:
              'Unauthorized access. Please repeat the integration process and ensure that you have permission to access the following services:',
            invalidProject:
              'The project you are trying to check does not exist or has been disabled/deleted.',
            accountDeleted:
              'The account you are trying to check does not exist or has been disabled/deleted.',
          },
          integrationRemoved: `Your GCP account is no longer integrated with us. Don't forget to remove the resources created for Unxpose integration at your GCP Management Console.`,
          integrationRetried:
            'Retrying integration. Come back in a few minutes!',
          integrationOptions: {
            chooseMethod: 'Choose one of the following integration methods',
            manualSetup: 'Manual setup',
            manualSetupDescription:
              'Access your GCP console and follow the instructions to complete the integration.',
            selectThisMethod: 'Select this method',
            terraformDescription:
              'Generate a configuration file and set up integration quickly using Terraform.',
          },
          terraformIntegration: {
            title:
              'How to integrate Unxpose with your GCP account using Terraform',
            intro: {
              title: 'Introduction',
              body: 'Learn how to integrate your GCP account using Terraform with this detailed tutorial.',
            },
            prerequisites: {
              title: 'Prerequisites',
              gcpAccount: 'GCP Account',
              gcpCli: 'GCP CLI installed on your computer',
              terraform: 'Terraform installed on your computer',
            },
            stepOne: {
              title: 'Step 1: Authenticate to the GCP CLI',
              body: 'Run the {{command}} command in the terminal and log in to your GCP account on the page that will be opened in your browser. This will allow you to authenticate to the GCP CLI and access your projects.',
            },
            stepTwo: {
              title: 'Step 2: Download the configuration file',
              downloadLink: 'Click here',
              body: ' to download the zip file containing the necessary configurations to integrate your projects with the application. Then, unzip the zip file to access the configuration files.',
            },
            stepThree: {
              title: 'Step 3: Insert the Project ID',
              body: 'Open the {{file}} file and look for the {{organization_id}} field. Insert your organization ID, which is a 10-digit number, into this field. You can find your organization ID on the dropdown list at the top of the GCP console page.<br/><br/> Next, locate the {{project_ids}} field and insert the ID(s) of the project(s) you want to integrate. Save the changes to the {{file}} file.',
            },
            stepFour: {
              title: 'Step 4: Initialize Terraform',
              body: 'Open the terminal and navigate to the directory where the {{file}} file is located. Then, run the {{command}} command to initialize Terraform and download the necessary dependencies.',
            },
            stepFive: {
              title: 'Step 5: Create the necessary resources with Terraform',
              body: `Now, run the {{command}} command. This will create the necessary GCP resources for integration with your application, such as access keys and secrets. The changes that will be made will be displayed on the console, and you will be prompted to enter 'yes' in order to continue with the resource creation process.`,
            },
            stepSix: {
              title: 'Step 6: Wait for the resources to be created',
              body: 'Wait for Terraform to finish creating the resources. When finished, a file will be generated in the root of the directory for each integrated project. These files will contain the credentials necessary to access the project resources.',
            },
            stepSeven: {
              title: 'Step 7: Upload the configuration file',
              body: `Upload the generated file(s) in the root of the project. They will be named as {{file}}. Please note that there will be one generated file per project, and you will need to upload each one individually. To upload, drag each file to the upload area below.`,
            },
            conclusion: {
              title: 'Conclusion',
              body: 'Congratulations! Almost done. Just click the button below to make your Google Cloud Platform account more secure with Unxpose.',
            },
          },
          manualIntegration: {
            title: 'How to integrate Unxpose with your GCP account',
            intro: {
              title: 'Introduction',
              inOrderToDiscover:
                'In order to discover and monitor your Google Cloud Platform resources we need you to set up an authorization to our account. This could be achieved using different strategies that might or not include keys/passwords. We opted to use a keyless integration to minimize the risk to your organization. It works by using identity federation to allow our AWS account to impersonate a Service Account in your GCP project.',
              stepOne: 'Step 1: Create a Role at organization level',
              stepTwo: 'Step 2: Create a Service Account',
              stepThree:
                'Step 3: Grant Organizational Access to Service Account',
              stepFour: 'Step 4: Create a Workload Identity Pool',
              stepFive: 'Step 5: Grant access to the Service Account',
              theFollowingTutorial:
                'The following tutorial will guide you through the GCP integration. If you have any kind of trouble with the process, feel free to ask for assistance through our',
              supportEmail: 'support email.',
            },
            stepOne: {
              title: 'Step 1: Create a Role at organization level',
              theFirstThing:
                'The first thing you need to do is to create a new custom role at your GCP organization. This role will carry all the permissions needed by Unxpose to monitor your resources. Access your GCP console at',
              selectYourOrganization:
                'Select your organization using the dropdown menu on the topbar.',
              onceYouClick:
                'Once you click on the dropdown, a modal will show up with a list containing projects and organizations. Select the organization you would like to monitor.',
              onceYouSelected:
                'Once you selected the organization, click on the navigation menu on the top left. Select',
              andThen: 'and then',
              chooseARole:
                'Choose a role title that you can easily associate with Unxpose.',
              clickOn: 'Click on',
              selectEachOf:
                'Select each of following permissions for the new role:',
              finishThisStep: 'Finish this step by clicking on the',
              button: ' button',
              highlights: {
                iamAdmin: 'IAM & Admin',
                roles: 'Roles',
                createRole: 'Create Role.',
                addPermissions: 'Add permissions.',
                create: 'Create',
              },
            },
            stepTwo: {
              title: 'Step 2: Create a Service Account',
              firstlyYouNeed:
                'Firstly, you need select the project you want to integrate with Unxpose using the dropdown you used to select the organization on the first step of this tutorial.',
              nowYouNeed:
                'Now you need to create a Service Account in the project you want to monitor. You can do this by accessing',
              clickOn: 'Click on',
              giveTheService:
                'Give the Service Account a name that you can easily associate with Unxpose.',
              whileCreating:
                'While creating a Service Account you need to add the role you created on the first step.',
              highlights: {
                iamServiceAccounts: 'IAM & Admin > Service Accounts.',
                createServiceAccount: 'Create Service Account.',
              },
            },
            stepThree: {
              title: 'Step 3: Grant Organizational Access to Service Account',
              evenThough:
                'Even though the Service Account was created at the project level, you should grant it permissions at the organization level so we can validate the role permissions. To do this, follow these steps:',
              steps: {
                organizationSelect:
                  'Make sure your organization is selected at the top of the Google Cloud Console page.',
                accessIAM: 'Access <strong>IAM & Admin > IAM</strong>.',
                grantAccess: 'Click on Grant Access.',
                newPrincipal:
                  'In the New Principal field, enter the email of the Service Account created in Step 2.',
                selectRole:
                  'In the Role field, select <strong>Custom Roles > [Your Unxpose Role]</strong>.',
                saveButton: 'Click Save to apply the permissions.',
              },
            },
            stepFour: {
              title: 'Step 4: Create a Workload Identity Pool',
              aWorkloadIdentity:
                'A Workload Identity Pool will grant external identities IAM roles, including the ability to impersonate Service Accounts. This lets you access resources directly, using a short-lived access token, and eliminates the maintenance and security burden associated with Service Account keys.',
              toReadMore:
                'To read more about Workload Identity Federation access:',
              withTheProject: 'With the project selected, access ',
              clickOn: 'Click on',
              chooseAPool:
                'Choose a Pool name/ID you can easily associate with Unxpose.',
              addAAwsProvider: 'Add a AWS provider to the pool.',
              chooseAProvider:
                'Choose a Provider name/ID you can easily associate with Unxpose and when asked for the AWS account ID insert ours:',
              finishThisStep: 'Finish this step by clicking on the',
              button: 'button',
              highlights: {
                iamWorkload: 'IAM & Admin > Workload Identity Federation.',
                createPool: 'Create Pool.',
                save: 'Save ',
              },
              attributeMapping:
                'On the section <strong>Configure provider attributes</strong> click on "Edit mapping" and add the attribute mapping "attribute.account" to "assertion.account".',
            },
            stepFive: {
              title: 'Step 5: Grant access to the Service Account',
              nowThatYou:
                'Now that you created the Workload Identity Pool, you need to give all identities in that pool access to the Service Account. On the Workload Identity Federation screen click on the edit button for the created pool.',
              clickOn: 'Click on',
              selectImpersonation:
                'Select <strong>Grant access using Service Account impersonation</strong> from the available options',
              selectTheService:
                'Select the Service Account you created in this tutorial.',
              attributeMatching:
                'On the section <strong>Select principals</strong> choose the attribute <strong>account</strong>, fill our AWS Account ID (<strong>708205383577</strong>) and click <strong>Save</strong>, as demonstrated in the following image.',
              onTheModal:
                'On the modal that shows up, choose the provider previously created and download the Config file.',
              uploadTheConfig:
                'Upload the config file here so we can connect with your cloud. You can do this by dragging the file to the area below.',
              dragAndDrop:
                'Drag and drop your config file here, or click to search in your computer',
              invalidConfigFile: 'Invalid config file.',
              highlights: {
                grantAccess: 'Grant Access.',
                save: 'Save.',
              },
            },
            stepSix: {
              title: 'Step 6: Ensure all APIs Are Enabled',
              text: 'Remember to verify that the necessary resources APIs are enabled for your project. Use the following links to check and enable each API:',
            },
            theEnd: {
              title: 'The End',
              congratulations: `Congratulations! You are all set. Click on the button below to make your Google Cloud Platform account safer with Unxpose.`,
              monitorMyAccount: 'Monitor my account',
            },
          },
          updateIntegration: {
            updateYourIntegration:
              'Update your integration with Google Cloud Platform',
            sinceLastUpdate:
              'The following permissions have been added since the last update:',
            whyDoingThis: 'Why do I have to do this?',
            alwaysAddingNew:
              'We are constantly adding new checks, either to expand the scope of the already monitored services or to start monitoring new GCP services. For this, on some occasions, it will be necessary to ask you to add new permissions related to these new services. If you have any questions, feel free to request assistance through our',
            stepByStep: {
              title:
                'Here is a step-by-step guide on what needs to be done to add this new permission:',
              stepOne: {
                title: 'Step 1: Access the role at the organization level',
                afterDropDown: `After clicking on the dropdown, a modal with a list containing projects and organizations will appear. Select the organization that was used in the integration. Note that you must select the "parent" of the integrated project and not the project itself.`,
                accessPortal:
                  'Access the Google Cloud Platform portal with your corporate user at',
                clickDropDown: '. Click on the dropdown in the top bar.',
              },
              stepTwo: {
                title: 'Step 2: Add missing permissions',
                addButton: 'Add',
                addPermissions: 'Add permissions',
                lookForRole:
                  'Search for the role that was created at the time of integration; it is likely that it was created using the name',
                accessRole:
                  'Access the role by clicking on it, and then click on',
                demonstration: 'As shown in the following image.',
                editRole: 'Edit Role',
                editionScreenClick: 'Within the role editing screen, click on',
                toAdd:
                  'To add the missing permissions, you can use the filtering feature located above the table. Then, you need to check the permission checkbox and click on ',
                toFinish: 'To finish this step, click the button ',
                updateButton: 'Update',
                verifyPermissions:
                  'Verify that your role contains all of the following permissions:',
              },
              stepThree: {
                title: 'Step 3: Grant Organizational Access to Service Account',
                evenThough:
                  'Even though the Service Account was created at the project level, you should grant it permissions at the organization level so we can validate the role permissions. To do this, follow these steps:',
                steps: {
                  organizationSelect:
                    'Make sure your organization is selected at the top of the Google Cloud Console page.',
                  accessIAM: 'Access <strong>IAM & Admin > IAM</strong>.',
                  grantAccess: 'Click on Grant Access.',
                  newPrincipal:
                    'In the New Principal field, enter the email of the Service Account created in Step 2.',
                  selectRole:
                    'In the Role field, select <strong>Custom Roles > [Your Unxpose Role]</strong>.',
                  saveButton: 'Click Save to apply the permissions.',
                },
              },
              stepFour: {
                title: 'Step 4: Confirm update success',
              },
            },
          },
          removeIntegration: {
            tryingToRemoveProject:
              'You are trying to remove integration with project: ',
          },
        },
        azure: {
          errors: {
            applicationNotFound:
              'The application was not found in the directory. This can happen if the application has not been installed by the administrator of the tenant or consented to by any user in the tenant. You may have sent your authentication request to the wrong tenant.',
            endpointResolutionError:
              'Could not resolve endpoints. Please make sure you entered the Directory ID correctly.',
            expiredClientSecret:
              'The provided client secret keys are expired. Visit the Azure portal to create new keys for your app.',
            invalidClientSecret:
              'Invalid client secret provided. Ensure the secret being sent in the request is the client secret value, not the client secret ID.',
            noSubscriptionFound:
              'Unable to find a subscription. Make sure you have a subscription related to your Azure Active Directory tenant.',
            tenantNotFound: `Tenant not found. Ensure the tenant ID is correct and you're signing into the correct cloud. This error may occur if there are no active subscriptions for the tenant.`,
            subscriptionTypeNotPermitted:
              'The current subscription type is not permitted to perform operations on any provider namespace. Please use a different subscription.',
          },
          integrationRemoved: `Your Azure account is no longer integrated with us. Don't forget to remove the resources created for Unxpose integration at your Azure Management Console.`,
          integration: {
            title: 'How to integrate Unxpose with your Azure account',
            intro: {
              inOrderTo:
                'In order to monitor your cloud network, you need to grant us access to some of your Azure resources. We will always try to request the least amount of permissions necessary.',
              theFirstThing:
                'The first thing you will need is to create an Azure app for Unxpose.',
              toCreateAn: 'To create an Azure app, your account must have the',
              roleForTheDirectory:
                'role for the Azure Active Directory and the',
              roleForTheSubscription:
                'role for the Azure subscription. Assign these roles to your Azure account before proceeding.',
              highlights: {
                userAdministrator: 'User Administrator',
                userAccessAdministrator: 'User Access Administrator',
              },
            },
            stepOne: {
              title: 'Step 1: Create the Azure app',
              accessThe: 'Access the',
              page: ' page',
              clickOnThe: 'Click on the',
              buttonAndThen: 'button and then',
              enterAName:
                'Enter a name for your Azure app (for example, Unxpose Integration).',
              forTheSupported: 'For the "Supported account types", select',
              clickOn: 'Click on',
              highlights: {
                microsoftEntraId: 'Microsoft Entra ID',
                add: 'Add',
                appRegistration: 'App registration.',
                accountsInThis:
                  'Accounts in this organizational directory only.',
                register: 'Register.',
              },
            },
            stepTwo: {
              andThen: 'and then',
              title: 'Step 2: Create an application secret',
              inTheAzureActive: 'In the Azure Active Directory, access',
              menu: ' menu',
              clickApplication: 'Click on the application you just created.',
              clickMenu: 'Click on the menu',
              clickOn: 'Click on',
              enterADescription: 'Enter a description for the client secret.',
              selectTheDesired:
                'Select the desired duration for this credential (24 months). The client secret expires after this time. We recommend that you generate a new secret and submit it here when it is close to the expiration date.',
              theClientSecret: 'The client secret value should appear.',
              recordTheClient: 'Record the client secret',
              youWillUse:
                'You will use it when registering the Azure app with Unxpose. Make sure you copy the',
              andNotThe: 'and not the',
              warningTitle: 'Warning',
              warningBody:
                'The client secret Value only appears once, so record it now. If you do not, you must generate a new client secret to obtain a new ',
              highlights: {
                appRegistrations: 'App registrations',
                certificatesAndSecrets: 'Certificates & secrets.',
                manage: 'Manage',
                newClientSecret: 'New client secret.',
                add: 'Add',
                value: 'Value',
                secretId: 'Secret ID.',
              },
            },
            stepThree: {
              title: 'Step 3: Configure Subscriptions',
              intro:
                'For Unxpose to monitor your resources, you need to create a new Custom Role in the Subscription and assign it to the previously created Application. These steps bellow must be repeated for each subscription you want to monitor.',
              one: '3.1: Create a new Custom Role',
              two: '3.2: Assign the function to the Azure app',
              inThe: 'In the',
              pageClick: 'page, click on your subscription.',
              clickOnTheAccess: 'Click on the',
              clickOnTheButton: 'Click on the',
              clickOnTheTab: 'Click on the',
              menu: ' menu',
              clickOn: 'Click on',
              andThen: 'and then',
              createARole:
                'Create a role with a descriptive name (for example, UnxposeRole).',
              tabAndThen: 'tab and then click on the',
              button: ' button',
              addTheFollowing:
                'Add the following permissions to the property "actions" from the existing JSON. It is located under',
              buttonForThe: 'button for the JSON field.',
              andThenClick: 'and then click on',
              unfortunatelyItMight:
                'Unfortunately, it might take some minutes for your role to become available so take a deep breath before the next step.',
              highlights: {
                subscriptions: 'Subscriptions,',
                accessControl: 'Access Control (IAM)',
                add: 'Add',
                addCustomRole: 'Add Custom Role.',
                json: 'JSON',
                edit: 'Edit',
                propertiesActions: 'properties > permissions > actions.',
                save: 'Save',
                reviewCreate: 'Review + create',
                create: 'Create',
              },
              conclusion:
                'Remember to repeat this process for each subscription you want to monitor.',
            },
            stepFour: {
              inTheSection: 'In the',
              inThePage: 'In the',
              pageClick: 'page, click on your subscription.',
              clickOnThe: 'Click on the',
              menu: ' menu',
              clickOn: 'Click on',
              andThen: 'and then',
              selectTheRole:
                'Select the role you just created (for example, UnxposeRole) and click',
              ifYouCantFind: `If you can't find it, wait a few minutes and refresh the page.`,
              on: 'On',
              sectionSelect: 'section, select',
              sectionClick: 'section, click on',
              onThe: 'On the',
              fieldType:
                'field, type the name of the Azure app you have created (for example, Unxpose Integration) and click on it. Then, click on the',
              button: ' button',
              reviewCreate: 'Review + create.',
              invalidConfiguration: 'Invalid configuration.',
              directoryIdAlreadyInUse: 'This Directory ID is already in use.',
              genericError:
                'Something went wrong. Check your configuration and try again.',
              highlights: {
                subscriptions: 'Subscriptions',
                accessControl: 'Access Control (IAM)',
                add: 'Add',
                addRoleAssignment: 'Add role assignment.',
                next: 'Next',
                assignAccessTo: 'Assign access to',
                userGroupOrService:
                  'Assign access to User, group, or service principal.',
                select: 'Select',
                selectMembers: '+Select members',
                members: 'Members',
              },
            },
            stepFive: {
              title: 'Step 4: Integrate your Azure account',
              nowThatYou: `Now that you have completed all the steps on your Azure account, you need to provide us the information needed to identify and authenticate on your account.`,
              youCanEasily:
                'You can easily find your Application ID and Directory ID by accessing your',
              andClickingOn: 'and clicking on the app you have created.',
              findingTheApplication:
                'Finding the Application ID and Directory ID:',
              type: 'Type',
              inTheSearch: 'in the search bar.',
              clickOnApplication:
                'Click on the application you have created on this tutorial.',
              copyTheCredentials:
                'Copy the credentials highlighted on the image above.',
              applicationId: 'Application (client) ID',
              directoryId: 'Directory (tenant) ID',
              secretValue: 'Secret value',
              uuidFound: 'UUID found on your application page',
              randomValue: 'Random value you copied on Step 2',
              monitorMyAccount: 'Monitor my account',
              highlights: {
                appRegistrations: 'App registrations',
              },
            },
          },
          removeIntegration: {
            tryingToRemoveTenant:
              'You are trying to remove integration with tenant: ',
          },
        },
        connectingToProvider: 'We are connecting to your cloud provider',
        directoriesBeingMonitored: 'Directories being monitored',
        directoriesFailing: 'Directories failing',
        directoryBeingMonitored: 'Directory being monitored',
        directoryFailing: 'Directory failing',
        directoryId: 'Directory ID',
        failedIntegration: 'The integration has failed.',
        gcpIntegrationInProgress: 'Your GCP integration is in progress.',
        integrations: 'Integrations',
        filterByProvider: {
          placeholder: 'Filter by provider',
          options: {
            aws: 'AWS',
            gcp: 'GCP',
            azure: 'Azure',
          },
        },
        sortBy: {
          placeholder: 'Order by',
          options: {
            bestScore: 'Best score',
            worstScore: 'Worst score',
          },
        },
        manageResources: 'Manage your cloud resources',
        monitorYourCloud1: 'Monitor your cloud environment',
        monitorYourCloud2: 'to ensure your resources are always safe',
        multicloudNotEnabled: "It's not possible to add multiple accounts.",
        noAssets:
          'There are no assets associated with this service being monitored right now',
        noFindingsInIntegration:
          'There are no findings associated with this integration right now',
        noFindingsInService:
          'There are no findings associated with this service right now',
        projectBeingMonitored: 'Project being monitored',
        projectFailing: 'Project failing',
        projectId: 'Project ID',
        projectsBeingMonitored: 'Projects being monitored',
        projectsFailing: 'Projects failing',
        selectProvider1: 'Select below which account you want to access',
        selectProvider2: 'and keep up with the latest findings',
        startIntegration:
          'Select your provider to start the integration process',
        noResults: {
          title: 'No account was configured',
          advantages: {
            title: 'Protect your company’s Cloud',
            listItems: {
              first:
                'Connect your company’s clouds in just a few clicks and identify configuration flaws using recognized frameworks',
              second:
                'View security flaws prioritized by risk level with evidence of where they were found, understand the impact of each, and follow step-by-step instructions to fix them',
              third:
                'Unxpose performs daily scans so you don’t have to worry about scheduling scans',
            },
          },
        },
      },
      compliance: {
        lgpd: {
          integrationsUnderMonitoring: 'Integrations under LGPD monitoring',
          lgpdBenefitsItems: {
            first: 'Know the level of compliance of your environment with LGPD',
            second: 'See which security flaws impact this indicator',
            third: 'Understand how to improve it',
          },
          toInsureYourCompany:
            'To ensure your company is LGPD compliant, we first need you to connect your Cloud Provider to Unxpose. Click on the link below and follow the tutorial!',
          lgpdAndCloud:
            'LGPD and Cloud: Increase the compliance level of your environment',
          selectTheIntegrations:
            'Select the integrations you want to monitor for LGPD',
          definition: `The "Lei Geral de Proteção de Dados" (13.709/2018) main objective is to protect the fundamental rights of freedom and privacy. To comply with the law, companies need to implement improvements in the data treatment process in the technology layer – including the cloud.`,
          improveYourCompliance: `Click on Details to understand how you can increase your company's compliance with LGPD.`,
          buttons: {
            manage: 'Manage',
            saveChanges: 'Save changes',
            startMonitoring: 'Start monitoring',
          },
          disclaimer: {
            title: 'Disclaimer',
            body: 'Unxpose is a cybersecurity company focused on identifying security issues in the attack surface, in the Cloud resources and in the SaaS providers used by your employees. Accordingly, our compliance analysis also considers these environments, and cannot be used as an indication of LGPD compliance in other virtual or physical environments within your company. Our compliance score assesses LGPD-related security settings that can reduce or mitigate incident risks.',
          },
          modal: {
            lgpdArticle: 'What does the law say?',
            reason: 'How does this finding relate to the LGPD?',
          },
          stats: {
            adhesion: 'Conformity',
            assets: 'Assets',
            findings: 'Findings',
            integrations: 'Integrations',
            providers: 'Providers',
          },
          success: {
            noFindings:
              'There are no Compliance findings related to your integrations right now.',
            updateIntegration: 'Your integrations have been updated.',
          },
          tables: {
            article: 'Article',
            name: 'Name',
            provider: 'Provider',
          },
        },
      },
      dns: {
        addDomainBtn: {
          submit: 'Add new domain',
          cancel: 'Cancel',
        },
        domainVerification: {
          title: 'Domain verification',
          body: {
            firstParagraph:
              'It is necessary to verify the domain <strong>{{hostName}}</strong> to complete its addition. You can verify it in two ways: by adding a <strong>TXT record to the domain DNS</strong> or by <strong>uploading a TXT file</strong> to your server.',
            secondParagraph:
              'Verification is important to ensure that only authorized individuals have access to the data related to the domain.',
          },
          selectMethod: {
            label: 'Verification method',
            recordMethod: 'Add a TXT record to your domain',
            fileMethod: 'Upload a TXT file to your server',
          },
          recordMethodSteps: {
            first:
              'Access your domain provider and look for the <strong>DNS Records</strong> section',
            second:
              'Copy the TXT value below and register it with your provider',
            third:
              'After ensuring the record has been updated, click <strong>Verify</strong>',
            noteForThird:
              '<strong>Note:</strong> DNS changes can take up to 48 hours to propagate',
          },
          fileMethodSteps: {
            first: '<a>Download</a> the TXT verification file',
            second: 'Upload this file to the root of the domain',
            noteForSecond:
              'We will look for it at <strong>{{domainPath}}</strong>',
            third: 'All set! After uploading, click <strong>Verify</strong>',
            noteForThird: 'We will check it automatically',
          },
          support: 'If you need help, contact us at <a>support@unxpose.com</a>',
        },

        filters: {
          typePlaceholder: 'Filter by record type',
        },
        nameServersTable: {
          nakedDomainsCount: '# of naked domains',
          nameServers: 'Name servers',
          subdomainsCount: '# of subdomains',
          title: 'Name servers breakdown',
        },
        filterByRegistrar: {
          placeholder: 'Filter by registrar',
        },
        nameServerDnsRecords: 'Name server DNS records',
        dnsRecords: 'DNS records',
        domains: 'Domains',
        removeDomain: 'Remove domain?',
        noFindings:
          'There are no findings associated with this domain right now.',
        noImportantFindings: 'There are no important findings right now.',
        noRecords:
          'There are no DNS records associated with this domain right now.',
        noSubdomains:
          'There are no subdomains associated with this domain right now.',
        organizationDomains: 'Organization domains',
        subdomains: 'Subdomains',
        domainScheduledForRemoval: 'will soon be removed.',
      },
      errors: {
        atLeastFiveCharacters: 'The email should have at least 5 characters',
        atLeastTwoCharacters: 'The name should have at least 2 characters',
        checkPermissions: 'Please check if the permissions are up-to-date.',
        checkPermissionsAws:
          'Check if your UnxposePolicy has updated permissions according to our tutorial',
        checkPermissionsGcp:
          'Enable APIs and check permissions according to our tutorial',
        confirmationNotMatching: 'The password confirmation is not matching.',
        fieldsMustBeFilled: 'All the fields must be filled.',
        expiredCode: 'The inserted code is expired.',
        invalidCode: 'The inserted code is invalid.',
        invalidCredentials: 'Invalid username or password.',
        invalidEmailAddress: 'Invalid email address',
        invalidOrExpiredInvite:
          'This link may be expired or is not valid at all. User invitation links are valid for 7 days, if this invite is older than that you need to ask for another invite to your company administrator.',
        invalidOrExpiredLink: 'This link is invalid or it is already expired.',
        latestCheckFailed: 'An error occurred during the last scan',
        somethingWentWrong: 'Something went wrong.',
        tryAgain: 'Please, try again',
        unexpectedError: 'An unexpected error occurred, try again.',
        forbiddenError: "You don't have permissions to perform this action.",
        domainTaken: 'This domain has already been added by another company.',
        domainInvalid: 'This domain is invalid.',
        domainInvalidWithSuggestion:
          'Invalid domain. Did you mean {{domainName}}?',
        paasDomain:
          'Invalid domain. Only add domains owned by your organization.',
        domainExists: 'This domain already exists in your account.',
        domainDoesNotRespond:
          'The domain is not responding: Check if there is an active website on this domain and if the file is in the root directory.',
        dnsRecordDoesNotResolve:
          'No TXT record was found on the domain. Verify if the record was added and wait a few minutes before trying again.',
        domainNotVerified:
          'A TXT record was found, but it does not have the indicated value. Review the steps and ensure everything was done correctly.',
      },
      finding: {
        assignment: 'Assignment',
        assigned: 'Assigned',
        placeholderSearch: "I'm looking for...",
        findingAssignedTo: 'This finding was assigned to {{assignee}}',
        findingUnassigned: 'This finding was unassigned',
        firstSeen: 'First seen:',
        lastSeen: 'Last seen:',
        lgpdArticles: 'LGPD articles',
        movedFrom: ' moved from',
        severity: 'Severity',
        statusUpdatedFrom: 'Status updated from',
        assignedTo: 'assigned this finding to',
        unassign: 'unassigned this finding',
        unassigned: 'Unassigned',
        modal: {
          title: 'Justification for Accepted Risk',
          label: 'Tell us why you chose the risk accepted option (optional)',
          description:
            'Use this field to explain your reasons for accepting this risk. This may include actions taken to minimize potential impacts or other relevant considerations. Justifying your choice is important to create a history of decisions made.',
          concludeBtn: 'Ok',
          cancelBtn: 'Cancel',
        },
      },
      footer: {
        privacyPolicy: 'Privacy Policy',
        termsOfService: 'Terms of Service',
      },
      googleWorkspace: {
        status: {
          active: 'Active',
          failed: 'Failed',
          started: 'Started',
        },
        badgesTooltipMessages: {
          compliantPassword: 'Using compliant password',
          notCompliantPassword: 'Not using compliant password',
          informationMissing:
            'Unable to get this information from Google Workspace',
          lessSecureAppsDisabled: 'Access to less secure apps disabled',
          lessSecureAppsEnabled: 'Access to less secure apps enabled',
          strongPassword: 'Using strong password',
          weakPassword: 'Not using strong password',
          mfaEnabled: 'MFA enabled',
          mfaDisabled: 'MFA disabled',
        },
        customerId: 'Customer ID',
        emptyStateBody: 'Come back in a few minutes!',
        connectingToOrganization: 'We are connecting to your organization.',
        removeIntegrationSuccess: 'Integration removed successfully.',
        integrationStatus: 'Integration Status:',
        createdAt: 'Created at:',
        latestCheck: 'Latest check:',
        integrationCreatedBy: 'Created by:',

        accessDenied: 'You must grant access to your account.',
        activeIntegrations: 'Active integrations',
        allSet: 'And all set! The process should be finished within minutes.',
        anErrorOccurred: 'An error occurred, try to integrate again',
        clickHereToManageUpdates:
          'Click here to verify integrations that need update.',
        clickHereToUpdate: 'Update now',
        clickOnContinue: 'Click on "Continue"',
        clickOnTheButton: 'Click on the button below',
        emptyStateLink: 'Head back to the Overview',
        emptyStateTitle:
          'Please wait for the integration process to be finished.',
        googleButton: 'Monitor my Google Workspace',
        integrationNotFound:
          'The integration you are looking for does not exist.',
        integrationNeedsUpdate:
          'For this integration to work properly, please grant permission to the following APIs:',
        integrationFailed: 'Integration failed. Please try again.',
        integrationInProgress:
          'Integration in progress. Come back in a few minutes!',
        integrationStarted: 'Integration in progress...',
        integratingIsEasy: `Performing the integration is easy. First, make sure you're an admin on Google Workspace.`,
        integrations: 'Integrations',
        manageIntegrations: 'Manage integrations',
        newIntegration: 'New integration',
        markTheFields: `Check the "View usage reports for your G Suite domain" and "View domains related to your customers" fields`,
        noUsers: 'No users',
        permissionsNeeded:
          'Administrator privileges or API permissions needed for integration:',
        refreshTokenMissing:
          'Remove previous integration from Google Authorized Apps at https://myaccount.google.com/connections and try again.',
        thenYouJustHaveTo: 'Then, you just have to:',
        title:
          'Monitor your Google Workspace environment in just a few clicks!',
        update: 'Update Integration',
        updateRequired: 'Update required!',
        users: 'Users',
        workspaceEmpty: `We couldn't find any users in this Workspace`,
        errors: {
          customerIdAlreadyInUse:
            'Customer ID already in use. The Workspace organization you are trying to connect is already connected to Unxpose.',
          insufficientPermission:
            'Insufficient permissions. Please try the Google Workspace integration again and make sure to select the following checkboxes:',
          invalidGrant:
            'Invalid grant. Access to Unxpose has been revoked, please contact support@unxpose.com.',
          notAuthorized:
            'The user who made the integration was not an administrator. Please ask an administrator to grant the following permissions:',
          unauthorizedClient:
            'Unauthorized client. Please contact support@unxpose.com.',
          unknown:
            'Please try the Google Workspace integration again. Something went wrong with the folowing APIs:',
        },
        failedIntegration: {
          failedIntegration: 'One of your integrations has failed.',
          seeErrorDetails: 'Click here to view the error details.',
        },
        startedIntegration: {
          integrationInProgress: 'Integration in progress.',
          comeBackInMinutes: 'Come back in a few minutes!',
        },
      },
      help: {
        sectionTitle: 'Help',
        footer: {
          title: 'Do you still have questions?',
          contactUs: 'Contact us',
        },
        intro: {
          menu: {
            title: 'Take a tour',
            subtitle: 'Navigate between our main functionalities.',
          },
          cloud: 'Monitor your cloud resources for misconfigurations.',
          dns: 'Know how secure your domains are to DNS threats.',
          help: 'In our Help section, you can revisit this tutorial and find more useful information.',
          management: 'Triage all Unxpose findings to take appropriate action.',
          network:
            "Watch the exposed network services (and its potential vulnerabilities) that we're currently monitoring.",
          people:
            "Find out if your employees' credentials have been exposed on data breaches.",
          reports:
            "Generate an executive report summarizing the company's cybersecurity status. Assets, findings, grade: all in just one click",
          scoreOverTime:
            'This is your security score fluctuation during the past 15 days.',
          securityScore:
            'This is your security score. A higher grade means your company is more secure.',
          web: "Assess your web applications' cybersecurity health.",
        },
        peopleIntro: {
          accountsTable:
            'The emails listed in this table were found by Unxpose using our web scanners.',
          newIntegrationButton:
            'Follow this link to integrate your Google Workspace or Microsoft 365 account.',
          helpIcon:
            'Want to know more? Click the question mark icon to read the help article for the section.',
        },
        twoFactorAuthentication:
          'Two-factor authentication (2FA) is an extra layer of security used when logging into website or apps. With 2FA, you have to log in with your username and password and provide another form of authentication that only you know or have access to.',
        executiveReport: {
          menu: {
            title: 'Executive Report',
            subtitle: `Find out how Unxpose's executive report can help you.`,
          },
          paragraph1: `The Unxpose executive report is a document that summarizes your company's state of cybersecurity in an accessible format for internal and external audiences. It is ideal for handing out to vendors — as a means of attesting to the strength of the company's cybersecurity — and to employees, helping to promote a stronger culture of security within the organization.`,
          paragraph2: `The executive report provides an overview of the company's security, including the Company Score, the Score of each section, and the assets being monitored. It also contains charts to make the data easier to understand, as well as the number of unresolved findings — that is, security flaws and/or misconfigurations. Finally, it includes the quantity of potentially sensitive information leaked on Github and company credentials exposed in data breaches.`,
          paragraph3:
            'The Unxpose executive report is generated according to the language used by the user on the platform. If you want to generate a report in another language, simply go to Settings > Profile and change to the desired language.',
          subtitle: 'Generating reports in different languages',
        },
        cvssCwe: {
          menu: {
            title: 'CVSS and CWE',
            subtitle: 'Get a grasp on these cybersecurity key concepts.',
          },
          cvss: {
            firstParagraph: `The Common Vulnerability Scoring System (CVSS) provides a way to capture the principal characteristics of a vulnerability, and produce a numerical score reflecting its severity, as well as a textual representation of that score. The numerical score can then be translated into a qualitative representation (such as low, medium, high, and critical) to help organizations properly assess and prioritize their vulnerability management processes.`,
            benefitsList: {
              title: 'CVSS affords three important benefits:',
              firstItem: `- It provides standardized vulnerability scores. When an organization uses a common algorithm for scoring vulnerabilities across all IT platforms, it can leverage a single vulnerability management policy defining the maximum allowable time to validate and remediate a given vulnerability.`,
              secondItem: `- It provides an open framework. Users may be confused when a vulnerability is assigned an arbitrary score by a third party. With CVSS, the individual characteristics used to derive a score are transparent.`,
              thirdItem: `- CVSS helps prioritize risk. When the environmental score is computed, the vulnerability becomes contextual to each organization, and helps provide a better understanding of the risk posed by a vulnerability to the organization.`,
              source: `Source:`,
            },
            ratingTable: {
              rating: 'Rating',
              cvssScore: 'CVSS Score',
              none: 'None',
              low: 'Low',
              medium: 'Medium',
              high: 'High',
              critical: 'Critical',
            },
          },
          cwe: {
            firstParagraph: `Common Weakness Enumeration (CWE) is a community-developed list of common software and hardware weakness types that have security ramifications. “Weaknesses” are flaws, faults, bugs, or other errors in software or hardware implementation, code, design, or architecture that if left unaddressed could result in systems, networks, or hardware being vulnerable to attack. The CWE List and associated classification taxonomy serve as a language that can be used to identify and describe these weaknesses in terms of CWEs.`,
            secondParagraph: `Targeted at both the development and security practitioner communities, the main goal of CWE is to stop vulnerabilities at the source by educating software and hardware architects, designers, programmers, and acquirers on how to eliminate the most common mistakes before products are delivered. Ultimately, use of CWE helps prevent the kinds of security vulnerabilities that have plagued the software and hardware industries and put enterprises at risk.`,
            benefitsList: {
              title: 'CWE helps developers and security practitioners to:',
              firstItem: `- Describe and discuss software and hardware weaknesses in a common language.`,
              secondItem: `- Check for weaknesses in existing software and hardware products.`,
              thirdItem: `- Evaluate coverage of tools targeting these weaknesses.`,
              fourthItem: `- Leverage a common baseline standard for weakness identification, mitigation, and prevention efforts.`,
              fifthItem: `- Prevent software and hardware vulnerabilities prior to deployment.`,
              source: `Source:`,
            },
          },
        },
        webhooks: {
          menu: {
            title: 'Unxpose webhooks',
            subtitle: 'Find out how to integrate your platform with ours',
          },
          unxposeWebhooks: {
            firstParagraph:
              'Webhooks allow you to integrate Unxpose with external applications. You can subscribe your application to certain Unxpose events and once they are triggered we will send a HTTP POST request to the configured URL. Created webhooks are shared across the organization and can be managed by any member.',
            secondParagraph:
              'Each time a new finding is detected, a webhook is triggered in one of the formats provided below, with variations based on the type of finding:',
          },
          securityConsiderations: {
            title: 'Security considerations',
            encryption: {
              title: 'Encryption',
              firstParagraph:
                'All webhook requests are sent using HTTPS, therefore, your server needs to be able to establish a secure connection. Redirects are not allowed.',
              secondParagraph:
                'You can have another security layer on your Webhooks by enabling encryption. We will use the encryption key present in your Webhook settings to cipher the body using AES-256-CBC. If you opt-in to this feature the payload will look like this:',
            },
            validatingOrigin: {
              title: 'Validating origin',
              firstParagraph:
                'You can ensure your server is only receiving requests originated by Unxpose using a custom header with a value of your choice that must be verified by your application.',
            },
          },
          webhookExample: {
            description: 'Sample description',
            references: 'Sample reference',
            solution: 'How to fix this issue',
            text: `You have a new HIGH severity finding. Access ${config.CLIENT_URL}/web/findings/b5cc327c-b468-4efa-81e5-0bf7bb0dd914 to know more.`,
          },
        },
        jira: {
          menu: {
            title: 'Jira integration',
            subtitle: 'Set up a Jira integration using webhooks.',
          },
          intro: {
            title: 'Introduction',
            body: `
            Welcome to the Unxpose integration with Jira Cloud tutorial! This guide will walk you through the setup of Unxpose webhooks in conjunction with Jira Automation, allowing you to automatically create new Issues from new Findings in Unxpose. The process may take a few minutes, but we'll be here to assist you at every step of the way. Shall we begin?`,
          },
          jiraConfig: {
            title: 'Part 1 - Jira setup:',
            step1:
              'Access your Jira account and navigate to the project where you want to create Unxpose Issues from the top menu.',
            step2: 'In the left-hand menu, select <b>Project settings</b>.',
            step3: 'Choose <b>Automation</b> and then <b>Rules</b>.',
            step4: 'Click on <b>Create rule</b> in the top right corner.',
            step5:
              'Look for <b>Incoming webhook</b> in the <b>Create a trigger</b> section. Select this option.',
            step6:
              'Copy the displayed URL (note: this is sensitive information. Do not share it publicly or store it in a public place).',
            step7:
              'Choose the option <b>No issues from the webhook</b> and click <b>Save</b>.',
            step8:
              'In the <b>Add component</b> section, select <b>THEN: Add an action</b>.',

            step9: {
              text: 'Look for <b>Create issue</b> in the <b>Add an action</b> section. Choose this option.',
              subItem1:
                'Fill in the fields <b>Project</b> and <b>Issue type</b> (Bug or Task are good options, for example).',
              subItem2: 'In the <b>Summary</b> field, add the value:',
              subItem3:
                'In the <b>Description</b> field, enter the following content:',
              subItem4: 'Click on <b>Save</b>.',
            },

            step10:
              'Give a descriptive name to your Automation (e.g., Unxpose integration).',
            step11: `Finally, click on <b>Turn it on</b>. All set here, now let's move on to the second part!`,
          },
          unxposeConfig: {
            title: 'Part 2 - Unxpose setup:',
            step1:
              'In your Unxpose account, click on the company name in the top-left corner and select <b>Settings</b>.',
            step2:
              'Choose the <b>Webhooks</b> tab. Then, click on <b>Add Webhook +</b>.',
            step3:
              'In the <b>Name</b> field, give a descriptive name to the webhook (e.g., Jira integration).',
            step4:
              'In the <b>URL</b> field, paste the URL obtained in step 6 of the Jira configuration.',
            step5:
              'Click on <b>Test Webhook</b> to verify if a new Issue is created in your project.',
            step6: 'If the test is successful, click on <b>Create webhook</b>.',
          },

          conclusion: {
            title: 'Conclusion',
            body: 'The integration between Unxpose and Jira is now configured! Whenever an event is triggered in the Unxpose Webhook, a new Issue will be automatically created in the Jira project!',
          },
        },
        people: {
          menu: {
            title: 'People',
            subtitle: `Assess the security risk of individuals in your organization.`,
          },
          firstParagraph: `With the drastic growth of digital in everything we do, separating personal and professional lives on a day-to-day basis becomes a challenge.`,
          secondParagraph: `As a result, it is not uncommon that people will use their corporate emails, sometimes reusing the same credentials, elsewhere in social media, online shops or in entertainment sites, spreading potentially sensitive corporate information across the Internet. Nevertheless, people will share personal information with sites with little scrutiny. This results in having IDs, telephone numbers and physical addresses, among others, in databases all over the world.`,
          thirdParagraph: `As ransomware attacks and data breaches happen every day, chances are your employees will have some data leaked, potentially something that impacts your business directly or indirectly.`,
          fourthParagraph: `Our People section evaluates data breaches 24/7 searching for information associated with your company employees and their corporate emails.`,
          fifthParagraph: `Any information found is presented, listing the type of data that was breached (e.g. personal IDs, financial information or email credentials), the associated breach event (e.g. Facebook data breach) and the year that the incident occured. That gives your company visibility about the overall people exposure, allowing remediation tasks such as enforcing a user to change their password in the next login, or alerting high executives of possible frauds and extortions with their personal information.`,
          scoreAlgorithmList: {
            intro: `Our People score algorithm considers the following factors:`,
            firstItem: `- Category of data breached (e.g. the relevance of information exposed);`,
            secondItem: `- Age of the breach event (e.g. the older the less likely to have impact in your organization today);`,
            thirdItem: `- Extent of the breaches, where Unxpose considers the number of incidents involving your corporate users vs the number of employees in the organization.`,
          },
        },
        network: {
          menu: {
            title: 'Network',
            subtitle: 'How we can help you keeping your network secure.',
          },
          assetDiscovery: {
            title: 'Asset discovery',
            firstParagraph: `In the past corporate networks were centralized and fully controlled within the company's boundaries, often being physically inside the corporate's buildings.`,
            secondParagraph: `Modern infrastructure has changed drastically, with servers, applications and cloud components widespread in different locations, being handled by 3rd party suppliers.`,
            thirdParagraph: `A second element of modern times is the agile dynamics of infrastructure: assets are created, modified, moved around or removed constantly. Often domains are acquired and digital assets are created without the visibility and consent of the IT teams – marketing department performing campaigns or developers launching test environments, for example, could expose sensitive assets and information, putting the company at risk to cyberattacks.`,
            fourthParagraph: `Understanding that you cannot protect an asset that you don't know is part of your company, Unxpose created a proprietary mechanism to automatically discover all assets pertaining to the business that are exposed to the Internet. Unxpose's Asset Discovery is constantly searching for domains, servers, applications and endpoints, without any human intervention.`,
          },
          networkVulnerabilities: {
            title: 'Network vulnerabilities',
            firstParagraph:
              'Network assets may be exposed due to inappropriate access controls or for inadvertent changes in the digital infrastructure. There are also another class of security issues, known as vulnerabilities. They are related to software errors, in design or implementation, that could open a window for cyberattacks.',
            secondParagraph: `After the company's assets are determined, Unxpose starts monitoring those assets for exposures and security vulnerabilities, 24/7.`,
          },
          visualizingExposures: {
            title: 'Visualizing exposures and vulnerabilities',
            firstParagraph: `Unxpose organises and displays the issues found such that the user can quickly see what's more important and quickly act to remediate them.`,
          },
          smartPrioritization: {
            title: 'Smart prioritization',
            firstParagraph: `Unxpose's proprietary score algorithm is not just about an A-F rating. The algorithm evaluates various points, such as impact, probability of attack and relevance of the affected asset to automatically triage and prioritize the security issues. Then the team knows the relevance of problems and where to act first, without the need of a specialist. This saves operations time, letting IT teams focus on the business.`,
          },
          fixingExposures: {
            title: 'Fixing exposures and vulnerabilities',
            firstParagraph:
              'Unxpose has didactic tutorials that put each issue in context, illustrating possible attack scenarios, likelihood of an attack, and the potential business impact of each issue. The communication is simple such that anyone with basic technology expertise can understand the problem and risks involved.',
            secondParagraph: `Unxpose understands that communicating problems does not resolve problems.`,
            thirdParagraph: `The platform then helps the user to identify who is responsible for the affected asset (e.g. whether it is part of the company's internal infrastructure or if managed by a 3rd party), such that the security issue is handed to the right person with no operational friction.`,
            fourthParagraph: `Unxpose then presents a step-by-step guidance on how to correct the security issues in a simple and objective way.`,
            fifthParagraph: `Once issues are resolved, Unxpose automatically validates if the corrections were successful, reflecting the result in the platform, then recalculating the security scores. For any issue to be partially fixed or not fixed, Unxpose will reopen each of those, reentering them in the list of problems.`,
            sixthParagraph: `This way no human resource is required to validate the fix, once again letting the company's team to focus on the business operations.`,
          },
        },
        questionnaire: {
          menu: {
            title: 'Questionnaires',
            subtitle: `Learn what security questionnaires are and how to automate responses`,
          },
          firstParagraph: {
            title: 'Questionnaires',
            firstItem:
              'Centralize the management of security questionnaires received by your company in an intuitive interface. Track the progress of responses and submission of questionnaires, and download them in CSV format. Take advantage of the auto-fill functionality of these questionnaires, using artificial intelligence, and speed up the contracting and accuracy of the process.',
          },
          secondParagraph: {
            title: 'Questionnaire submission',
            firstItem:
              'To ensure accuracy in response generation using AI, only questionnaires that have been submitted will be considered. You can mark a questionnaire as submitted at the time of upload or by editing it later.',
            secondItem:
              'The date of submission of the questionnaire corresponds to the day the document was sent to the evaluating company.',
          },
          thirdParagraph: {
            title: 'Knowledge Base',
            firstItem:
              "The Knowledge Base is a special questionnaire created by Unxpose. It contains primary questions about your organization's security and has priority in the automatic response generation using AI.",
            secondItem:
              "A filled and updated Knowledge Base will enable more accurate and aligned responses with the company's current situation.",
          },
          fourthParagraph: {
            title: 'Generating responses using artificial intelligence',
            firstItem:
              'The AI response generation feature for the automatic filling of questionnaires is particularly useful for optimizing your time and resources, providing quick and accurate responses.',
            secondItem:
              'To use the automatic response generation functionality, you must have questionnaires marked as submitted or questions answered in your Knowledge Base, which will be used as input to generate new responses.',
          },
        },
        unresolvedFindingsBySeverity:
          'Unresolved findings include <b>open</b>, <b>reopened</b>, <b>fixed</b> and <b>stale</b> statuses.',
        unxposeScoring: {
          menu: {
            title: 'Unxpose Scoring',
            subtitle: 'Understand how our scoring system works.',
          },
          unxposeScoring: {
            title: 'Unxpose scoring',
            definition: `The scoring algorithm is the core of Unxpose's intelligence in triaging and communicating security issues and exposures. The score has four levels (top-down): Company Security Score, Category Score, Asset Score, and individual Finding Score (vulnerability or exposure). Each level has a set of parameters to balance and communicate the right risk of exposure.`,
            securityScoreGrades: 'Security score grades',
            grades: {
              A: 'Outstanding',
              B: 'Good',
              C: 'Average',
              D: 'Needs improvement',
              E: 'Bad',
              F: 'Critical',
            },
          },
          companysExposureScore: {
            title: `Company's Security Score`,
            definition: `The Company Security Score is an average of all asset categories of the company. You can see the Company Security Score by accessing the Overview Menu.`,
          },
          categoryScore: {
            title: `Category Score`,
            definition: `The category score is the total score of all findings in a category divided by the number of assets in that category. This distribution, called Findings Extent, balances the score of the category based on the size of the company's infrastructure. The notion of assets varies depending on the category, for example, in DNS assets are domains, in Web assets are web applications and so on. For People the number of employees are considered.`,
          },
          assetScore: {
            title: 'Asset Score',
            definition: `The asset score expresses the exposure of a given asset (e.g. the number of issues present in that asset and how critical those issues are). The total findings from a given digital asset represents the Asset Score.`,
          },
          findingScore: {
            title: 'Finding Score',
            definition:
              'The Finding Score rates an individual finding (a vulnerability or an exposure). The vulnerability score applies to the infrastructure assets (domains, hosts, webapps, cloud assets). When a finding is related to an infrastructure asset, its score is calculated from the average of the following terms:',
            scoreCalculation:
              'The Finding Score is calculated from the average of the following terms:',
            cvss: {
              title: `CVSS:`,
              definition: `The Common Vulnerability Scoring System is a free and open industry standard for assessing the severity of computer system security vulnerabilities.`,
            },
            businessCriticality: {
              title: `Business Criticality:`,
              definition: `Sets context to how critical an asset or category is to your business.`,
            },
          },
          peopleScore: {
            intro: `When a finding is relatead to an employee, the following factors are taken into account:`,
            breachRelevance: {
              title: `Breach Relevance:`,
              definition: `The year the breach occurred (the older, the lower the risk) and the type of breached information (email, password, date of birth, etc.).`,
            },
            employeesAccess: {
              title: `Employee's Access:`,
              definition: `The levels of access that the affected employee has.`,
            },
            businessCriticality: {
              title: `Business Criticality:`,
              definition: `a constant representing the importance of this category to the company.`,
            },
          },
          progressBar: {
            title: `Security Score Progress`,
            definition: `The Company Security Score progress bar ranges from 0 to 100% and displays how close the company is to reaching the next grade.`,
          },
          informationScore: {
            title: `Information Score`,
            definition: `Currently this category does not have a score due to the potential number of false positives which could overly influence the overall result.`,
          },
        },
      },
      internetExposure: {
        domain: 'Domain',
        hostsByCountry: {
          title: 'Hosts by country',
          info: 'TO DO: SHOULD BE EDITED',
          ipCount: 'IP Count',
        },
        domainsOverview: 'Domains overview',
        subdomains: 'Subdomains',
        noFindings:
          'There are no findings associated with this domain right now',
        registrarNotDetected: 'Not detected',
        tables: {
          domainsByRegistrar: 'Domains by registrar',
          hostsByAsOrg: 'Hosts by AS organizations',
          registrar: 'Registrar',
          webAppsByProtocol: 'Web apps by protocol',
        },
      },
      nextSteps: {
        noResults: "You're up to date",
      },
      labels: {
        authenticationCode: 'Authentication code',
        closedFindings: 'Closed findings',
        companyName: 'Company name',
        condition: 'Condition',
        country: 'Country',
        currentPassword: 'Current password',
        customHeaders: 'Custom headers',
        days: 'days',
        disabled: 'Disabled',
        email: 'Email',
        enableEncryption: 'Enable encryption',
        enableWebhook: 'Enable webhook',
        enabled: 'Enabled',
        encryptionKey: 'Encryption key',
        inviteExpires: 'Expires {{datetime}}',
        inviteExpired: 'Invite expired',
        firstName: 'First name',
        identificationNumber: 'Identification number',
        jobTitle: 'Job title',
        language: 'Language',
        lastName: 'Last name',
        lastStatus: 'Last status',
        name: 'Name',
        newFindings: 'New findings',
        newPassword: 'New password',
        numberOfEmployees: 'Number of employees',
        password: 'Password',
        passwordConfirmation: 'Password confirmation',
        phoneNumber: 'Mobile phone number',
        role: 'Role',
        status: 'Status',
        totalFindings: 'Total findings',
        usersNotShown: 'Some users might not be shown here',
        weeklyReport: 'Weekly report',
        you: 'You',
        dontHaveAnAccount: 'No account?',
        createAccount: 'Create account',
      },
      links: {
        termsAndConditions: 'Terms and Conditions',
      },
      management: {
        noFindings: 'There are no findings related to your company right now',
        sales: {
          findingsUnavailable: 'Viewing findings is not available on your plan',
          title: 'Gain insights into findings',
          advantages: {
            first:
              'Export reports in csv applying various filters, such as status, assignment, or assets where they were found',
            second: 'Have unified visibility over the security breach catalog',
            third: `Understand in which stages of the life cycle your company's findings are`,
          },
        },
        bulkChange: {
          selectionItemsBtn: 'Select items',
          hideSelectionBtn: 'Hide selection',
          bulkChangeBtn: 'Bulk change',
          nextStepBtn: 'Next step',
          backBtn: 'Back',
          confirmBtn: 'Confirm',
          allFindingsSelected:
            '<strong>All {{qty}} findings</strong> have been selected.',
          allFindingsPageSelected:
            '<strong>{{qty}} findings</strong> on this page have been selected.',
          selectAllFindingsPage: 'Select all {{qty}} findings',
          uncheckAll: 'Uncheck all',
          nothingFound: 'Something seems wrong, as no results were found.',
          stepOne: {
            stepTitle: 'Step 1',
            stepDescription: 'Choose action',
            title: 'You have selected {{qty}} finding(s)',
            chooseActionLabel: 'Choose the desired action for the selection',
            changeStatus: 'Change status',
            newStatusLabel: 'Choose the new status',
          },
          stepTwo: {
            stepTitle: 'Step 2',
            stepDescription: 'Confirmation',
            title: 'The change will be made to {{qty}} finding(s)',
            table: {
              title: 'Title',
              transition: 'Transition (status)',
            },
            modal: {
              title: 'Confirmation',
              description:
                'The operation to be performed on {{qty}} finding(s) <strong>cannot be undone</strong>. Do you want to continue?',
              continueBtn: 'Continue',
              cancelBtn: 'Cancel',
            },
          },
          stepThree: {
            stepTitle: 'Step 3',
            stepDescription: 'Result',
            title: 'The change is being made to {{qty}} finding(s)...',
            subtitle:
              'You will see the result here when the process is complete',
            success: 'Success',
            fail: 'Fail',
            operationCompleted: 'Operation completed!',
            seeDetails: 'See details below',
            noFindingsChanged: 'No findings were changed.',
            changeSuccess: 'Successfully changed {{qty}} finding(s).',
            changeFailure: ' {{qty}} failure(s) occurred.',
            goToFindings: 'Go to Findings',
            errorMsg: {
              invalidFlow: 'Cannot change from {{oldStatus}} to {{newStatus}}',
              people: 'People findings are not supported for bulk change',
            },
            table: {
              title: 'Title',
              previousStatus: 'Previous status',
              currentStatus: 'Current status',
              changeStatus: 'Change status',
              observations: 'Observations',
            },
          },
        },
      },
      microsoft365: {
        adminAccessNeeded:
          'In order to grant access to our Azure application in your directory, you must have administrator privileges and be a native user of that directory.',
        directoryIdAlreadyInUse:
          "Directory ID already in use. Please ensure the directory is not already connected and use a native user in the directory you're trying to connect.",
        tenantId: 'Tenant ID',
        emptyStateBody: 'Come back in a few minutes!',
        connectingToOrganization: 'We are connecting to your organization.',
        removeIntegrationSuccess: 'Integration removed successfully.',
        integrationStatus: 'Integration Status:',
        createdAt: 'Created at:',
        latestCheck: 'Latest check:',
        integrationCreatedBy: 'Created by:',
      },
      misc: {
        active: 'Active',
        created: 'Created',
        all: 'All',
        asset: 'Asset',
        assets: 'Assets',
        at: 'At',
        changes: 'Changes',
        city: 'City',
        clickHere: 'Click here',
        domain: 'Domain',
        expires: 'Expires',
        expired: 'Expired',
        failed: 'Failed',
        files: 'Files',
        finding: 'Finding',
        findings: 'Findings',
        loadMore: 'Load more',
        loading: 'Loading...',
        noOptions: 'No options.',
        no: 'No',
        of: 'Of',
        justification: 'Justification',
        edit: 'Edit',
        remove: 'Remove',
        close: 'Close',
        removed: 'removed',
        resources: 'Resources',
        since: 'Since',
        started: 'Started',
        thanks: 'Thanks',
        to: 'to',
        users: 'Users',
        wasAdded: 'Was added.',
        wasRemoved: 'Was removed.',
        yes: 'Yes',
        datePickerPlaceholder: 'MM/DD/YYYY',
        datePickerMultiplePlaceholder: 'MM/DD/YYYY - MM/DD/YYYY',
        paginationItems: '{{qty}} items',
        select: 'Select...',
        copiedToClipboard: 'Copied!',
        addNew: 'Add',
        calculating: 'Calculating...',
      },
      network: {
        filterByAsorg: {
          placeholder: 'Filter by AS org',
        },
        filterbyCountry: {
          placeholder: 'Filter by country',
        },
        host: 'Host',
        fingerprints: 'Fingerprints',
        hostname: 'Hostname',
        hostsFound: 'Hosts found',
        noFindingsInHost:
          'There are no findings related to this host right now',
        noFindingsInService:
          'There are no findings related to this service right now',
        port: 'Port',
        protocol: 'Protocol',
        region: 'Region',
        relatedRecords: 'Related records',
        responsible: 'Responsible',
        services: 'Services',
        servicesDetected: 'Services detected',
        servicesBreakdown: 'Services breakdown',
        nHosts: '# of hosts',
        nServices: '# of services',
        csvNhosts: 'Number of hosts',
        csvNservices: 'Number of services',
        buttons: {
          viewMore: 'view more',
        },
        errors: {
          serviceNotResponding:
            'This service did not respond on the last check',
        },
      },
      nps: {
        couldntTestTheProduct: "I didn't have enough time to test the product",
        dontHaveASecurityTeam: "I don't have a cybersecurity team",
        other: 'Other',
        productDontMeetMyNeeds: "The product doesn't meet my needs",
        questionOne: 'Why are you leaving?',
        questionThree: 'Please explain why you gave us this score:',
        questionTwo: 'In a scale of 1 to 10, how would you rate our product?',
        submit: 'Submit my evaluation',
        subtitle:
          "We're sad that our partnership wasn't successful this time. And we would like to hear from you.",
        surveySent:
          "We've received your opinion and it will help us to democratize cybersecurity even further.",
        title: 'Your opinion is important to us',
        valueIsTooHigh: 'The plans are too expensive',
        whatWasMissing: 'What was missing?',
      },
      options: {
        allFindings: 'All findings',
        highOrCritical: 'High or critical findings',
        mediumOrHigher: 'Medium, high or critical findings',
      },
      people: {
        account: 'Account',
        accountNotBreached: "We didn't find this account in any data breach.",
        accounts: 'Accounts',
        affectedAccounts: 'Affected accounts',
        breachCount: '# of breaches',
        breachDetails: 'Breach details',
        breachFinding: {
          description: `Unxpose has discovered that this user's information was compromised as a result of a breach in a third-party website. The breach occurred due to the user's registration on the affected website using the aforementioned email address. Our monitoring system has flagged this email because it is associated with one of your connected identity providers, registered within this application, or linked to one of your monitored domains`,
          remediation: {
            intro: `When a user's information has been breached, it is important to take immediate action to mitigate any potential harm. Here are some common remediation steps for a breached user:`,
            notify:
              'Notify the user: Inform the user about the breach as soon as possible. Provide clear and concise information about what data was compromised and what potential risks they may face.',
            passwords:
              'Reset passwords: Instruct the user to change their passwords for the affected account(s) immediately. Encourage them to create strong, unique passwords and enable two-factor authentication for enhanced security.',
            monitor:
              'Monitor accounts: Advise the user to regularly monitor their financial, email, and other sensitive accounts for any suspicious activity. They should report any unauthorized transactions or unusual behavior immediately.',
            educate:
              'Educate users: Raise awareness among users about best practices for online security, such as avoiding suspicious emails or links, using strong passwords, and regularly updating software and applications.',
            warning: `Remember, each breach may have unique circumstances, so it's essential to adapt the remediation steps based on the specific situation.`,
            conclusion:
              'After taking the necessary steps, you can mark this finding as resolved to maintain a record of what has been resolved.',
          },
        },
        changeBreachFindingStatus: {
          closed: {
            mitigated: 'Security measures have been taken',
            removed: 'Account no longer exists',
            other: 'Other',
          },
          falsePositive:
            'This option indicates that this email was never associated with your organization, which means that this is a platform error. Our team will perform a manual check and can remove it if necessary.',
        },
        dashboard: {
          accountsUnderMonitoring: 'Accounts',
          accountsWithoutMfa: 'MFA-disabled accounts',
          integrations: 'Integrations',
        },
        breachesOverTime: 'Breaches found over time',
        breaches: 'Breaches',
        breachesOpen: 'Open Breaches',
        breachesChecked: 'Breaches checked',
        credentials: 'Credentials',
        dataLeaked: 'Data leaked',
        emailFoundYear: 'breach from',
        failedIntegration: 'The integration has failed.',
        general: 'General',
        googleLoginEnabled: 'Enabled',
        googleLoginDisabled: 'Disabled',
        noBreachedAccounts:
          'No breached accounts related to your company were found',
        noFindings:
          'There are no findings associated with this account right now',
        sources: 'Sources',
        strongPassword: 'Strong password',
        userAccounts: 'User accounts',
        usersInBreach: 'Users found in breach',
        emailSources: {
          manual: 'Manual insertion',
          registration: 'Unxpose registration',
        },
        addIntegration: {
          title1: 'Promote a secure work environment by reviewing',
          title2: 'the adherence of your employees to security best practices.',
          subtitle:
            'Select your provider and get started in just a few clicks.',
        },
        labels: {
          admin: 'Admin',
          alias: 'Alias',
          creationTime: 'Created at',
          email: 'Email',
          emailSources: 'Sources',
          filesAnyoneWithLink: 'Files available for anyone with link',
          filesSharedExternally: 'Files shared externally',
          lastLogin: 'Last login',
          lessSecureApps: 'Less Secure Apps',
          mfa: 'MFA',
          name: 'Name',
          passwordCompliant: 'Password Compliant',
          passwordStrength: 'Password Strength',
          publicFiles: 'Public files',
          role: 'Role',
          riskySharing: 'Files shared externally',
          suspended: 'Suspended user: ',
          suspensionReason: 'Suspended by: ',
        },
        tooltips: {
          betaTesting1: 'New feature in beta testing phase.',
          betaTesting2: 'Want to test it out? Email support@unxpose.com.',
          isAdmin: 'Administrator',
          isNotAdmin: 'Regular user',
          mfaEnabled: 'MFA enabled',
          mfaDisabled: 'MFA disabled',
          noDataAvailable: 'Not available',
        },
        filters: {
          activeAccountsOnly: 'Active accounts only',
          accountSource: 'Account source',
          adminOnly: 'Administrators only',
          clearFilters: 'Clear filters',
          filters: 'Filters',
          filtersApplied: 'Filters applied',
          googleWorkspaceFilters: 'Google Workspace filters',
          microsoft365Filters: 'Microsoft 365 filters',
          mfaDisabledOnly: 'MFA disabled only',
          suspendedAccountsOnly: 'Suspended accounts only',
        },
        subtitles: {
          anyoneWithLink: 'Anyone with the link can access',
          googleDrive1: 'Events related to company file privacy',
          googleDrive2: 'setting changes in the last 180 days.',
          publicOnTheWeb: 'Public on the web',
          sharedExternally:
            'Shared with explicit users/groups external to the domain',
        },
        severityBreakdown: {
          title: 'Severity breakdown',
          findingSeverity: 'Finding severity',
          account:
            'Contains account related information (emails, usernames, IPs, devices, etc)',
          credentials:
            'Contains credentials (passwords, access keys, PINs, etc)',
          breachAge: 'Breach happened {{age}} years ago',
          privateMessages:
            'Contains private messages (email, chat, support tickets, etc)',
          personal: 'Contain other personal information',
        },
        advantages: {
          headerTitle: "You haven't integrated accounts yet",
          actionBtn: 'Integrate now',
          secondaryBtn: 'Watch demo',
          title: 'Monitor the weakest link: your employees',
          items: {
            first:
              'Connect Google Workspace or Microsoft 365 to understand which employees are adhering to security best practices',
            second:
              'Monitor parameters such as MFA activation, password strength, and file sharing on Google Drive',
            third:
              'Verify whether the corporate login credentials of your employees have been compromised in data breaches and understand how to solve it',
          },
        },
      },
      placeholders: {
        currentPassword: 'Your current password',
        email: 'Email address',
        firstName: 'Jane',
        jobTitle: 'CEO, CSO, SysAdmin, etc',
        key: 'Key',
        lastName: 'Doe',
        minCharacters: 'At least 10 characters',
        newPasswordConfirmation: 'Repeat your new password',
        password: 'Password',
        passwordConfirmation: 'Repeat your password',
        select: 'Select',
        value: 'Value',
      },
      suppliers: {
        addNewBtn: 'Add new',
        addSupplierBtn: {
          title: 'Manage your suppliers',
          details:
            '<strong>Add suppliers</strong> is not available on your plan. Start a TRIAL and access all features for free for 7 days.',
          supplierLimit_zero:
            'You <strong>do not have any limits for suppliers</strong> in your plan. Contact us to upgrade your resources!',
          supplierLimit_one:
            'You have reached the <strong>limit of {{count}} supplier</strong> for your plan. Contact us to upgrade your resources!',
          supplierLimit_other:
            'You have reached the <strong>limit of {{count}} suppliers</strong> for your plan. Contact us to upgrade your resources!',
          listItems: {
            first:
              'Create and store questionnaire templates that you usually send to your suppliers',
            second:
              'Control the invitation of suppliers and the delivery of questionnaires',
            third: `View your supplier's responses to the questionnaires you sent`,
          },
        },
        registeredSuppliers: {
          title: 'Registered suppliers',
          table: {
            supplier: 'Supplier',
            questionnaires: 'Questionnaires',
            created: 'Created',
          },
          addNew: {
            title: 'Add new',
            companyName: 'Company name',
            companyNameHelper: 'Supplier company you want to evaluate',
            invitationEmail: {
              label: 'Invitation email',
              helper:
                'The supplier will receive an invitation via email provided',
              placeholder: 'abc@unxpose.com',
            },
            addBtn: 'Add',
          },
          detail: {
            sendQuestionnaireBtn: 'Send questionnaire',
            submittedQuestionnaires: 'Submitted questionnaires',
            invitationStatus: 'Invitation status',
            resendInvitationBtn: 'Resend',
            sentQuestionnaires: {
              table: {
                name: 'Name',
                questions: 'Questions',
                sent: 'Sent',
              },
              remove: {
                success: 'Questionnaire removed successfully.',
                modal: {
                  title: 'Remove questionnaire?',
                  body: 'The questionnaire <strong>"{{questionnaireName}}"</strong> will be removed from the sent list.',
                },
              },
            },
          },
          editModal: {
            title: 'Edit supplier',
            companyName: 'Company name',
          },
          removeModal: {
            title: 'Remove supplier?',
            body: 'The supplier <strong>{{companyName}}</strong> will be removed from your company.',
          },
          noSuppliers: 'To get started, register a supplier',
          noSentQuestionnaires: 'To get started, send a questionnaire',
        },
        sentQuestionnaireDetail: {
          answer: 'Answer',
          comment: 'Comment',
        },
        invites: {
          acceptSupplierOfCompany:
            'Do you agree to undergo the Supplier Evaluation of the company <strong>{{companyName}}</strong>?',
          title: 'Pending invites',
          acceptBtn: 'Accept',
          declineBtn: 'Decline',
          description: 'Invitation sent by <strong>{{name}}</strong> ',
          successAccepted: 'Invitation accepted successfully!',
          successDeclined: 'Invitation declined successfully!',
        },
        messages: {
          supplierRegistered: 'Supplier successfully registered!',
          invalidEmailError:
            'The informed email is not valid. Please provide a corporate email address.',
          multipleDomainsError:
            'All contact emails must be from the same domain',
          emailsInUseError: 'One or more contacts e-mails are already in use',
          domainInUseError:
            'Domain {{domain}} is already in use in another supplier',
          nameInUseError:
            "Supplier name {{name}} is already in use by one of the company's suppliers",
          companyOwnsDomainError: `Can't invite contacts from domain owned by company`,
          supplierEdited: 'Supplier successfully edited!',
          supplierRemoved: 'Supplier successfully removed!',
          resentInvitation: 'Invitation resent successfully!',
        },
        inviteHandler: {
          loading: {
            title: 'Loading...',
            description: 'This may take a few seconds.',
          },
          invalidSession: {
            title: 'The invitation was not found',
            description: 'There is something wrong with the invitation link.',
          },
          expiredSession: {
            title: 'Your invitation has expired',
            description: 'You need to ask the sender to resend the invitation.',
          },
          unknownError: {
            title: 'We encountered a problem',
            description: 'Contact the email below if necessary.',
          },
          companyExists: {
            title: 'Your company is already on Unxpose',
            description: 'Contact the account administrator for access.',
          },
        },
      },
      questionnaires: {
        base: {
          title: 'Knowledge Base',
          description:
            'The Knowledge Base is a special document automatically generated by Unxpose. It is used as  the security knowledge base of your organization, being consulted and prioritized when responding to new questionnaires using AI.',
          actionBtn: 'Access',
          progress: 'Progress',
        },
        title: 'Questionnaires',
        answer: 'Answer',
        considerations: 'Comments',
        evidences: {
          evidencesQty_zero: 'Evidences',
          evidencesQty_one: 'Evidences ({{count}} document)',
          evidencesQty_other: 'Evidences ({{count}} documents)',
          addDocumentBtn: 'Add document',
          addDocumentTitleModal: 'Add new document',
          uploadSuccess:
            'Document uploaded! You can now include it in the evidences.',
        },
        discard: 'Discard',
        save: 'Save',
        generateAnswer: 'Generate answer',
        creationInfo: 'Created {{createdAt}} by <strong>{{createdBy}}</strong>',
        updateInfo: 'Modified {{updatedAt}} by <strong>{{updatedBy}}</strong>',
        sentInfo: 'Sent by <strong>{{companyName}}</strong> {{receivedAt}}',
        acceptanceInfo: 'Accepted {{createdAt}}',
        progress: 'Progress',
        generationSuccess: 'Response generated successfully!',
        generationNoAnswer:
          "We didn't find an answer. By answering more questions, the AI ​​will have data to help you solve the questions.",
        cannotChange: "A submitted questionnaire doesn't allow changes.",
        cannotEvaluateApproved:
          'An approved questionnaire does not allow evaluations.',
        cannotEvaluateChangesRequested:
          'Waiting for adjustments from the supplier. You will be able to make new evaluations after the questionnaire is resubmitted.',
        unableToGenerateModal: {
          title: 'You cannot generate responses yet',
          body: 'At the moment, you have not submitted any questionnaires. The generated responses directly depend on these questionnaires as they are part of your knowledge base. The more questionnaires submitted, the better the responses will be.',
          primaryBtn: 'Ok',
          learnMore: 'Learn more',
        },
        answers: {
          yes: 'Implemented',
          no: 'Not implemented',
          inProgress: 'In progress',
          notApply: 'Not apply',
        },
        buttons: {
          addConsiderations: 'Add considerations',
          hideConsiderations: 'Hide considerations',
          saveConsiderations: 'Save considerations',
          showConsiderations: 'Show considerations',
          skipQuestion: 'Skip question',
        },
        csv: {
          answer: 'Answer',
          considerations: 'Comments',
          noConsiderations: '-',
          notAnswered: '-',
          question: 'Question',
        },
        placeholders: {
          provideDetails: 'Provide more details (optional)',
        },
        tables: {
          name: 'Name',
          questionnairesAvailable: 'Available questionnaires',
          latestResponse: 'Last answered',
          questionsNumber: 'Questions',
          progress: 'Progress',
          createdAt: 'Created',
          modifiedAt: 'Modified',
          modifiedBy: 'by {{username}}',
          sentBy: 'Sent by {{companyName}}',
          status: 'Status',
        },
        emptyState: {
          title: 'There are no questionnaire to display',
          body: 'You must upload first',
        },
        new: {
          title: 'Add new',
          questionnaireName: 'Questionnaire name',
          questionnaireNameHelper:
            'How your questionnaire will appear in the listing',
          questionnaireDescription: 'Description',
          questionnaireDescriptionHelper: 'Add context or notes',
          learnAboutFile: {
            title: 'Questions file',
            sampleFile: {
              doubts: 'Have doubts?',
              download: 'Download a <a>sample file</a>',
              online:
                'View a sample <withAnswer>with answers</withAnswer> and another <withoutAnswer>without answers</withoutAnswer>',
              onlineTemplate: 'View a <a>sample file</a>',
            },
            steps: {
              one: 'It should have up to <strong>3 columns</strong>: one for <strong>question</strong> (required), one for <strong>answer</strong> (optional), and the last for <strong>comment</strong> (optional).',
              two: 'The format should be CSV where the values are <strong>comma-separated</strong>. CSV is a file format for storing data in a table. Each line represents a record and each comma separates fields.',
              three:
                'The first line of the file must contain the header with values in the first, second, and third column. This line will be ignored during loading.',
            },
          },
          maxFileSize:
            'Only <strong>.csv</strong> files are supported <strong>(up to 1 MB)</strong>',
          onSubmitBtn: 'Submit',
          messages: {
            success: 'Your file has been saved successfully!',
            dragAndDropError:
              'Only CSV files are supported <br> and you must upload them one at a time.',
            error: {
              invalidCsv:
                'The provided CSV file is invalid. Please check if it has all columns and for any incorrectly formatted lines.',
              invalidQuestionnaireName:
                'Invalid questionnaire name. Make sure questionnaire name is provided and is not too long.',
              invalidQuestionnaireDescription:
                'Invalid questionnaire description. Make sure questionnaire description is not longer than 500 characters.',
              missingQuestionnaireFile: 'Missing questionnaire file.',
            },
          },
          modal: {
            firstContent: {
              title: 'Questionnaire saved',
              body: 'The questionnaire you are sending has one or more responses. Has this document already been submitted?',
              primaryBtn: 'Yes, provide date',
              secondaryBtn: 'Not yet',
            },
            secondContent: {
              title: 'When was the submission made?',
              body: "Please enter the submission date below. It's important to record this data to ensure the accuracy of the information.",
              primaryBtn: 'Finish',
              secondaryBtn: 'Back',
            },
          },
        },
        sendQuestionnaire: {
          title: 'Send questionnaire',
          questionnaireName: 'Questionnaire name',
          questionnaireNameHelper:
            'How the questionnaire will be displayed in the list',
          questionnaireDescription: 'Description',
          questionnaireDescriptionHelper: 'Add context or remarks',
          template: 'Template',
          templateHelper: 'Choose a template to be used in the questionnaire',
          sendBtn: 'Send',
          addNew: 'Add New',
          success: 'Questionnaire sent successfully!',
          alreadySentError: 'This questionnaire has already been sent.',
        },
        invites: {
          title: 'Open questionnaire invitations',
          acceptRequestOfCompany:
            'Do you accept responding to <strong>{{inviteName}}</strong> from <strong>{{inviteCompany}}</strong>?',
          acceptBtn: 'Accept',
          declineBtn: 'Decline',
          description: 'Invitation sent by <strong>{{name}}</strong> ',
          successAccepted: 'Invitation accepted successfully!',
        },
        remove: {
          success: 'Questionnaire successfully removed.',
          modal: {
            title: 'Remove questionnaire?',
            body: 'Questionnaire <strong>"{{questionnaireName}}"</strong> will be removed from Unxpose',
          },
        },
        submit: {
          submitBtn: 'Submit',
          name: 'Name',
          description: 'Description',
          modal: {
            title: 'Submit questionnaire',
            body: 'The answers to the questionnaire <strong>"{{questionnaireName}}</strong>" will be shared with the company <strong>{{companyName}}</strong> and will no longer be modifiable. Are you sure you want to submit the questionnaire now?',
            submissionDate: 'Submission date',
          },
        },
        edit: {
          title: 'Edit',
          submittedAt: 'Submitted on {{datetime}}',
          modal: {
            title: 'Edit questionnaire',
            name: 'Name',
            description: 'Description',
            submissionDate: 'Submission date',
          },
        },
        saveAlert: {
          title: 'Continue without saving?',
          body: 'You have unsaved changes in your response.',
          continueBtn: 'Continue',
        },
        templates: {
          title: 'Questionnaire templates',
          noTemplates: 'To get started, add a template',
          table: {
            name: 'Name',
            questions: 'Questions',
            created: 'Created',
          },
          new: {
            title: 'Add new template',
            templateName: 'Template name',
            templateNameHelper:
              'How your template will be displayed in the listing',
            templateDescription: 'Description',
            templateDescriptionHelper: 'Add context or notes',
            learnAboutFile: {
              title: 'Question file',
              steps: {
                one: 'The file format must be CSV (Comma Separated Values). This format is widely used and can be opened or exported by any spreadsheet editor, such as Excel or Google Sheets.',
                two: 'It must have only one column: <strong>question</strong>.',
                three:
                  'The first row of the file needs to contain the header with the value in the first column. This row will be ignored during loading.',
              },
            },
          },
          upload: {
            success: 'Questionnaire template uploaded successfully!',
          },
          remove: {
            success: 'Questionnaire template removed successfully.',
            modal: {
              title: 'Remove questionnaire template?',
              body: 'The template <strong>"{{templateName}}"</strong> will be removed from Unxpose',
            },
          },
          edit: {
            success: 'Questionnaire template edited successfully.',
            modal: {
              title: 'Edit questionnaire template',
              name: 'Name',
              description: 'Description',
            },
          },
        },
        updateSuccess: 'Changes have been successfully saved!',
        questionReferences: {
          references: 'References',
          generatedByAI: 'Response generated by AI.',
          openReferences: 'See references',
        },
        history: {
          viewHistoryBtn: 'View history',
          title: 'Questionnaire History',
          by: 'By <strong>{{fullName}} ({{supplierCompany}})</strong>',
          byFor:
            'By <strong>{{fullName}} ({{buyerCompany}})</strong> for the supplier company <strong>{{supplierCompany}}</strong>',
        },
        review: {
          evaluations: {
            meets: 'Meets',
            partiallyMeets: 'Partially meets',
            doesNotMeet: 'Does not meet',
            noEvaluated: 'No evaluated',
          },
          questionBox: {
            boxTitle: 'Review answer',
            evaluationLabel: 'How do you rate the answer?',
            commentsLabel: 'Comments',
            answerEvaluation: 'Answer Evaluation',
            editedAnswer:
              'Answer modified! The questionnaire must be resubmitted for the answers to be re-evaluated.',
          },
          modal: {
            title: 'Review',
            reviewBtn: 'Review',
            body: 'The evaluations and comments will only be shared with the supplier if you request changes.',
            note: 'Once you approve or request changes to a questionnaire, you will no longer be able to evaluate the answers.',
            table: {
              evaluation: 'Evaluation',
              questions: 'Questions',
            },
            approveBtn: 'Approve',
            requestChangesBtn: 'Request changes',
            evaluationTooltip:
              "To request changes, there must be at least one evaluation of 'Partially meets' or 'Does not meet'",
          },
          messages: {
            approvedSuccess:
              'The questionnaire has been successfully approved!',
            changesRequestedSuccess:
              'The supplier has been notified of your review! Please wait while the necessary adjustments are made.',
          },
        },
      },
      documents: {
        availableDocuments: 'Available documents',
        types: {
          pentest: 'Pentest Report',
          securityPolicy: 'Information Security Policy',
          backupPolicy: 'Backup Policy',
          businessContinuityDRPolicy:
            'Business Continuity and Disaster Recovery Policy',
          dataClassificationPolicy: 'Data Classification Policy',
          encryptionPolicy: 'Encryption Policy',
          pentestSummaryReport: 'Penetration test (Pentest) Summary Report',
          privacyPolicy: 'Privacy Policy',
          secIncidentMgmtPolicy: 'Security Incident Management Policy',
          vulnerabilityMgmtPolicy: 'Vulnerability Management Policy',
          changeManagementPolicy: 'Change Management Policy',
          dataRetentionDisposalPolicy: 'Data Retention and Disposal Policy',
          accessControlPolicy: 'Access Control Policy',
          humanResourcesPolicy: 'Human Resources Policy',
          vendorManagementPolicy: 'Vendor Management Policy',
          riskManagementPolicy: 'Risk Management Policy',
          assetManagementPolicy: 'Asset Management Policy',
          authPasswordPolicy: 'Authentication and Password Policy',
          auditLoggingPolicy: 'Audit Logging Policy',
          physicalSecurityPolicy: 'Physical Security Policy',
          acceptableUsePolicy: 'Acceptable Use Policy',
        },
        table: {
          document: 'Document type',
          documentDate: 'Document date',
          uploadedBy: 'Uploaded by',
          uploadDate: 'Upload date',
          downloadFile: 'Download file',
        },
        addNew: {
          addNewDocument: 'Add new',
          documentType: 'Document type',
          documentTypeDetail: 'Choose an option',
          consultancyCompany: 'Consulting company',
          consultancyCompanyDetail: 'Company name',
          executionRange: 'Execution period',
          executionRangeDetail:
            'Period during which the pentest was executed (start and end)',
          generationDate: 'Document version date',
          generationDateDetail: 'Select a date',
          fileTitle: 'File',
          maxFileSize:
            'Only <strong>.pdf, .png and .jpg</strong> files are supported <strong>(up to 40 MB)</strong>',
          onSubmitBtn: 'Submit',
          messages: {
            success: 'Your file has been successfully saved!',
            dragAndDropError:
              'Only PDF, PNG and JPG files are supported <br> and you must upload them one at a time',
          },
        },
        modal: {
          remove: {
            title: 'Remove document?',
            body: 'The document <strong>"{{documentName}}"</strong> will be removed from Unxpose',
            primaryBtn: 'Remove',
            secondaryBtn: 'Cancel',
            success: 'Document removed successfully.',
          },
          removeFeedback: {
            title: 'Error removing document',
            body: 'The document <strong>"{{documentName}}"</strong> from {{documentDate}} could not be removed because it is linked to one or more questionnaires listed below:',
            primaryBtn: 'Ok',
          },
          download: {
            modal: {
              title: 'Download document?',
              body: 'Downloading this file may pose a security risk to your device. Make sure you trust the source of this content.',
              primaryBtn: 'Continue',
              secondaryBtn: 'Cancel',
            },
          },
        },
        advantages: {
          documentsUnavailable:
            'Document viewing is not available on your plan',
          noResults: 'No documents have been registered yet',
          title:
            'Add your cybersecurity documents and maintain an up-to-date inventory',
          items: {
            first:
              'Centralize your security documentation and allow multiple teams to easily access the information',
            second:
              'Ensure that all documents are available for review during assessments and audits',
            third:
              'Attach relevant documents to the responses in the security questionnaires',
          },
          addNewBtn: 'Add new',
        },
      },
      reports: {
        emptyStateTitle: "You don't have any reports yet",
        errors: {
          errorGeneratingReport: 'There was an error generating the report.',
        },
        languages: {
          en: 'English',
          pt: 'Portuguese',
        },
        table: {
          createdBy: 'Created by',
          date: 'Date',
          language: 'Language',
          downloadReport: 'Download report',
        },
        executiveReports: 'Executive reports',
      },
      signUp: {
        primaryBtn: 'Create free account',
        loginCall: 'Already have an account?',
        login: 'Log in',
        policy:
          'I agree that I have read and accepted the <a>Privacy Policy</a>',
        learnMore: 'Want to learn more about Unxpose?',
        goToSite: '<a>Visit our website</a>',
        callToAction:
          'Create your account and start <span>protecting</span> your company',
        subtitle: '100% free. No credit card required.',
        messages: {
          success: 'All good! Check your email to continue.',
          invalidEmail:
            'You need to use your corporate email to create an account at Unxpose.',
          tooManyRequests: 'Ops! Too many requests. Try again later.',
        },
        register: {
          messages: {
            success: 'Registration completed successfully! Log in to continue.',
          },
        },
      },

      scores: {
        progressBar: {
          lowPercentage: `One step at a time and you'll make it to the next grade!`,
          mediumPercentage: `Keep pushing towards the next grade!`,
          highPercentage: `left! You're almost there!`, // there is a percentage number before this text
          perfectScore: `You did it! Keep up the great work to maintain your score!`,
        },
      },
      settings: {
        subscription: {
          domains: 'Domains',
          unlimited: {
            domains: 'Unlimited domains',
            idpIntegrations: 'Unlimited identity provider integrations',
            cloudIntegrations: 'Unlimited cloud provider integrations',
          },
          idpIntegrations: 'Identity provider integrations',
          cloudIntegrations: 'Cloud provider integrations',
          upgradeBtn: 'Upgrade',
          upgradeModal: {
            title: 'Unlock more features in your plan',
            body: 'Upgrade your plan and gain access to more Unxpose features to make the most out of our platform. Enhance your experience now!',
            primaryBtn: 'Contact sales',
            secondaryBtn: 'Maybe later',
            paperformTitle: 'Contact sales and upgrade your plan',
          },
        },
        tabs: {
          company: 'Company',
          members: 'Members',
          profile: 'Profile',
          security: 'Security',
          notifications: 'Notifications',
          auditLogs: 'Audit Logs',
          apiKeys: 'API Keys',
        },
        advanced: {
          title: 'Advanced',
          monitoring: {
            title: 'Monitoring preferences',
            subtitle: 'Define how you want your assets to be monitored',
            cloudflare: {
              label: 'Ignore Cloudflare additional ports',
              description:
                'For assets using Cloudflare, monitoring will occur only on ports 80 and 443. Other ports will be ignored, and their findings will be closed.',
            },
            aws: {
              label: 'Ignore additional port for AWS S3 buckets',
              description:
                'For assets using AWS S3 buckets, monitoring will occur only on port 445. Other ports will be ignored, and their findings will be closed.',
            },
          },
        },
        company: {
          placeholders: {
            select: 'Select',
          },
          dropdown: {
            10: '1 - 10',
            50: '11 - 50',
            200: '51 - 200',
            500: '201 - 500',
            1000: '501 - 1,000',
            5000: '1,001 - 5,000',
            10000: 'More than 5,000',
          },
          premiumAdvantages: {
            text: 'Subscribe to Unxpose and <strong>unlock all features</strong> to keep your company protected.',
            callToAction: 'Discover advantages',
            subscriptionManagement:
              'Manage your subscription on <strong>Stripe</strong>',
            mySubscriptionBtn: 'My Subscription',
            tryForFree:
              'Try <strong>all Unxpose features</strong> for free for 7 days!',
          },
          trialUser: 'You are on a TRIAL',
          trialUserExpired:
            'Your TRIAL has expired. Subscribe to Unxpose and keep your company safe.',
          paymentFailed:
            'There was a problem with your payment. Access your subscription.',
          inactiveSubscription: 'You do not have an active subscription.',
        },
        members: {
          inviteUserModal: {
            note: 'When the invitation is accepted, this user will have access to all assets related to this company.',
          },
          removeUserModal: {
            title: 'Remove user?',
            bodySingle:
              '<strong>{{userName}}</strong> will be removed and lose access to Unxpose.',
            bodyMultiple:
              '<strong>{{userName}}</strong> is a member of the following companies:',
            options: {
              removeSingle:
                'Remove only from the current company ({{currentCompany}})',
              removeMultiple: 'Remove from ALL listed companies above',
            },
            actionButtons: {
              remove: 'Remove',
              cancel: 'Cancel',
            },
          },
          removeInviteModal: {
            title: 'Remove invite?',
            body: 'Are you sure you want to remove the invitation for email <strong>{{email}}</strong>?',
          },
        },
        errors: {
          selectACondition:
            'Please, select a condition that will trigger this webhook.',
          requiredFields: 'Name and URL are required fields.',
          userAlreadyInvited: '{{email}} is already invited.',
          userAlreadyRegistered: '{{email}} is already registered to Unxpose',
          userAlreadyRegisteredInCompany:
            '{{email}} is already registered to {{company}}',
          inviteInvalidSubscription: `This company does not have a valid subscription. Check your subscription details in Settings -> Company menu.`,
        },
        notifications: {
          placeholders: {
            select: 'Select',
          },
          slack: {
            webhookAlreadyConfigured1: 'You already have a',
            webhookAlreadyConfigured2: 'created to send Slack Notifications.',
            toDisableSlack1:
              'To disable Slack notifications you can simply access the Webhooks tab and disable the corresponding URL. If you still have questions you can always contact us at',
            toDisableSlack2: ', we will be happy to hear from you!',
            notifications:
              'You can receive Unxpose notifications directly in a Slack channel of your choice. This can be achieved by configuring an Incoming Webhook for Slack and pointing one Unxpose webhook to the URL you get from Slack. The following steps will guide you on how this can be configured:',
            stepFourBody:
              'Paste your URL below, choose a condition and we will take care of everything for you.',
            stepFourHeader: 'Give us your Incoming Webhook URL',
            stepOneBody:
              "The first thing you will need is to do is create a Slack app where you will configure the Incoming Webhook. You can select your company's workspace and choose whatever name you want.",
            stepOneHeader: "Create a Slack app (if you don't have one already)",
            stepThreeBody:
              'On the bottom of the page, click on Add New Webhook to Workspace, select the channel you would like to receive notifications on and click on Allow.',
            stepThreeHeader: 'Create an Incoming Webhook',
            stepTwoBody:
              'Select the app you created on Your Apps page, go to the Add features functionality and click on Incoming Webhooks. Then, click on Activate Incoming Webhooks.',
            stepTwoHeader: 'Enable Incoming Webhooks',
          },
        },
        auditLogs: {
          title: 'Audit Logs',
          tableColumns: {
            user: 'User',
            action: 'Action',
            ip: 'IP',
            userAgent: 'User agent',
            dateTime: 'Date',
          },
        },
        success: {
          inviteSent: 'Invite sent to {{email}}',
        },
        apiKeys: {
          title: 'Registered API Keys',
          generateApiKeyBtn: 'Generate API Key',
          columns: {
            name: 'Name',
            keyId: 'Key ID',
            expiration: 'Expiration',
            status: 'Status',
          },
          generatedAt: 'Generated at {{dateTime}}',
          generationModal: {
            title: 'Generate new API key',
            keyName: 'Key name',
            expirationDate: 'Expiration',
            expirationDates: {
              oneMonth: '1 month',
              threeMonths: '3 months',
              sixMonths: '6 months',
              twelveMonths: '12 months',
            },
            generateBtn: 'Generate',
          },
          resultGenerationModal: {
            title: 'Save the key',
            firstParagraph:
              'Keep your key in a safe place. For security, it <strong>will not be shown again</strong>. If you lose it, you will need to generate a new one.',
            secondParagraph:
              'The value below includes the <strong>ID and value</strong> of the key, separated by a colon. The values must be included in the <strong>X-API-KEY</strong> header in the format below. For more information, <a>access the API documentation</a>.',
            closeBtn: 'I have saved the key',
          },
          removeModal: {
            title: 'Remove API key?',
            body: 'Are you sure you want to remove the key <strong>{{keyName}}</strong>?',
          },
          messages: {
            noApiKeys: 'No API keys have been generated',
            generateFirstApiKey: 'Generate your first API key',
            generated: 'The API key was successfully generated!',
            removed: 'The API key was successfully removed!',
          },
        },
        oauth: {
          emailMismatch:
            'The email you are trying to connect does not match your current email.',
          emailNotFound:
            'The user you are trying to connect is not registered.',
          googleConnected: 'Your Google account was connected successfully.',
          microsoftConnected:
            'Your Microsoft account was connected successfully.',
          googleDisconnected:
            'Your Google account was disconnected successfully.',
          microsoftDisconnected:
            'Your Microsoft account was disconnected successfully.',
        },
      },
      status: {
        closed: 'Closed',
        falsePositive: 'Incorrect',
        fixed: 'Fixed',
        irrelevant: 'Irrelevant',
        new: 'Open', // backwards compat
        open: 'Open',
        plural: {
          closed: 'Closed',
          falsePositive: 'Incorrect',
          fixed: 'Fixed',
          irrelevant: 'Irrelevant',
          new: 'Open',
          open: 'Open',
          reopened: 'Reopened',
          revoked: 'Credentials revoked',
          riskAccepted: 'Risk accepted',
          stale: 'Stale',
        },
        reopened: 'Reopened',
        resolved: 'Resolved',
        revoked: 'Credentials revoked',
        riskAccepted: 'Risk accepted',
        stale: 'Stale',
        unreachable: 'Unreachable',
        unrelated: 'Unrelated',
        sent: 'Sent',
        accepted: 'Accepted',
        submitted: 'Submitted',
        pending: 'Pending',
        approved: 'Approved',
        changesRequested: 'Changes requested',
        awaitingReview: 'Waiting for your review {{timestamp}}',
        awaitingEvaluation: 'Waiting for evaluation {{timestamp}}',
        edited: 'The answer has been edited',
        declined: 'Declined',
        expired: 'Expired',
        active: 'Active',
      },
      success: {
        genericSuccess: 'Everything went just as expected.',
      },
      severity: {
        critical: 'Critical',
        high: 'High',
        info: 'Info',
        low: 'Low',
        medium: 'Medium',
      },
      subtitles: {
        changePassword1: 'Use your current password',
        changePassword2: 'to create a new one',
        companyDetails: 'How should we call your company?',
        companySize: 'It will be used to calculate your score',
        contact: 'How to contact you?',
        description: 'Description',
        googleAccount: 'Sign in with Google',
        holdOn: 'We are testing your webhook...',
        microsoftAccount: 'Sign in with Microsoft',
        newWebhook: 'Set up a new webhook',
        noBreachedEmails:
          'There are no breached emails associated with your company right now.',
        noWebhooks: 'Not sure what to do from here?',
        personalInformation: 'Insert your personal details',
        plan: "Check out your plan's details",
        public: "It's a journey",
        setUpPreferences: 'How would you like to be notified?',
        setUpSlack: "Integrate Unxpose's notifications to Slack",
        somethingWrong:
          'Verify your webhook configuration or check your server.',
        taxIdentification: 'It will be used to scan for domains',
        testYourWebhook:
          "First, let's make sure your webhook is working as intended.",
        twoFactorAuthentication: 'Make your account more secure',
        unknownBreachedEmails:
          "We've detected that there are breached emails associated with your company, but couldn't precise which ones.",
        webhookSuccess: "It's working! Now go ahead and save your changes",
      },
      tables: {
        access: 'Access',
        accessKeyAge: 'Access key age',
        actions: 'Actions',
        admin: 'Admin',
        asset: 'Asset',
        attachmentCount: 'Attachment Count',
        autoRenew: 'Auto renew',
        breach: 'Breach',
        bucketName: 'Bucket name',
        createTime: 'Create time',
        createdAt: 'Created at',
        createdBy: 'Created by',
        date: 'Date',
        dateCreated: 'Date created',
        dbName: 'DB name',
        displayName: 'Display name',
        domainName: 'Domain name',
        encrypted: 'Encrypted',
        expiry: 'Expiry',
        false: 'False',
        filterByAssignee: 'Filter by assignee',
        filterByAssignment: 'Filter by assignment',
        filterByCategory: 'Filter by category',
        filterByDomain: 'Filter by domain',
        filterBySeverity: 'Filter by severity',
        filterByStatus: 'Filter by status',
        filterByTitleIn: 'Filter by title in',
        filterByCloudIntegration: 'Filter by cloud integration',
        filterIn: 'Filter in',
        filterStartingWith: 'Filter by entries starting with...',
        filterByTitleOrAsset: 'Filter by title or asset',
        finding: 'Finding',
        host: 'Host',
        instanceClass: 'Instance class',
        instanceId: 'Instance Id',
        instanceStatus: 'Instance status',
        instanceType: 'Instance type',
        items: 'Items',
        lastActivity: 'Last activity',
        lastCheck: 'Last check',
        launchTime: 'Launch time',
        name: 'Name',
        never: 'Never',
        noMatchWithSelectedFilters: 'No results match your selected filters',
        noResultsFound: 'No results found',
        nothingFound: 'Nothing found yet',
        nothingFoundWithSearch: 'Nothing found with this search',
        page: 'Page',
        passwordAge: 'Password Age',
        port: 'Port',
        privateZone: 'Private Zone',
        publicDns: 'Public DNS',
        dnsRecord: 'DNS Record',
        dnsRecords: 'DNS Records',
        records: 'Records',
        region: 'Region',
        resourceName: 'Resource name',
        resourceRecordSetCount: '# Records',
        searchSomethingElse: 'Try searching for something else',
        servicePrincipal: 'Service Principal',
        firstSeen: 'First seen',
        lastSeen: 'Last seen',
        seen: 'Last seen',
        status: 'Status',
        severity: 'Severity',
        title: 'Title',
        transferLock: 'Transfer lock',
        true: 'True',
        tryDifferentFilters: 'Try different filters to find what you need',
        tryOtherTerms: 'Try looking for other terms',
        type: 'Type',
        updatedAt: 'Updated at',
        username: 'username',
        value: 'Value',
        viewLogs: 'View logs',
        volumeId: 'Volume Id',
        volumeType: 'Volume type',
        waitAFewMinutes:
          'It might take a few minutes before something shows up here. Come back later.',
        year: 'Year',
      },
      titles: {
        about: 'About',
        activity: 'Activity',
        addDomain: 'Which domain you want to add?',
        allFindingsFor: 'All findings for',
        assetDiscovery: 'Asset discovery',
        categoryScore: 'Category score',
        changeFindingStatus: {
          title: 'Status change',
          text: 'You are trying to change the status of this finding from {{status}} to {{newStatus}}. It is necessary to inform the reason for the change:',
        },
        changePassword: 'Change password',
        companyDetails: 'Company details',
        companySize: 'Company size',
        completeRegistration: 'Complete your registration',
        contact: 'Contact',
        unresolvedFindingsBySeverity: 'Unresolved findings by severity',
        goodNews: 'Good news',
        googleAccount: 'Google account',
        googleAccountConnected: 'Your Google account is connected.',
        holdOn: 'Hold on',
        inviteMembers: 'Invite members to collaborate',
        microsoftAccount: 'Microsoft Account',
        microsoftAccountConnected: 'Your Microsoft account is connected',
        newWebhook: 'New webhook',
        noResultsFound: 'No results found',
        noWebhooks: "You haven't set up any webhooks yet.",
        password: 'Password',
        personalInformation: 'Personal information',
        plan: 'Plan',
        yourPlan: 'Your plan',
        public: 'Security is not a project',
        rawData: 'Raw data',
        references: 'References',
        remediation: 'Remediation',
        scoreByCategory: 'Score by category',
        securityScore: 'Security score',
        nextSteps: `What's Next`,
        securityScoreOverTime: 'Security score over time',
        setUpPreferences: 'Set up your preferences',
        setUpSlack: 'Set up Slack',
        solution: 'Solution',
        somethingWrong: 'Oops, something is wrong',
        taxIdentification: 'Tax identification',
        teamMembers: 'Team members',
        sentInvites: 'Sent invites',
        testYourWebhook: 'Test your webhook',
        totalVsClosed: 'Total and closed findings over time',
        twoFactorAuthentication: 'Two-factor authentication',
        webhookSuccess: 'Your webhook has been successfully tested',
        whatsImportant: "What's important",
        watchDemo: 'Watch demo',
      },
      warnings: {
        accessUsingDesktop:
          'For a more complete visualization access using a desktop computer.',
        comeBackInMinutes: 'Come back in a few minutes',
        comeBackLater: 'Come back later!',
        contactUs: 'Any questions? Contact us at',
        domainRemoval:
          'By removing <strong>{{hostname}}</strong> domain, you are going to also remove all the records, subdomains, web applications and findings related to it. Are you sure you want to continue?',
        followUs1: 'Meanwhile, follow our page on',
        followUs2: 'and visit our',
        followUs3:
          "You'll find some useful content about cybersecurity and best practices in developing secure applications",
        generatingReport: 'Generating report. This might take a minute...',
        googleAccountNotConnected1: 'Your Google account is not connected.',
        googleAccountNotConnected2:
          'If your registered email address is a Google account, you can',
        googleAccountNotConnected3: 'connect it to our plataform',
        insufficientInformation: 'Insufficient information',
        keepWebhookSecret:
          "Keep it secret, keep it safe. Your webhook URL contains a secret. Don't share it online, especially on public version control repositories.",
        microsoftAccountNotConnected1:
          'Your Microsoft account is not connected.',
        microsoftAccountNotConnected2:
          'If your registered email address is a Microsoft account, you can',
        microsoftAccountNotConnected3: 'connect it to our plataform',
        passwordConfirmationDontMatchRegister: 'Passwords do not match',
        passwordConfirmationDontMatch:
          'New password and confirmation do not match',
        planExpiredBody1:
          "But don't worry, we still want to keep you and your company safe. Shall we?",
        planExpiredBody2:
          'In order to activate your plan and keep your company safe against cyberattacks, request contact and one of our experts will get in touch.',
        planExpiredHeader: 'your plan has expired!',
        planExpiredListItem1:
          'Non-stop monitoring, so you are always protected.',
        planExpiredListItem2:
          'Accessible tutorials that will make the correction process much quicker and easier.',
        planExpiredListItem3:
          'The expertise of an cybersecurity team just a few clicks away.',
        planExpiredListTitle:
          'Keep in mind the perks of having Unxpose on your team:',
        planExpiredSuccess: 'Our experts will get in touch soon',
        selectOneOption: 'Please, select one of the following options',
        twoFactorAuthenticationEnabled:
          'You have Two-factor authentication enabled. If you want to disable it (not recommended), provide your current password below.',
        updateTerms:
          'We have updated our Terms and Conditions. You need to accept it in order to keep using our services.',
        warning: 'Warning',
      },
      web: {
        errors: {
          invalidName: 'Invalid web application name.',
          urlNotResponding: 'This URL did not respond on the last check',
          webAppNotResponding:
            'There are no known URLs pointing to this web application. If this persists for a week, the related findings will be closed.',
        },
        tables: {
          product: 'Product',
          total: 'Total',
          version: 'Version',
          versions: 'Versions',
          webAppsWith: 'Web applications with',
        },
        applicationNotWorking: 'This application is not working anymore',
        deleteUrlConfirmation:
          "Delete this URL? You won't be able to access its content anymore.",
        host: 'Host',
        identifiedWebApplications: 'Identified web applications',
        mergeDuplicates: 'Merge duplicates',
        mergeWebApps: 'Merge web apps',
        mergedWebApps: 'Merged web apps',
        noFindingsInWebApp: 'There are no findings in this web app right now.',
        noImportantFindings:
          'There are no important findings in your web apps right now.',
        path: 'Path',
        port: 'Port',
        protocol: 'Protocol',
        selectWebApps: 'Select two or more web apps to merge',
        unmergeUrlsConfirmation:
          'Are you sure you want to unmerge all these URLs?',
        urlNotWorking: 'This URL is not working anymore',
        webApplicationDetails: 'Web application details',
        webApplications: 'Web applications',
        webAppsMerged: 'Web apps merged',
        firstSeen: 'First seen:',
        lastSeen: 'Last seen:',
        details: 'Details',
        statusCode: 'Status code: ',
        title: 'Title: ',
        url: {
          key: 'Key',
          value: 'Value',
        },
        entryPoints: 'Entry Points',
        urlQueryString: 'URL query parameters',
        wafBlocked:
          'It looks like there is a WAF blocking our requests. Please, add our IP adresses to your allow list:',
        wildcardTooltip:
          'This web application is hosted on a subdomain that uses a wildcard, so any valued used instead of asterisk will work',
        addWebApps: {
          addWebAppBtn: 'Add web app',
          modalTitle: 'Which web application do you want to add?',
          helperText:
            'Only web apps that are on domains monitored by your organization can be added.',
          urlInput: 'URL',
          messages: {
            invalidUrl: 'Invalid URL.',
            ipsNotAllowed: 'IP addresses are not allowed.',
            unmonitoredDomainerror:
              'You cannot add web apps from domains that are not monitored.',
            domainNotOwned: 'Domain does not belong to this company.',
            noResponseUrl:
              'There is no web app associated with this DNS record.',
            urlExists: 'The {{url}} URL already exists.',
            urlExistsEntryPoint:
              'The URL {{url}} already exists and is pointing to {{entryPoint}}.',
            tooManyRequests:
              'Ops! Too many requests. Please wait a moment and try again.',
            webAppNotLive: 'Web app is not live.',
            serviceBehindCloudflare:
              "This web app cannot be added because the option to ignore Cloudflare's additional ports is enabled.",
            webAppSuccess: 'The web app {{url}} has been added successfully.',
          },
        },
      },

      sortSelect: {
        placeholder: 'Sort by',
        options: {
          highestScore: 'Worst score',
          lowestScore: 'Best score',
          alphabetical: 'A - Z',
        },
      },
      companiesPage: {
        hi: 'Hi',
        searchCompany: 'Search company...',
        accessCompany: 'Access company',
        monitoring: 'Monitoring: ',
        plan: 'Plan: ',
        subscriptionEndDate: 'Subscription end date: ',
      },
      whyUnxpose: {
        mainTitle: 'Why choose <span>Unxpose?</span>',
        bottom: {
          title: "Let's schedule a <span>chat?</span>",
          subtitle:
            "If you're still not sure, you can request access to all features for free for 7 days!",
        },
        firstItem: {
          title: 'Automation = Efficiency',
          paragraph_1:
            "With Unxpose automations, your company can do more with fewer people and in less time. Asset discovery scans and security vulnerability scans run **automatically and continuously**. This means you don't have to worry about scheduling scans or manually adding new assets. Additionally, for each new finding, the solution prioritizes automatically so your team knows where to act first and why.",
          testimonial: {
            text: `"I see Unxpose as an extension of technology and cybersecurity teams because it reduces the chance of human error by automating environment monitoring and also prioritizing what has been found. Thus, our team avoids repetitive work and reduces the time to correct security findings."`,
            authorName: 'Mateus Neves',
            authorRole: 'CTO of Seedz',
          },
        },
        secondItem: {
          title: 'One solution, different security perspectives',
          paragraph_1:
            "Unxpose provides **unified visibility of your company's infrastructure**, offering an external perspective through Attack Surface monitoring, an internal perspective through **Cloud provider monitoring** (AWS, Azure, and GCP), Compliance (LGPD), and **employee behavior** in Google Workspace and Microsoft 365. Moreover, the solution's continuous and automated discovery identifies assets that need protection in a simple manner so you have no blind spots in your company's infrastructure.",
          testimonial: {
            text: `"Unxpose shows what needs to be protected. There's no other solution in the market able to to give such a wide visibility in one single solution, covering Attack Surface Management, Cloud Security and Compliance with a incredible low cost."`,
            authorName: 'Denis Oliveira',
            authorRole: 'Head of Cybersecurity of CAF',
          },
        },
        thirdItem: {
          title: 'Audit and compliance reports in 1 click',
          paragraph_1:
            'You can **generate PDF reports** specially created for external audits that will show the security state of your company. Best of all, 100% of the information shown in Unxpose dashboards is **exportable in CSV** with just one click.',
        },
        fourthItem: {
          title: 'Accelerate sales processes',
          paragraph_1:
            'Reduce the time to respond to **Security Questionnaires** received using Unxpose. Our solution utilizes **Artificial Intelligence to automatically fill in the answers** based on previously answered questionnaires.',
          paragraph_2:
            'Moreover, you can **centralize the entire process** in an intuitive interface that facilitates collaboration with people from different teams, in addition to being able to track progress, attach evidence, and have control over deliveries.',
        },
        fifthItem: {
          title: 'Supplier Management',
          paragraph_1:
            'Centralize your **Supplier Management** using Unxpose. You can send questionnaires to your suppliers, evaluate the responses, and request adjustments with just a few clicks. Eliminate the exchange of spreadsheets via emails and **gain a unified view of your suppliers!**',
        },
        sixthItem: {
          title: 'Unmatched cost-benefit ratio',
          paragraph_1:
            "Starting from **R$ 166,66 / month**, you can subscribe to Unxpose and have Attack Surface and Cloud monitoring, providing visibility of assets and knowledge of security flaws that put your company at risk. In all plans, **the number of users is unlimited**. Affordable pricing for your company to have the work of a security team within a few clicks' reach.",
        },
        seventhItem: {
          title: 'No agent installation, no complicated setup',
          paragraph_1:
            "Unxpose is entirely plug and play. Discovering and monitoring exposed assets on the internet is done by simply registering the company's main domain(s) in the solution. Cloud integration takes 5 minutes via API, following the tutorial provided in the solution. Connecting **Google Workspace or Microsoft 365** takes only 4 clicks. You receive the invitation, create your Unxpose account, and **within minutes have access to the solution's dashboards to protect your company**.",
        },
        eighthItem: {
          title: 'For companies of all sizes. For all types of teams',
          paragraph_1:
            "Unxpose is a security solution that values didacticism and ease of use. You definitely don't need security experts on your team to use the solution. **Unxpose Scoring ranges from A to F and translates the company's exposure level at the moment**, an easily understandable KPI. For each flaw found, the solution provides a step-by-step guide for correction, written so that even a junior developer can execute it.",
        },
        ninthItem: {
          title: 'Close support',
          paragraph_1:
            "Do you have questions about any of Unxpose's findings, are you having difficulty integrating, or are you not sure how to provide the requested information in an audit? The Unxpose team is available promptly to **help you and your company get the most out of the solution** and take the necessary security actions.",
          testimonial: {
            text: `"The Unxpose team combines decades of cybersecurity experience with an innovative product vision."`,
            authorName: 'Cristiano Lincoln',
            authorRole: 'Founder & CEO of Tempest',
          },
        },
      },
      memberGetMember: {
        body: 'How about your company with Unxpose 100% free?',
        button: 'Learn more',
        modal: {
          title: 'Your company with Unxpose 100% free',
          description:
            'By referring Unxpose to a company that subscribes to one of our plans, your organization gets a 10% discount*. The discounts are cumulative and can reach 100% — that is, your company can have Unxpose for free!',
          steps: {
            first: {
              title: 'Invite a company',
              body: 'Ask them to inform us of your email here at Unxpose',
            },
            second: {
              title: 'Get a 10% discount',
              body: 'If the company subscribes to a plan, you already get a 10% discount',
            },
            third: {
              title: 'More companies invited, greater discount',
              body: 'If 10 companies subscribe to a plan, yours will be free!',
            },
          },
          conditions:
            '* The discount will be valid as long as the referred company has an active subscription',
        },
      },
      marketplace: {
        by: 'por',
        startingAt: 'Starting at',
        noServicesFound: 'No services found for this category.',
        hire: 'Request a quote',
        requestQuote: 'Request a quote',
        quoteRequested:
          'Quote requested successfully! You will receive an email with a proposal within 3 days.',
        sharedInfo:
          'The information provided may be shared with partner {{partnerName}}.',
      },
    },
  },
}
