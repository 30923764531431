import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'
import {useNavigate} from 'react-router-dom'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import images from 'res'
import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from 'utils/score'
import {getPropByLocale, viewFindingsByCategory} from 'utils'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import {statusList, tableStyles} from 'constant'
import Timestamp from 'components/partials/Timestamp'

export default function InnerTable(props) {
  const navigate = useNavigate()

  const [filterValue, setFilterValue] = useState('')

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    pageCount,
    preGlobalFilteredRows,
    gotoPage,
    nextPage,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    previousPage,
    state: {pageIndex},
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  function handleEmptyState() {
    if (props.tab === 'findings')
      return (
        <GenericEmptyState
          icon={<images.FindingIcon width="55" color="#90A4AE" />}
          title={
            filterValue
              ? i18next.t('tables.nothingFoundWithSearch')
              : i18next.t('titles.goodNews')
          }
          body={
            filterValue
              ? i18next.t('tables.searchSomethingElse')
              : i18next.t('cloud.noFindingsInService')
          }
          buttonText={i18next.t('buttons.viewAllFindings')}
          linkPath={viewFindingsByCategory('cloud')}
          bgColor={'bg-astral-50'}
          margin="m-2"
        />
      )

    return (
      <GenericEmptyState
        icon={<images.CloudIcon width="55" color="#90A4AE" />}
        title={
          filterValue
            ? i18next.t('tables.nothingFoundWithSearch')
            : i18next.t('titles.noResultsFound')
        }
        body={
          filterValue
            ? i18next.t('tables.searchSomethingElse')
            : i18next.t('cloud.noAssets')
        }
        bgColor="bg-astral-50"
        margin="m-2"
      />
    )
  }

  return (
    <div className="w-full">
      <div className="tableWrap relative">
        <div className="flex justify-between items-center pb-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            value={filterValue}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
          />
          <Pagination
            handlePrevious={previousPage}
            handleNext={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={rows.length || 0}
          />
        </div>
        {!page.length ? (
          handleEmptyState()
        ) : (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>
                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className={`dm-mono text-sideral-300 text-sm`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {column.render('Header').toUpperCase()}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${
                      props.tab === 'findings' ? 'cursor-pointer' : ''
                    } ${tableStyles.tableRow}`}
                    onClick={() => {
                      if (props.tab !== 'findings') return
                      navigate(`/azure/finding/${row.original.id}`)
                    }}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.Header === i18next.t('tables.title')) {
                        return (
                          <td
                            className={cell.column.classes || ''}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <span
                              className={`${getSeverityColor(
                                getSeverityString(cell.row.original.severity)
                              )} severity-indicator inline-flex flex-shrink-0 mr-2`}></span>
                            {cell.value
                              ? getPropByLocale(cell.row.original.title)
                              : '—'}
                          </td>
                        )
                      }
                      if (cell.column.Header === i18next.t('tables.severity')) {
                        return (
                          <td
                            className={cell.column.classes || ''}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value
                              ? getSeverityLabel(getSeverityString(cell.value))
                              : '—'}
                          </td>
                        )
                      }

                      if (cell.column.Header === 'Status') {
                        return (
                          <td
                            className={
                              cell.column.classes + ' capitalize-first' || ''
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? statusList[cell.value] : '—'}
                          </td>
                        )
                      }

                      if (cell.column.Header === i18next.t('tables.seen')) {
                        const moment =
                          cell.row.original.lastNmapScan ||
                          cell.row.original.checkedAt ||
                          cell.row.original.updatedAt
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={cell.column.classes || ''}>
                            <Timestamp date={new Date(moment)} fromNow />
                          </td>
                        )
                      }
                      if (
                        cell.column.Header === i18next.t('tables.value') &&
                        typeof cell.value === 'object'
                      ) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={cell.column.classes}>
                            {Array.isArray(cell.value)
                              ? cell.value.map((value, index) => {
                                  return (
                                    <span key={uuidv4()}>
                                      {typeof value === 'string'
                                        ? value
                                        : JSON.stringify(value)}

                                      {index < cell.value.length - 1
                                        ? ', '
                                        : ''}
                                    </span>
                                  )
                                })
                              : JSON.stringify(cell.value)}
                          </td>
                        )
                      }
                      if (
                        cell.column.id === 'checkedAt' ||
                        cell.column.id === 'createdAt' ||
                        cell.column.id === 'updatedAt' ||
                        cell.column.id.match(/Expiry/i) ||
                        cell.column.id.match(/Date/i) ||
                        cell.column.id.match(/createTime/i) ||
                        cell.column.id.match(/creationTime/i) ||
                        cell.column.id.match(/lastModifiedTime/i) ||
                        cell.column.id.match(/data.CreateTime/i) ||
                        cell.column.id.match(/data.InstanceCreateTime/i) ||
                        cell.column.id.match(/data.LaunchTime/i) ||
                        cell.column.id.match(/password_last_changed/i) ||
                        cell.column.id.match(/timeCreated/i) ||
                        cell.column.id.match(/user_creation_time/i)
                      ) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={cell.column.classes}>
                            {cell.value &&
                            cell.value !== 'N/A' &&
                            cell.value !== 'not_supported' ? (
                              <Timestamp date={new Date(cell.value)} fromNow />
                            ) : (
                              'None'
                            )}
                          </td>
                        )
                      }
                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

InnerTable.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowId: PropTypes.string,
  rowClasses: PropTypes.string,
  tab: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
}
