import i18next from 'i18next'
import config from 'config'
import {getKeyAge, getLastActivity} from 'components/cloud/cloudUtils'
import {toast} from 'react-toastify'
import {getToastAutoCloseTime} from 'utils'

const initialState = {
  filters: {},
  invites: [],
  issues: {},
  loading: {user: true, cloudIntegrations: true},
  user: null,
  webApps: [],
  product: {},
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_NETWORK_STATS_DONE': {
      return Object.assign({}, state, {
        networkStats: action.payload,
      })
    }

    case 'FETCH_NETWORK_IMPORTANT_FINDINGS_DONE': {
      return Object.assign({}, state, {
        networkImportantFindings: action.payload,
      })
    }

    case 'FETCH_FINDINGS_BY_NETWORK_SERVICE_DONE': {
      return Object.assign({}, state, {
        findingsByService: action.payload,
      })
    }

    // Domains
    case 'FETCH_DOMAIN_DONE': {
      return Object.assign({}, state, {
        domain: action.payload,
      })
    }

    case 'FETCH_DOMAINS_BREAKDOWN_DONE': {
      return Object.assign({}, state, {
        domainsBreakdown: action.payload,
      })
    }

    case 'FETCH_DOMAINS_DONE': {
      return Object.assign({}, state, {
        domains: action.payload,
      })
    }

    case 'FETCH_DOMAINS_HOSTNAMES_DONE': {
      return Object.assign({}, state, {
        domainsHostnames: action.payload,
        loading: {...state.loading, domainsHostnames: false},
      })
    }

    case 'FETCH_DOMAINS_BY_REGISTRAR_DONE': {
      return Object.assign({}, state, {
        domainsByRegistrar: action.payload,
        loading: {...state.loading, domainsByRegistrar: false},
      })
    }

    case 'FETCH_DOMAIN_RECORDS': {
      return Object.assign({}, state, {
        loading: {
          ...state.loading,
          domainRecords: {
            ...state.loading.domainRecords,
            [action.payload.domainId]: true,
          },
        },
      })
    }

    case 'FETCH_DOMAIN_FINDINGS_DONE': {
      if (!action.payload || !action.payload.domainId) return state

      return Object.assign({}, state, {
        domainFindings: {
          ...state.domainFindings,
          [action.payload.domainId]: action.payload.findings,
        },
        loading: {
          ...state.loading,
          domainFindings: {
            ...state.loading.domainFindings,
            [action.payload.domainId]: false,
          },
        },
      })
    }

    case 'FETCH_DNS_RECORDS_LOGS': {
      return Object.assign({}, state, {
        loading: {
          ...state.loading,
          dnsRecordsLogs: true,
        },
      })
    }

    case 'FETCH_DNS_RECORDS_LOGS_DONE': {
      if (!action.payload) return state

      return Object.assign({}, state, {
        dnsRecordsLogs: action.payload,
        loading: {
          ...state.loading,
          dnsRecordsLogs: false,
        },
      })
    }

    case 'FETCH_DOMAIN_RECORDS_DONE': {
      if (
        !action.payload ||
        !action.payload.data ||
        !action.payload.data.domainId
      )
        return state

      return Object.assign({}, state, {
        domainRecords: {
          ...state.domainRecords,
          [action.payload.data.domainId]: action.payload,
        },
        loading: {
          ...state.loading,
          domainRecords: {
            ...state.loading.domainRecords,
            [action.payload.data.domainId]: false,
          },
        },
      })
    }

    case 'FETCH_DOMAIN_SUBDOMAINS': {
      return Object.assign({}, state, {
        loading: {
          ...state.loading,
          domainSubdomains: {
            ...state.loading.domainSubdomains,
            [action.payload.domainId]: true,
          },
        },
      })
    }

    case 'FETCH_DOMAIN_SUBDOMAINS_DONE': {
      if (
        !action.payload ||
        !action.payload.data ||
        !action.payload.data.domainId
      )
        return state

      return Object.assign({}, state, {
        domainSubdomains: {
          ...state.domainSubdomains,
          [action.payload.data.domainId]: action.payload,
        },
        loading: {
          ...state.loading,
          domainSubdomains: {
            ...state.loading.domainSubdomains,
            [action.payload.data.domainId]: false,
          },
        },
      })
    }

    case 'CLEAR_NAME_SERVER_DETAILS': {
      return Object.assign({}, state, {
        nameServerDetails: null,
      })
    }

    case 'FETCH_NAME_SERVER_DETAILS_DONE': {
      return Object.assign({}, state, {
        nameServerDetails: action.payload,
      })
    }

    case 'FETCH_NAME_SERVERS_DONE': {
      return Object.assign({}, state, {
        nameServers: action.payload,
      })
    }

    case 'FETCH_HOST_DONE': {
      return Object.assign({}, state, {
        host: {
          ...state.host,
          [action.payload.id]: action.payload,
        },
      })
    }

    case 'FETCH_HOSTS_DONE': {
      return Object.assign({}, state, {
        hosts: action.payload,
      })
    }

    case 'FETCH_HOSTS_ERROR': {
      return Object.assign({}, state, {
        hosts: {data: [], total: 0},
      })
    }

    case 'FETCH_HOST_FINDINGS_DONE': {
      return Object.assign({}, state, {
        hostFindings: action.payload,
      })
    }

    case 'FETCH_HOSTS_BY_AS_ORGS_DONE': {
      return Object.assign({}, state, {
        hostsByAsOrgs: action.payload,
        loading: {...state.loading, hostsByAsOrgs: false},
      })
    }

    case 'FETCH_HOSTS_BY_COUNTRY_DONE': {
      return Object.assign({}, state, {
        hostsByCountry: action.payload,
      })
    }

    case 'FETCH_BREACHES_DONE': {
      action.payload.forEach((breach, i) => {
        if (typeof breach.data === 'string')
          action.payload[i].data = JSON.parse(
            breach.data.replace('{', '[').replace('}', ']')
          )
      })
      return Object.assign({}, state, {
        breaches: action.payload,
      })
    }

    case 'FETCH_PEOPLE_ACCOUNT_DONE': {
      return Object.assign({}, state, {
        peopleAccount: action.payload,
        companyEmails: null,
      })
    }

    case 'FETCH_PEOPLE_ACCOUNT_FINDINGS_DONE': {
      return Object.assign({}, state, {
        peopleAccountFindings: action.payload,
      })
    }

    case 'FETCH_PEOPLE_FINDING_DONE': {
      return Object.assign({}, state, {
        peopleFinding: action.payload,
      })
    }

    case 'FETCH_PEOPLE_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        peopleFindingActivity: action.payload,
      })
    }

    case 'CHECK_DONE': {
      return Object.assign({}, state, {
        results: action.payload,
      })
    }

    case 'FETCH_DNS_FINDING_DONE': {
      return Object.assign({}, state, {
        dnsFinding: action.payload,
      })
    }

    case 'FETCH_DNS_FEED_DONE': {
      return Object.assign({}, state, {
        dnsFeed: action.payload,
      })
    }

    case 'FETCH_DNS_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        dnsFindingActivity: action.payload,
      })
    }

    case 'FETCH_DNS_DONE': {
      return Object.assign({}, state, {
        asset: action.payload,
        loading: Object.assign({}, state.loading, {dns: false}),
      })
    }

    case 'FETCH_TOTAL_VS_CLOSED_ISSUES_DONE': {
      return Object.assign({}, state, {
        issuesStatus: action.payload,
      })
    }

    case 'FETCH_ISSUE_DONE': {
      return Object.assign({}, state, {
        issue: action.payload,
      })
    }

    case 'FETCH_WEB_FINDING_DONE': {
      return Object.assign({}, state, {
        webFinding: action.payload,
      })
    }

    case 'ADD_WEB_APP_DONE': {
      return Object.assign({}, state, {
        addWebAppDone: true,
        loading: {...state.loading, addWebApp: false},
      })
    }

    case 'FETCH_WEB_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        webFindingActivity: action.payload,
      })
    }

    case 'FETCH_NETWORK_FINDING_DONE': {
      return Object.assign({}, state, {
        networkFinding: action.payload,
      })
    }

    case 'FETCH_NETWORK_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        networkFindingActivity: action.payload,
      })
    }

    case 'FETCH_ALL_FINDINGS_COUNT_DONE': {
      return Object.assign({}, state, {
        allFindingsCount: action.payload,
      })
    }

    case 'FETCH_ALL_FINDINGS_DONE': {
      return Object.assign({}, state, {
        allFindings: action.payload,
      })
    }

    case 'FETCH_BULK_FINDINGS_DONE': {
      return Object.assign({}, state, {
        bulkFindings: action.payload,
        loading: Object.assign({}, state.loading, {bulkFindings: false}),
      })
    }

    case 'UPDATE_FINDINGS_BULK_DONE': {
      return Object.assign({}, state, {
        updateFindingsBulk: action.payload,
      })
    }

    case 'FETCH_FINDINGS_BULK_RESULT_DONE': {
      return Object.assign({}, state, {
        bulkFindingsResult: action.payload,
        loading: Object.assign({}, state.loading, {bulkFindingsResult: false}),
      })
    }

    case 'FETCH_ALL_ISSUES_DONE': {
      return Object.assign({}, state, {
        allIssues: action.payload,
      })
    }

    case 'FETCH_ISSUES_DONE': {
      const issues = {}
      issues[action.payload.type] = action.payload.data
      return Object.assign({}, state, {issues})
    }

    case 'FETCH_ISSUES_WEB_DONE': {
      return Object.assign({}, state, {
        issues: Object.assign({}, state.issues, {web: action.payload}),
        loading: Object.assign({}, state.loading, {issues: false}),
      })
    }

    case 'FETCH_ISSUES_DNS_DONE': {
      return Object.assign({}, state, {
        issues: Object.assign({}, state.issues, {dns: action.payload}),
        loading: Object.assign({}, state.loading, {issues: false}),
      })
    }

    case 'REDIRECT_FEEDBACK': {
      if (!action.payload) return state

      const {status, message, path} = action.payload

      if (status === 'success') {
        toast.success(message, {
          toastId: message,
          autoClose: getToastAutoCloseTime(message),
        })

        return Object.assign({}, state, {
          success: true,
          redirectFeedback: {path},
        })
      }
      if (status === 'error') {
        toast.error(message, {
          toastId: message,
          autoClose: getToastAutoCloseTime(message),
        })

        return Object.assign({}, state, {
          error: true,
          redirectFeedback: {path},
        })
      }

      return state
    }

    case 'GENERIC_ERROR': {
      let message, loading
      switch (action.payload.code) {
        case 'FORBIDDEN':
          loading = {user: false}
          message = i18next.t('errors.forbiddenError')
          break

        default:
          loading = {...state.loading, ...action.payload.loading}
          message =
            action.payload.message || i18next.t('errors.unexpectedError')
      }

      toast.error(message, {
        toastId: message,
        autoClose: getToastAutoCloseTime(message),
      })

      return Object.assign({}, state, {
        loading,
        error: true,
        success: false,
        disabled: false,
      })
    }

    case 'CLEAR_ERROR': {
      return Object.assign({}, state, {
        error: null,
      })
    }

    case 'CLEAR_FILTERS': {
      const exception = action.payload && action.payload.except
      const newFilter = Object.entries(state.filters).find(([key]) => {
        return key === exception
      })

      return Object.assign({}, state, {
        filters: !newFilter ? {} : {[newFilter[0]]: newFilter[1]},
      })
    }

    case 'CLEAR_CLOUD_INTEGRATION_FILTERS': {
      return Object.assign({}, state, {
        clearCloudIntegrationFilters: action.payload,
      })
    }

    case 'RETRY_AWS_INTEGRATION_DONE':
    case 'RETRY_GCP_INTEGRATION_DONE':
    case 'STOP_AWS_INTEGRATION_DONE':
    case 'STOP_AZURE_INTEGRATION_DONE':
    case 'STOP_GCP_INTEGRATION_DONE': {
      return Object.assign({}, state, {
        clearCloudIntegrationFilters: true,
      })
    }

    case 'CLEAR_SUCCESS': {
      return Object.assign({}, state, {
        success: null,
      })
    }

    case 'SUCCESS': {
      const message =
        action.payload.message || i18next.t('success.genericSuccess')

      toast.success(message, {
        toastId: message,
        autoClose: getToastAutoCloseTime(message),
      })

      return Object.assign({}, state, {
        loading: {...state.loading, ...action.payload.loading, login: false},
        error: null,
        success: true,
        disabled: false,
      })
    }

    case 'CLEAR_ACTION': {
      return Object.assign({}, state, {
        [action.payload]: null,
      })
    }

    case 'FETCH_USER_DONE': {
      if (typeof window.clarity === 'function') {
        window.clarity('set', 'userId', action.payload?.email)
      }

      return Object.assign({}, state, {
        user: action.payload,
        loading: {...state.loading, user: false},
      })
    }

    case 'FETCH_SERVICE_DONE': {
      return Object.assign({}, state, {
        service: action.payload,
      })
    }

    case 'FETCH_SERVICE_DETAILS_DONE': {
      return Object.assign({}, state, {
        serviceDetails: action.payload,
      })
    }

    case 'FETCH_SERVICE_DETAILS_FINDINGS_DONE': {
      return Object.assign({}, state, {
        serviceDetailsFindings: action.payload,
      })
    }

    case 'FETCH_HOSTS_BY_NETWORK_SERVICE_DONE': {
      return Object.assign({}, state, {
        hostsByService: action.payload,
      })
    }

    case 'FETCH_SERVICES_DONE': {
      return Object.assign({}, state, {
        services: action.payload,
      })
    }

    case 'SELECT_COMPANY': {
      return Object.assign({}, state, {
        selectedCompany: action.payload,
      })
    }

    case 'FETCH_LATEST_CHECK_DONE': {
      return Object.assign({}, state, {
        latestCheck: action.payload,
      })
    }

    case 'FETCH_COMPANYS_DONE': {
      return Object.assign({}, state, {
        companies: action.payload,
        disabled: false,
        error: false,
        loading: Object.assign({}, state.loading, {companies: false}),
      })
    }

    case 'FETCH_COMPANY_DONE': {
      return Object.assign({}, state, {
        company: action.payload,
        loading: {...state.loading, company: false},
        disabled: false,
      })
    }

    case 'FETCH_COMPANY_MEMBERS_DONE': {
      return Object.assign({}, state, {
        companyMembers: action.payload,
      })
    }

    case 'FETCH_COMPANIES_DONE_PAGINATION': {
      return Object.assign({}, state, {
        companiesPagination: action.payload,
      })
    }

    case 'FETCH_BREACHES_OVER_TIME_DONE': {
      return Object.assign({}, state, {
        breachesOverTime: action.payload,
      })
    }

    case 'FETCH_AUDIT_LOGS_DONE': {
      return Object.assign({}, state, {
        auditLogs: action.payload,
      })
    }

    case 'FETCH_NEXT_STEPS_DONE': {
      return Object.assign({}, state, {
        nextSteps: action.payload,
        loading: {...state.loading, nextSteps: false},
      })
    }

    case 'FETCH_SCORE_OVER_TIME_DONE': {
      return Object.assign({}, state, {
        scoreOverTime: action.payload,
      })
    }

    case 'FETCH_COMPANY_SCORES_DONE': {
      return Object.assign({}, state, {
        scores: action.payload.scores,
        loading: {...state.loading, scores: false},
      })
    }

    case 'FETCH_COMPANY_STATS_DONE': {
      switch (action.payload.type) {
        case 'web':
          return Object.assign({}, state, {
            statsWeb: action.payload,
          })
        case 'dns':
          return Object.assign({}, state, {
            statsDns: action.payload,
          })
        case 'people':
          return Object.assign({}, state, {
            statsPeople: action.payload,
          })
        default:
          return Object.assign({}, state, {
            stats: action.payload,
          })
      }
    }

    case 'FETCH_CLOUD_FEED_DONE': {
      return Object.assign({}, state, {
        cloudFeed: action.payload,
        loading: {...state.loading, cloudFeed: false},
      })
    }

    case 'FETCH_CLOUD_INTEGRATIONS_DONE': {
      return Object.assign({}, state, {
        cloudIntegrations: action.payload,
        loading: {...state.loading, cloudIntegrations: false},
      })
    }

    case 'FETCH_ACTIVE_CLOUD_INTEGRATIONS_DONE': {
      return Object.assign({}, state, {
        activeCloudIntegrations: action.payload,
      })
    }

    case 'FETCH_CLOUD_STATS_DONE': {
      return Object.assign({}, state, {
        statsCloud: action.payload,
      })
    }

    case 'FETCH_WEB_STATS_DONE': {
      return Object.assign({}, state, {
        statsWeb: action.payload,
      })
    }

    case 'FETCH_WEB_IMPORTANT_FINDINGS_DONE': {
      return Object.assign({}, state, {
        webImportantFindings: action.payload,
      })
    }

    case 'FETCH_DNS_STATS_DONE': {
      return Object.assign({}, state, {
        statsDns: action.payload,
      })
    }

    case 'LOADING': {
      return Object.assign({}, state, {
        loading: Object.assign({}, state.loading, action.payload),
      })
    }

    case 'ACCEPT_TERMS_DONE': {
      return Object.assign({}, state, {
        termsAccepted: true,
      })
    }

    case 'INVITE_MEMBER': {
      return Object.assign({}, state, {
        disabled: true,
      })
    }

    case 'FETCH_INVITES_DONE': {
      return Object.assign({}, state, {
        invites: action.payload,
        loading: {...state.loading, invites: false},
      })
    }

    case 'FETCH_INVITE_INFO_DONE': {
      return Object.assign({}, state, {
        inviteInfo: action.payload,
        loading: {...state.loading, inviteInfo: false},
      })
    }

    case 'FETCH_BREACHED_ACCOUNTS_DONE': {
      return Object.assign({}, state, {
        breachedAccounts: action.payload,
        loading: {...state.loading, breachedAccounts: false},
      })
    }

    case 'FETCH_FEED_DONE': {
      return Object.assign({}, state, {
        feed: action.payload,
      })
    }

    case 'FETCH_MOST_CRITICAL_DONE': {
      return Object.assign({}, state, {
        mostCritical: action.payload,
      })
    }

    case 'LOGOUT_DONE': {
      if (
        !window.location.pathname.endsWith('/login') &&
        !window.location.pathname.endsWith('/logout') &&
        !window.location.pathname.endsWith('/forgot') &&
        !window.location.pathname.endsWith('/signup')
      ) {
        window.location.href = '/login'
      }

      return Object.assign({}, state, {
        user: null,
      })
    }

    case 'AUTHENTICATE_DONE': {
      if (action.payload.mfa) {
        window.location.href = '/mfa'
        return state
      }

      window.location.href = '/redirect'

      return state
    }

    case 'SAVE_FILTER': {
      return Object.assign({}, state, {
        filter: action.payload,
      })
    }

    case 'FETCH_HAS_CLOUD_INTEGRATION_DONE': {
      const {hasCloudIntegration} = action.payload

      return Object.assign({}, state, {
        hasCloudIntegration,
      })
    }

    case 'FETCH_AWS_INTEGRATIONS_DONE': {
      const ids = action.payload.length
        ? action.payload.map(integration => integration.id)
        : null

      const loadingUpdated = action.payload.length
        ? Object.assign(
            {initialState: false},
            ...ids.map(id => ({[id]: false}))
          )
        : {intialState: false}

      return Object.assign({}, state, {
        awsIntegrations: action.payload,
        loading: {
          ...state.loading,
          awsIntegrations: {
            ...loadingUpdated,
          },
        },
      })
    }

    case 'INTEGRATE_AWS_DONE': {
      setTimeout(() => {
        window.location.href = '/cloud'
      }, 2000)
      return state
    }

    case 'FETCH_AWS_STATS_DONE': {
      const accountId = action.payload.accountId || 'general'
      return Object.assign({}, state, {
        awsStats: {...state.awsStats, [accountId]: action.payload},
        loading: {...state.loading, awsStats: false},
      })
    }

    case 'FETCH_AWS_FINDINGS_DONE': {
      return Object.assign({}, state, {
        awsFindings: action.payload,
      })
    }

    case 'FETCH_AWS_FINDINGS_BY_ASSET_DONE': {
      const {awsAsset, findings} = action.payload

      return Object.assign({}, state, {
        awsFindingsByAsset: {
          ...state.awsFindingsByAsset,
          [awsAsset]: findings,
        },
      })
    }

    case 'FETCH_AWS_MOST_CRITICAL_DONE': {
      return Object.assign({}, state, {
        awsMostCritical: action.payload,
      })
    }

    case 'FETCH_TEMPLATE_DONE': {
      return Object.assign({}, state, {
        template: action.payload,
      })
    }

    case 'FETCH_TEMPLATES_DONE': {
      return Object.assign({}, state, {
        templates: action.payload,
      })
    }

    case 'FETCH_COMPANIES_DONE': {
      return Object.assign({}, state, {
        companies: action.payload,
      })
    }

    case 'FETCH_REPORTS': {
      return Object.assign({}, state, {
        loading: {...state.loading, reports: true},
      })
    }

    case 'FETCH_REPORTS_DONE': {
      return Object.assign({}, state, {
        reports: action.payload,
        loading: {...state.loading, reports: false},
      })
    }

    case 'CLEAR_QR_CODE': {
      return Object.assign({}, state, {
        qrCode: null,
      })
    }

    case 'GENERATE_2FA_DONE': {
      return Object.assign({}, state, {
        qrCode: action.payload,
      })
    }

    case 'GENERATE_REPORT_DONE': {
      return Object.assign({}, state, {
        loading: {...state.loading, generateReport: false},
      })
    }

    case 'FETCH_WEB_APP_DONE': {
      return Object.assign({}, state, {
        webApp: action.payload,
      })
    }

    case 'FETCH_WEB_APPS': {
      return Object.assign({}, state, {
        webApps: [],
      })
    }

    case 'FETCH_WEB_APPS_DONE': {
      return Object.assign({}, state, {
        webApps: action.payload,
      })
    }

    case 'FETCH_WEB_APPS_FINDINGS': {
      return Object.assign({}, state, {
        webApps: [],
      })
    }

    case 'FETCH_WEB_APP_FINDINGS_DONE': {
      return Object.assign({}, state, {
        webAppsFindings: action.payload,
      })
    }

    case 'FETCH_WEB_APPS_BY_PROTOCOL_DONE': {
      return Object.assign({}, state, {
        webAppsByProtocol: action.payload,
        loading: {...state.loading, webAppsByProtocol: false},
      })
    }

    case 'FETCH_WEB_APPS_FINGERPRINTS_DONE': {
      return Object.assign({}, state, {
        webAppsFingerprints: action.payload,
      })
    }

    case 'CLEAR_FINGERPRINT_DETAILS': {
      return Object.assign({}, state, {
        fingerprintDetails: null,
      })
    }

    case 'FETCH_FINGERPRINT_DETAILS_DONE': {
      return Object.assign({}, state, {
        fingerprintDetails: action.payload,
      })
    }

    case 'DELETE_URL_DONE': {
      return Object.assign({}, state, {
        webApp: null,
      })
    }

    case 'VERIFY_SESSION_DONE': {
      return Object.assign({}, state, {
        invite: action.payload,
      })
    }

    case 'FETCH_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        activity: action.payload,
      })
    }

    case 'FETCH_EVENTS_DONE': {
      return Object.assign({}, state, {
        events: action.payload,
      })
    }

    case 'UPDATE_FILTER': {
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          ...action.payload,
        },
      })
    }

    case 'UPDATE_SORT': {
      return Object.assign({}, state, {
        sort: {...state.sort, ...action.payload},
      })
    }

    case 'UPDATE_NOTIFICATION_SETTINGS_DONE': {
      return Object.assign({}, state, {
        user: {...state.user, notifications: action.payload},
      })
    }

    case 'FETCH_WEBHOOKS_DONE': {
      return Object.assign({}, state, {
        webhooks: action.payload,
      })
    }

    case 'FETCH_WEBHOOK_LOGS_DONE': {
      return Object.assign({}, state, {
        webhookLogs: action.payload,
      })
    }

    case 'TEST_WEBHOOK_DONE': {
      return Object.assign({}, state, {
        lastStatus: action.payload,
        loading: {...state.loading, testingWebhook: false},
        webhookTestsCount: state.webhookTestsCount
          ? state.webhookTestsCount + 1
          : 1,
        webhookTested: true,
      })
    }

    case 'REDIRECT_404': {
      window.location.replace('/404')
      return state
    }

    case 'FETCH_CSRF_DONE': {
      return Object.assign({}, state, {
        csrf: action.payload.csrfToken,
      })
    }

    case 'FETCH_EVIDENCE_DONE': {
      return Object.assign({}, state, {
        evidence: action.payload,
      })
    }

    case 'CLEAR_AWS_ASSETS_DATA': {
      return Object.assign({}, state, {
        awsFindingsByAsset: null,
        iamUsers: null,
        s3Buckets: null,
        ec2Instances: null,
        ebsVolumes: null,
        rdsInstances: null,
        route53Domains: null,
        route53HostedZones: null,
      })
    }

    case 'FETCH_AWS_FINDING_DONE': {
      return Object.assign({}, state, {
        awsFinding: action.payload,
      })
    }

    case 'FETCH_AWS_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        awsFindingActivity: action.payload,
      })
    }

    case 'FETCH_AWS_IMPORTANT_FINDINGS_DONE': {
      return Object.assign({}, state, {
        awsImportantFindings: action.payload,
      })
    }

    case 'FETCH_AWS_IAM_POLICIES_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        iamPolicies: {...state.iamPolicies, [accountId]: data},
      })
    }

    case 'FETCH_AWS_USERS_DONE': {
      const {accountId, data} = action.payload

      for (const user of action.payload.data) {
        user.accessKeyAge = getKeyAge(user)
        user.lastActivity = getLastActivity(user)
      }

      return Object.assign({}, state, {
        iamUsers: {...state.iamUsers, [accountId]: data},
      })
    }

    case 'FETCH_CLOUDTRAIL_TRAILS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        cloudtrailTrails: {
          ...state.cloudtrailTrails,
          [accountId]: data,
        },
      })
    }

    case 'FETCH_RDS_INSTANCES_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        rdsInstances: {...state.rdsInstances, [accountId]: data},
      })
    }

    case 'FETCH_RDS_SNAPSHOTS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        rdsSnapshots: {...state.rdsSnapshots, [accountId]: data},
      })
    }

    case 'FETCH_KMS_KEYS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        kmsKeys: {...state.kmsKeys, [accountId]: data},
      })
    }

    case 'GENERATE_API_KEY_DONE': {
      return Object.assign({}, state, {
        generatedApiKey: action.payload,
        loading: {...state.loading, generatingApiKey: false},
      })
    }

    case 'FETCH_API_KEYS_DONE': {
      return Object.assign({}, state, {
        apiKeys: action.payload,
        loading: {...state.loading, apiKeys: false},
      })
    }

    case 'FETCH_EC2_AMIS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        ec2Amis: {...state.ec2Amis, [accountId]: data},
      })
    }

    case 'FETCH_EC2_INSTANCES_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        ec2Instances: {...state.ec2Instances, [accountId]: data},
      })
    }

    case 'FETCH_EC2_SECURITY_GROUPS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        ec2SecurityGroups: {
          ...state.ec2SecurityGroups,
          [accountId]: data,
        },
      })
    }

    case 'FETCH_EC2_VPCS_DONE': {
      for (const vpc of action.payload.data) {
        if (!vpc.data || !vpc.data.Tags || !vpc.data.Tags.length) {
          vpc.data.name = ''
          continue
        }

        const nameObj = vpc.data.Tags.find(tag => tag.Key === 'Name')

        vpc.data.name = nameObj ? nameObj.Value : ''
      }

      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        ec2Vpcs: {...state.ec2Vpcs, [accountId]: data},
      })
    }

    case 'FETCH_EBS_VOLUMES_DONE': {
      const {accountId, data} = action.payload
      return Object.assign({}, state, {
        ebsVolumes: {...state.ebsVolumes, [accountId]: data},
      })
    }

    case 'FETCH_EFS_DONE': {
      const {accountId, data} = action.payload
      return Object.assign({}, state, {
        efs: {...state.efs, [accountId]: data},
      })
    }

    case 'FETCH_S3_BUCKETS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        s3Buckets: {...state.s3Buckets, [accountId]: data},
      })
    }

    case 'FETCH_ROUTE_53_DOMAINS_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        route53Domains: {...state.route53Domains, [accountId]: data},
      })
    }

    case 'FETCH_ROUTE_53_HOSTED_ZONES_DONE': {
      const {accountId, data} = action.payload

      return Object.assign({}, state, {
        route53HostedZones: {...state.route53HostedZones, [accountId]: data},
      })
    }

    case 'CLEAR_GCP_ASSETS_DATA': {
      return Object.assign({}, state, {
        gcpBuckets: null,
        gcpFindingsByAsset: null,
        gcpFindingsByService: null,
        gcpApiKeys: null,
        gcpInstances: null,
        gcpKms: null,
        gcpPrincipals: null,
        gcpServiceAccountKeys: null,
        gcpSqlInstances: null,
      })
    }

    case 'FETCH_GCP_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        gcpFindingActivity: action.payload,
      })
    }

    case 'FETCH_GCP_SQL_INSTANCES_DONE': {
      return Object.assign({}, state, {
        gcpSqlInstances: action.payload,
      })
    }

    case 'FETCH_GCP_BUCKETS_DONE': {
      return Object.assign({}, state, {
        gcpBuckets: action.payload,
      })
    }

    case 'FETCH_GCP_API_KEYS_DONE': {
      return Object.assign({}, state, {
        gcpApiKeys: action.payload,
      })
    }

    case 'FETCH_GCP_PRINCIPALS_DONE': {
      return Object.assign({}, state, {
        gcpPrincipals: action.payload,
      })
    }

    case 'FETCH_GCP_SERVICE_ACCOUNTS_DONE': {
      return Object.assign({}, state, {
        gcpServiceAccounts: action.payload,
      })
    }

    case 'FETCH_GCP_SERVICE_ACCOUNT_KEYS_DONE': {
      return Object.assign({}, state, {
        gcpServiceAccountKeys: action.payload,
      })
    }

    case 'FETCH_GCP_FINDING_DONE': {
      return Object.assign({}, state, {
        gcpFinding: action.payload,
      })
    }

    case 'FETCH_GCP_FINDINGS_DONE': {
      return Object.assign({}, state, {
        gcpFindings: action.payload,
      })
    }

    case 'FETCH_GCP_FINDINGS_BY_ASSET_DONE': {
      const {asset, findings} = action.payload

      return Object.assign({}, state, {
        gcpFindingsByAsset: {
          ...state.gcpFindingsByAsset,
          [asset]: findings,
        },
      })
    }

    case 'FETCH_GCP_MOST_CRITICAL_DONE': {
      return Object.assign({}, state, {
        gcpMostCritical: action.payload,
      })
    }

    case 'FETCH_GCP_KMS_DONE': {
      return Object.assign({}, state, {
        gcpKms: action.payload,
      })
    }

    case 'FETCH_GCP_STATS_DONE': {
      const projectId = action.payload.projectId || 'general'
      return Object.assign({}, state, {
        gcpStats: {...state.gcpStats, [projectId]: action.payload},
        loading: {...state.loading, gcpStats: false},
      })
    }

    case 'FETCH_GCP_INSTANCES_DONE': {
      return Object.assign({}, state, {
        gcpInstances: action.payload,
      })
    }

    case 'FETCH_GCP_INTEGRATIONS_DONE': {
      const ids = action.payload.length
        ? action.payload.map(integration => integration.id)
        : null

      const loadingUpdated = action.payload.length
        ? Object.assign(
            {initialState: false},
            ...ids.map(id => ({[id]: false}))
          )
        : {intialState: false}

      return Object.assign({}, state, {
        gcpIntegrations: action.payload,
        loading: {
          ...state.loading,
          gcpIntegrations: {
            ...loadingUpdated,
          },
        },
      })
    }

    case 'FETCH_GCP_INTEGRATION_DONE': {
      return Object.assign({}, state, {
        gcpIntegration: action.payload,
      })
    }

    case 'UPDATE_GCP_INTEGRATION_VERSION_DONE': {
      return Object.assign({}, state, {
        gcpIntegration: {
          ...state.gcpIntegration,
          version: config.gcpIntegrationVersion,
        },
      })
    }

    case 'INTEGRATE_AZURE_DONE': {
      setTimeout(() => {
        window.location.href = '/cloud'
      }, 2000)
      return state
    }

    case 'FETCH_AZURE_INTEGRATIONS_DONE': {
      return Object.assign({}, state, {
        azureIntegrations: action.payload,
        loading: {...state.loading, azureIntegrations: false},
      })
    }

    case 'FETCH_AZURE_STATS_DONE': {
      const tenantId = action.payload.tenantId || 'general'
      return Object.assign({}, state, {
        azureStats: {...state.azureStats, [tenantId]: action.payload},
        loading: {...state.loading, azureStats: false},
      })
    }

    case 'FETCH_AZURE_BLOB_CONTAINERS_DONE': {
      return Object.assign({}, state, {
        azureBlobContainers: action.payload,
      })
    }

    case 'FETCH_AZURE_DISKS_DONE': {
      return Object.assign({}, state, {
        azureDisks: action.payload,
      })
    }

    case 'FETCH_AZURE_MYSQL_SERVERS_DONE': {
      return Object.assign({}, state, {
        azureMysqlServers: action.payload,
      })
    }

    case 'FETCH_AZURE_POSTGRESQL_SERVERS_DONE': {
      return Object.assign({}, state, {
        azurePostgresqlServers: action.payload,
      })
    }

    case 'FETCH_AZURE_SQL_DATABASES_DONE': {
      return Object.assign({}, state, {
        azureSqlDatabases: action.payload,
      })
    }

    case 'FETCH_AZURE_SQL_SERVERS_DONE': {
      return Object.assign({}, state, {
        azureSqlServers: action.payload,
      })
    }

    case 'FETCH_AZURE_STORAGE_ACCOUNTS_DONE': {
      return Object.assign({}, state, {
        azureStorageAccounts: action.payload,
      })
    }

    case 'FETCH_AZURE_SUBSCRIPTIONS_DONE': {
      return Object.assign({}, state, {
        azureSubscriptions: action.payload,
      })
    }

    case 'FETCH_AZURE_RESOURCE_GROUPS_DONE': {
      return Object.assign({}, state, {
        azureResourceGroups: action.payload,
      })
    }

    case 'FETCH_AZURE_VM_INSTANCES_DONE': {
      return Object.assign({}, state, {
        azureVmInstances: action.payload,
      })
    }

    case 'FETCH_AZURE_MOST_CRITICAL_DONE': {
      return Object.assign({}, state, {
        azureMostCritical: action.payload,
      })
    }

    case 'CLEAR_AZURE_SERVICES_DATA': {
      return Object.assign({}, state, {
        azureBlobContainers: null,
        azureDisks: null,
        azureFindingsByResource: null,
        azureSqlDatabases: null,
        azureSqlServers: null,
        azureStorageAccounts: null,
        azureVmInstances: null,
      })
    }

    case 'FETCH_AZURE_FINDING_DONE': {
      return Object.assign({}, state, {
        azureFinding: action.payload,
      })
    }

    case 'FETCH_AZURE_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        azureFindingActivity: action.payload,
      })
    }

    case 'FETCH_AZURE_FINDINGS_DONE': {
      return Object.assign({}, state, {
        azureFindings: action.payload,
      })
    }

    case 'FETCH_AZURE_FINDINGS_BY_RESOURCE_DONE': {
      const {resourceName, findings} = action.payload

      return Object.assign({}, state, {
        azureFindingsByResource: {
          ...state.azureFindingsByResource,
          [resourceName]: findings,
        },
      })
    }

    case 'FETCH_AZURE_FINDINGS_BY_SERVICE_DONE': {
      const {azureService, findings} = action.payload

      return Object.assign({}, state, {
        azureFindingsByService: {
          ...state.azureFindingsByService,
          [azureService]: findings,
        },
      })
    }

    // Compliance
    case 'FETCH_COMPLIANCE_STATS_DONE': {
      return Object.assign({}, state, {
        complianceStats: action.payload,
      })
    }

    // LGPD

    case 'FETCH_LGPD_INTEGRATIONS_DONE': {
      return Object.assign({}, state, {
        lgpdIntegrations: action.payload,
      })
    }

    case 'FETCH_LGPD_DETAILS_DONE': {
      return Object.assign({}, state, {
        lgpdDetails: action.payload,
      })
    }

    case 'FETCH_LGPD_ITEM_DETAILS_DONE': {
      return Object.assign({}, state, {
        lgpdItemDetails: action.payload,
      })
    }

    case 'FETCH_LGPD_FINDINGS_DONE': {
      return Object.assign({}, state, {
        lgpdFindings: action.payload,
      })
    }

    case 'START_LGPD_MONITORING_DONE': {
      return Object.assign({}, state, {
        loading: {...state.loading, lgpdIntegration: false},
      })
    }

    // Suppliers
    case 'FETCH_SUPPLIERS_DONE': {
      return Object.assign({}, state, {
        suppliers: action.payload,
        loading: {...state.loading, suppliers: false},
      })
    }

    case 'UPDATE_SUPPLIER_DONE': {
      return Object.assign({}, state, {
        updateSupplierDone: action.payload,
      })
    }

    case 'FETCH_SUPPLIER_DETAILS_DONE': {
      return Object.assign({}, state, {
        supplierDetails: action.payload,
        loading: {...state.loading, supplierDetails: false},
      })
    }

    case 'ADD_SUPPLIER_DONE': {
      return Object.assign({}, state, {
        loading: {...state.loading, addSupplier: false},
      })
    }

    case 'FETCH_SUPPLIER_INVITES_DONE': {
      return Object.assign({}, state, {
        supplierInvites: action.payload,
        loading: {...state.loading, supplierInvites: false},
      })
    }

    case 'VERIFY_SUPPLIER_INVITE_DONE': {
      return Object.assign({}, state, {
        verifySupplierInvite: action.payload,
        loading: {...state.loading, verifySupplierInvite: false},
      })
    }

    case 'FETCH_SUPPLIER_SENT_QUESTIONNAIRES_DONE': {
      return Object.assign({}, state, {
        supplierSentQuestionnaires: action.payload,
        loading: {...state.loading, supplierSentQuestionnaires: false},
      })
    }

    // Questionnaires
    case 'FETCH_QUESTIONNAIRE_DETAILS_DONE': {
      return Object.assign({}, state, {
        questionnaireDetails: action.payload,
      })
    }

    case 'FETCH_QUESTIONNAIRE_BASE_DETAILS_DONE': {
      return Object.assign({}, state, {
        questionnaireBaseDetails: action.payload,
        loading: {...state.loading, questionnaireBaseDetails: false},
      })
    }

    case 'FETCH_QUESTIONNAIRES_DONE': {
      return Object.assign({}, state, {
        questionnaires: action.payload,
      })
    }

    case 'UPLOAD_QUESTIONNAIRE_DONE': {
      return Object.assign({}, state, {
        uploadQuestionnaireFeedback: action.payload,
      })
    }

    case 'FETCH_QUESTIONNAIRE_TEMPLATES_DONE': {
      return Object.assign({}, state, {
        questionnaireTemplates: action.payload,
        loading: {...state.loading, questionnaireTemplates: false},
      })
    }

    case 'FETCH_QUESTIONNAIRE_INVITES_DONE': {
      return Object.assign({}, state, {
        questionnaireInvites: action.payload,
        loading: {...state.loading, questionnaireInvites: false},
      })
    }

    case 'ANSWER_FEEDBACK': {
      return Object.assign({}, state, {
        answersFeedback: {
          ...state.answersFeedback,
          [action.payload.questionId]: action.payload.status,
        },
      })
    }

    case 'CLEAR_ANSWER_FEEDBACK': {
      return Object.assign({}, state, {
        answersFeedback: {
          ...state.answersFeedback,
          [action.payload.questionId]: null,
        },
      })
    }

    case 'GENERATE_ANSWER_DONE': {
      return Object.assign({}, state, {
        generatedAnswer: action.payload,
        loading: {...state.loading, generatedAnswer: false},
      })
    }

    case 'FETCH_QUESTION_REFERENCES_DONE': {
      return Object.assign({}, state, {
        questionReferences: action.payload,
        loading: {...state.loading, questionReferences: false},
      })
    }

    case 'FETCH_SUPPLIER_QUESTIONNAIRE_DONE': {
      return Object.assign({}, state, {
        supplierQuestionnaire: action.payload,
        loading: {...state.loading, supplierQuestionnaire: false},
      })
    }

    case 'FETCH_QUESTIONNAIRE_EVENTS_DONE': {
      return Object.assign({}, state, {
        questionnaireEvents: action.payload,
        loading: {...state.loading, supplierQuestionnaire: false},
      })
    }

    case 'ANSWERS_REVIEW_FEEDBACK': {
      return Object.assign({}, state, {
        answersReviewFeedback: {
          ...state.answersReviewFeedback,
          [action.payload.questionId]: action.payload.status,
        },
      })
    }

    case 'CLEAR_ANSWER_REVIEW_FEEDBACK': {
      return Object.assign({}, state, {
        answersReviewFeedback: {
          ...state.answersReviewFeedback,
          [action.payload.questionId]: null,
        },
      })
    }

    case 'QUESTIONNAIRE_REVIEW_DONE': {
      return Object.assign({}, state, {
        questionnaireReviewSuccess: true,
      })
    }

    // Documents
    case 'FETCH_DOCUMENTS_DONE': {
      return Object.assign({}, state, {
        documents: action.payload,
        loading: {...state.loading, documents: false},
      })
    }

    case 'UPLOAD_DOCUMENT_DONE': {
      return Object.assign({}, state, {
        isDocumentUploaded: true,
      })
    }

    case 'REMOVE_DOCUMENT_FEEDBACK': {
      return Object.assign({}, state, {
        removeDocumentFeedback: action.payload,
      })
    }

    case 'FETCH_MICROSOFT_365_INTEGRATIONS_DONE': {
      return Object.assign({}, state, {
        microsoft365Integrations: action.payload,
        loading: {
          ...state.loading,
          microsoft365Integrations: false,
        },
      })
    }

    case 'FETCH_GOOGLE_WORKSPACE_INTEGRATIONS_DONE': {
      return Object.assign({}, state, {
        googleWorkspaceIntegrations: action.payload,
        loading: {
          ...state.loading,
          googleWorkspaceIntegrations: false,
        },
      })
    }

    case 'FETCH_GOOGLE_WORKSPACE_FINDING_DONE': {
      return Object.assign({}, state, {
        googleWorkspaceFinding: action.payload,
      })
    }

    case 'FETCH_GOOGLE_WORKSPACE_FINDING_ACTIVITY_DONE': {
      return Object.assign({}, state, {
        workspaceFindingActivity: action.payload,
      })
    }

    case 'FETCH_COMPANY_EMAILS_DONE': {
      return Object.assign({}, state, {
        companyEmails: action.payload,
        loading: {
          ...state.loading,
          companyEmails: false,
        },
      })
    }

    case 'RELOAD': {
      i18next.changeLanguage(action.payload.locale, err => {
        if (err) return console.trace(err)
        window.location.reload()
      })
      return state
    }

    case 'SHOW_PLAN_RENEWAL_MODAL': {
      return Object.assign({}, state, {
        showPlanRenewalModal: action.payload,
      })
    }

    case 'TOGGLE_RESTRICTION_MODAL': {
      return Object.assign({}, state, {
        toggleRestrictionModal: {
          contentId: action.payload.contentId,
          type: action.payload.type,
          showModal: action.payload.showModal,
        },
      })
    }

    case 'SHOW_AUTO_SUBSCRIPTION_MODAL': {
      return Object.assign({}, state, {
        showAutoSubscriptionModal: action.payload,
      })
    }

    case 'MEMBER_GET_MEMBER_MODAL': {
      return Object.assign({}, state, {
        memberGetMemberModal: {
          showModal: action.payload.showModal,
        },
      })
    }

    case 'REGISTER_DONE': {
      window.dataLayer?.push({
        event: 'selfSignUpCreateAccount',
        email: state.invite.email,
      })

      return state
    }

    case 'FETCH_PRODUCTS_DONE': {
      return Object.assign({}, state, {
        products: action.payload,
        loading: {...state.loading, products: false},
      })
    }

    case 'FETCH_PRODUCT_DONE': {
      return Object.assign({}, state, {
        product: {
          ...state.product,
          [action.payload.id]: action.payload,
        },
        loading: {...state.loading, product: false},
      })
    }

    case 'REQUEST_QUOTE_DONE': {
      return Object.assign({}, state, {
        clearQuoteForm: new Date(),
      })
    }

    default:
      return state
  }
}

export default rootReducer
