import {i18next} from 'translate/i18n'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch, batch} from 'react-redux'
import {useParams, Link} from 'react-router-dom'

import {
  fetchGcpFindings,
  fetchGcpMostCritical,
  fetchGcpStats,
  fetchGcpIntegrations,
  clearFilters,
  clearGcpAssetsData,
} from 'actions'

import {assetsNames} from 'constant'

import config from 'config'

import EmptyState from '../EmptyState'
import Spinner from 'components/partials/Spinner'
import IntegrationStats from '../IntegrationStats'
import WhatsImportant from '../WhatsImportant'
import TableWrapper from 'components/partials/tables/TableWrapper'
import AssetsTable from './AssetsTable'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'

export default function GcpDashboard() {
  const dispatch = useDispatch()
  const gcpStatsState = useSelector(state => state.gcpStats)
  const gcpIntegrationsState = useSelector(state => state.gcpIntegrations)
  const gcpMostCriticalState = useSelector(state => state.gcpMostCritical)

  const params = useParams()

  const planUtils = PlanUtils()

  const [fetchingFeed, setFetchingFeed] = useState(true)

  const [currentIntegration, setCurrentIntegration] = useState({})
  const [findingsByAsset, setFindingsByAsset] = useState([])
  const [visibility, setVisibility] = useState('')

  const configsAreOutdated =
    currentIntegration &&
    parseFloat(currentIntegration.version) <
      Math.floor(config.gcpIntegrationVersion)

  const hasAtLeastOneResource = integrationId => {
    return (
      gcpStatsState &&
      gcpStatsState[integrationId] &&
      Object.values(gcpStatsState[integrationId]).find(value => !!value)
    )
  }

  const mapFindingsByAsset = gcpFindingsByAsset => {
    const assets = Object.entries(gcpFindingsByAsset).map(entry => {
      const [asset, {findings}] = entry
      return {asset, findings, name: assetsNames.gcp[asset]}
    })

    return assets.sort((a, b) => a.name.localeCompare(b.name))
  }

  const assetsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            accessor: 'name',
          },
          {
            accessor: 'findings',
          },
          {
            accessor: 'chevron',
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (currentIntegration && currentIntegration.projectId) {
      batch(() => {
        dispatch(fetchGcpFindings(currentIntegration.projectId))
        dispatch(fetchGcpStats(currentIntegration.projectId))

        if (!planUtils.hasPlanRestriction()) {
          dispatch(fetchGcpMostCritical(currentIntegration.projectId))
        }
      })
    }
  }, [currentIntegration])

  useEffect(() => {
    if (gcpIntegrationsState) {
      const account = gcpIntegrationsState.find(
        integration => integration.projectId === params.id
      )
      setCurrentIntegration(account)
    }
  }, [gcpIntegrationsState])

  useEffect(() => {
    if (gcpMostCriticalState && fetchingFeed) {
      setFetchingFeed(false)
    }
  }, [gcpMostCriticalState])

  useEffect(() => {
    if (
      !gcpStatsState ||
      !gcpStatsState[currentIntegration.projectId] ||
      !gcpStatsState[currentIntegration.projectId].assets
    )
      return

    const arr = mapFindingsByAsset(
      gcpStatsState[currentIntegration.projectId].assets
    )

    setFindingsByAsset(arr)
  }, [gcpStatsState, currentIntegration])

  useEffect(() => {
    batch(() => {
      dispatch(fetchGcpIntegrations())
      dispatch(clearFilters({except: 'gcp'}))
      dispatch(clearGcpAssetsData())
    })
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      {
        // Has active integration but is outdated
        currentIntegration &&
        currentIntegration.status === 'active' &&
        configsAreOutdated ? (
          <div className={visibility}>
            <div
              className="bg-purple-100 border border-purple-400 text-purple-700 px-4 py-3 mb-6 rounded relative text-xs"
              role="alert">
              <Link to={`/cloud/gcp/${currentIntegration.id}/update`}>
                <strong className="font-bold">
                  {i18next.t('cloud.updateAvailable')}
                </strong>
                &nbsp;
                <span className="block sm:inline">
                  {i18next.t('cloud.followOurGuide')}
                </span>
              </Link>
              <span
                className="absolute top-0 bottom-0 right-0 p-3"
                onClick={() => {
                  setVisibility('hidden')
                }}>
                <svg
                  className="fill-current h-5 w-5 text-purple-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )
      }

      {!currentIntegration ||
      !currentIntegration.projectId ||
      !gcpStatsState ? (
        <Spinner />
      ) : (
        <>
          {currentIntegration &&
          currentIntegration.status === 'active' &&
          hasAtLeastOneResource(currentIntegration.projectId) ? (
            <>
              {gcpStatsState && gcpStatsState[currentIntegration.projectId] && (
                <IntegrationStats
                  provider="gcp"
                  integration={currentIntegration}
                  nAssets={
                    gcpStatsState[currentIntegration.projectId]
                      .totalResources || 0
                  }
                  nFindings={
                    gcpStatsState[currentIntegration.projectId].findings || 0
                  }
                  score={
                    gcpStatsState[currentIntegration.projectId].score.grade ||
                    '-'
                  }
                />
              )}
              <WhatsImportant
                provider="gcp"
                feed={gcpMostCriticalState || []}
                cloudAccount={currentIntegration.projectId}
                fetching={fetchingFeed}
              />

              <TableWrapper
                title={i18next.t('misc.assets')}
                loaded={findingsByAsset}
                table={
                  <AssetsTable
                    data={findingsByAsset}
                    columns={assetsColumns}
                    projectId={currentIntegration.projectId}
                    integrationId={currentIntegration.id}
                    pageNumber={0}
                  />
                }
              />
            </>
          ) : (
            <>
              <div>
                <div className="bg-white rounded-sm pt-2 pl-2 pb-1 relative">
                  <i className="icofont-clouds ml-2 mt-2 text-2xl"></i>
                  <span className="text-xs absolute top-0 mt-3 mx-4 uppercase">
                    Google Cloud Platform
                  </span>
                </div>
              </div>
              <EmptyState />
            </>
          )}
        </>
      )}
    </Page>
  )
}
