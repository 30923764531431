import i18next from 'i18next'

export const bucketsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Storage class',
        accessor: 'data.storageClass',
      },
      {
        Header: 'Location',
        accessor: 'data.location',
      },
      {
        Header: 'Location type',
        accessor: 'data.locationType',
      },
      {
        Header: 'Date created',
        accessor: 'data.timeCreated',
      },
    ],
  },
]

export const instancesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Zone',
        accessor: 'data.zone',
      },
      {
        Header: 'Status',
        accessor: 'data.status',
      },
      {
        Header: 'Machine type',
        accessor: 'data.machineType',
      },
      {
        Header: 'Date created',
        accessor: 'createdAt',
      },
    ],
  },
]

export const kmsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Purpose',
        accessor: 'data.purpose',
      },
      {
        Header: 'Protection level',
        accessor: 'data.primary.protectionLevel',
      },
      {
        Header: 'Date created',
        accessor: 'data.createTime',
      },
    ],
  },
]

export const apiKeysColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.displayName'),
        accessor: 'data.displayName',
        width: 80,
      },
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.name',
        width: 80,
      },
    ],
  },
]

export const principalsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.type'),
        accessor: 'type',
        width: 80,
      },
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.displayName',
        width: 80,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
  },
]

export const findingsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.title'),
        accessor: 'title',
      },
      {
        Header: i18next.t('tables.resourceName'),
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 60,
      },
      {
        Header: i18next.t('tables.severity'),
        accessor: 'severity',
        width: 50,
      },
      {
        Header: i18next.t('tables.seen'),
        accessor: 'bestDate',
        classes: 'invisible lg:visible',
        width: 50,
        sortType: (a, b) => {
          return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
        },
      },
    ],
  },
]

export const serviceAccountKeysColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Key algorithm',
        accessor: 'data.keyAlgorithm',
      },
      {
        Header: 'Key type',
        accessor: 'data.keyType',
      },
      {
        Header: 'Valid after',
        accessor: 'data.validAfterTime',
      },
      {
        Header: 'Valid before',
        accessor: 'data.validBeforeTime',
      },
    ],
  },
]

export const sqlInstanceColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Version',
        accessor: 'data.databaseVersion',
      },
      {
        Header: 'Region',
        accessor: 'data.region',
      },
      {
        Header: 'Project',
        accessor: 'data.project',
      },
      {
        Header: 'Date created',
        accessor: 'data.createTime',
      },
    ],
  },
]
