import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import i18next from 'i18next'
import hljs from 'highlight.js'
import {useSelector, useDispatch} from 'react-redux'

import {azurePermissions, borderStyle, integrationStyles} from 'constant'
import images from 'res'
import config from 'config'
import {loading, integrateAzure} from 'actions'

import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import CopyToClipboard from 'components/partials/CopyToClipboard'
import {isLoading} from 'utils'

const Integration = () => {
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()

  const [form, setForm] = useState(null)

  const handleInputChange = event => {
    const {name, value} = event.target
    setForm({...form, [name]: value})
  }

  const handleClickIntegrate = e => {
    e.preventDefault()
    dispatch(loading({azureIntegration: true}))
    dispatch(
      integrateAzure({
        _csrf: csrfState,
        version: config.azureIntegrationVersion,
        config: form,
      })
    )
  }

  const azurePermissionsFormatted = azurePermissions
    .map(x => `"${x}"`)
    .join(',')
    .replace(/,/g, ',\n')

  const copyToClipboard = arg => {
    switch (arg) {
      case 'azurePermissions': {
        return navigator.clipboard.writeText(azurePermissionsFormatted)
      }
    }
  }

  useEffect(() => {
    hljs.highlightAll()
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      <BackButton fallbackLink={'/cloud'} />

      <div className={borderStyle + ' bg-white p-6 mt-4'}>
        <form className="lg:w-8/12" onSubmit={handleClickIntegrate}>
          <div className={integrationStyles.mainTitle}>
            {i18next.t('cloud.azure.integration.title')}
          </div>

          <div className="my-4 text-sm">
            <p className="py-1">
              {i18next.t('cloud.azure.integration.intro.inOrderTo')}
            </p>
            <p className="py-1">
              {i18next.t('cloud.azure.integration.intro.theFirstThing')}
            </p>
            <p className="py-1">
              {i18next.t('cloud.azure.integration.intro.toCreateAn')}{' '}
              <strong>
                {' '}
                {i18next.t(
                  'cloud.azure.integration.intro.highlights.userAdministrator'
                )}
              </strong>{' '}
              {i18next.t('cloud.azure.integration.intro.roleForTheDirectory')}{' '}
              <strong>
                {i18next.t(
                  'cloud.azure.integration.intro.highlights.userAccessAdministrator'
                )}
              </strong>{' '}
              {i18next.t(
                'cloud.azure.integration.intro.roleForTheSubscription'
              )}
            </p>

            <h3 className={integrationStyles.secondaryTitle}>
              {i18next.t('cloud.azure.integration.stepOne.title')}
            </h3>
            <ol className="list-decimal ml-8 my-2">
              <li>
                {i18next.t('cloud.azure.integration.stepOne.accessThe')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepOne.highlights.microsoftEntraId'
                  )}
                </strong>
                {i18next.t('cloud.azure.integration.stepOne.page')}.
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepOne.clickOnThe')}{' '}
                <strong>
                  {i18next.t('cloud.azure.integration.stepOne.highlights.add')}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepOne.buttonAndThen')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepOne.highlights.appRegistration'
                  )}
                </strong>
                <img
                  src={images.appRegistration}
                  className={integrationStyles.image}
                />
              </li>
              <li>{i18next.t('cloud.azure.integration.stepOne.enterAName')}</li>
              <li>
                {i18next.t('cloud.azure.integration.stepOne.forTheSupported')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepOne.highlights.accountsInThis'
                  )}
                </strong>
                <img
                  src={images.accountTypes}
                  className={integrationStyles.image}
                />
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepOne.clickOn')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepOne.highlights.register'
                  )}
                </strong>
              </li>
            </ol>

            <h3 className={integrationStyles.secondaryTitle}>
              {i18next.t('cloud.azure.integration.stepTwo.title')}
            </h3>
            <ol className="list-decimal ml-8 my-2">
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.inTheAzureActive')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.appRegistrations'
                  )}
                </strong>
                {i18next.t('cloud.azure.integration.stepTwo.menu')}.
                <img
                  src={images.appRegistrations}
                  className={integrationStyles.image}
                />
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.clickApplication')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.clickMenu')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.manage'
                  )}
                </strong>{' '}
                {i18next.t('cloud.azure.integration.stepTwo.andThen')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.certificatesAndSecrets'
                  )}
                </strong>
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.clickOn')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.newClientSecret'
                  )}
                </strong>
                <img
                  src={images.newClientSecret}
                  className={integrationStyles.image}
                />
              </li>
              <li>
                {' '}
                {i18next.t('cloud.azure.integration.stepTwo.enterADescription')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.selectTheDesired')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.clickOn')}{' '}
                <strong>
                  {i18next.t('cloud.azure.integration.stepTwo.highlights.add')}
                </strong>
                . {i18next.t('cloud.azure.integration.stepTwo.theClientSecret')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepTwo.recordTheClient')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.value'
                  )}
                </strong>
                . {i18next.t('cloud.azure.integration.stepTwo.youWillUse')}
                <strong>
                  {' '}
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.value'
                  )}
                </strong>{' '}
                {i18next.t('cloud.azure.integration.stepTwo.andNotThe')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepTwo.highlights.secretId'
                  )}
                </strong>
                <img
                  src={images.secretValue}
                  className={integrationStyles.image}
                />
                <div className="mt-4 mb-8 text-mars-700">
                  <h3 className="font-semibold">
                    {i18next.t('cloud.azure.integration.stepTwo.warningTitle')}
                  </h3>
                  {i18next.t('cloud.azure.integration.stepTwo.warningBody')}
                  <strong>
                    {i18next.t(
                      'cloud.azure.integration.stepTwo.highlights.value'
                    )}
                  </strong>
                  .
                </div>
              </li>
            </ol>

            <h3 className={integrationStyles.secondaryTitle}>
              {i18next.t('cloud.azure.integration.stepThree.title')}
            </h3>

            <h4 className={integrationStyles.thirdTitle}>
              {i18next.t('cloud.azure.integration.stepThree.one')}
            </h4>

            <p className="py-2">
              {i18next.t('cloud.azure.integration.stepThree.intro')}
            </p>

            <ol className="list-decimal ml-8 my-2">
              <li>
                {i18next.t('cloud.azure.integration.stepThree.inThe')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.subscriptions'
                  )}
                </strong>{' '}
                {i18next.t('cloud.azure.integration.stepThree.pageClick')}
                <img
                  src={images.subscriptionsPage}
                  className={integrationStyles.image}
                />
              </li>
              <li>
                {i18next.t(
                  'cloud.azure.integration.stepThree.clickOnTheAccess'
                )}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.accessControl'
                  )}
                </strong>
                {i18next.t('cloud.azure.integration.stepThree.menu')}.
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepThree.clickOn')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.add'
                  )}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepThree.andThen')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.addCustomRole'
                  )}
                </strong>
                <img
                  src={images.customRole}
                  className={integrationStyles.image}
                />
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepThree.createARole')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepThree.clickOnTheTab')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.json'
                  )}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepThree.tabAndThen')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.edit'
                  )}
                </strong>
                {i18next.t('cloud.azure.integration.stepThree.button')}
                .
                <img src={images.jsonTab} className={integrationStyles.image} />
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepThree.addTheFollowing')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.propertiesActions'
                  )}
                </strong>
                <pre className="mt-3 mb-6">
                  <CopyToClipboard
                    content={azurePermissionsFormatted}
                    onClick={() => copyToClipboard('azurePermissions')}
                  />
                </pre>
                <img
                  src={images.permissionsJson}
                  className={integrationStyles.image}
                />
              </li>

              <li>
                {i18next.t(
                  'cloud.azure.integration.stepThree.clickOnTheButton'
                )}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.save'
                  )}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepThree.buttonForThe')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepThree.clickOn')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.reviewCreate'
                  )}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepThree.andThenClick')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepThree.highlights.create'
                  )}
                </strong>
                .
              </li>
              <li>
                {i18next.t(
                  'cloud.azure.integration.stepThree.unfortunatelyItMight'
                )}
              </li>
            </ol>

            <h4 className={integrationStyles.thirdTitle}>
              {i18next.t('cloud.azure.integration.stepThree.two')}
            </h4>

            <ol className="list-decimal ml-8 my-2">
              <li>
                {i18next.t('cloud.azure.integration.stepFour.inThePage')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.subscriptions'
                  )}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepFour.pageClick')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.clickOnThe')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.accessControl'
                  )}
                </strong>
                {i18next.t('cloud.azure.integration.stepFour.menu')}.
                <img src={images.iamMenu} className={integrationStyles.image} />
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.clickOn')}{' '}
                <strong>
                  {i18next.t('cloud.azure.integration.stepFour.highlights.add')}{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepFour.andThen')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.addRoleAssignment'
                  )}
                </strong>
                <img
                  src={images.addRoleAssignment}
                  className={integrationStyles.image}
                />
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.selectTheRole')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.next'
                  )}
                  .{' '}
                </strong>
                {i18next.t('cloud.azure.integration.stepFour.ifYouCantFind')}
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.on')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.assignAccessTo'
                  )}
                </strong>{' '}
                {i18next.t('cloud.azure.integration.stepFour.sectionSelect')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.userGroupOrService'
                  )}
                </strong>
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.inTheSection')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.members'
                  )}
                </strong>{' '}
                {i18next.t('cloud.azure.integration.stepFour.sectionClick')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.selectMembers'
                  )}
                </strong>
                .
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.onThe')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.select'
                  )}
                </strong>{' '}
                {i18next.t('cloud.azure.integration.stepFour.fieldType')}{' '}
                <strong>
                  {i18next.t(
                    'cloud.azure.integration.stepFour.highlights.select'
                  )}
                </strong>
                {i18next.t('cloud.azure.integration.stepFour.button')}.
              </li>
              <li>
                {i18next.t('cloud.azure.integration.stepFour.clickOn')}{' '}
                <strong>
                  {i18next.t('cloud.azure.integration.stepFour.reviewCreate')}
                </strong>
              </li>
            </ol>

            <p className="py-2">
              {i18next.t('cloud.azure.integration.stepThree.conclusion')}
            </p>

            <h3 className={integrationStyles.secondaryTitle}>
              {i18next.t('cloud.azure.integration.stepFive.title')}
            </h3>

            <div className="my-4">
              {i18next.t('cloud.azure.integration.stepFive.nowThatYou')}
            </div>
            <div className="my-4">
              {i18next.t('cloud.azure.integration.stepFive.youCanEasily')}{' '}
              <strong>
                {i18next.t(
                  'cloud.azure.integration.stepFive.highlights.appRegistrations'
                )}{' '}
              </strong>
              {i18next.t('cloud.azure.integration.stepFive.andClickingOn')}
            </div>

            <img src={images.findingIds} className={integrationStyles.image} />

            <div className="my-4">
              {i18next.t(
                'cloud.azure.integration.stepFive.findingTheApplication'
              )}
              <ul className="list-disc my-4 ml-8">
                <li>
                  {i18next.t('cloud.azure.integration.stepFive.type')}{' '}
                  <strong>
                    {i18next.t(
                      'cloud.azure.integration.stepFive.highlights.appRegistrations'
                    )}{' '}
                  </strong>
                  {i18next.t('cloud.azure.integration.stepFive.inTheSearch')}
                </li>
                <li>
                  {i18next.t(
                    'cloud.azure.integration.stepFive.clickOnApplication'
                  )}
                </li>
                <li>
                  {i18next.t(
                    'cloud.azure.integration.stepFive.copyTheCredentials'
                  )}
                </li>
              </ul>
            </div>

            <div className="mt-8">
              <PrimaryInput
                text={i18next.t(
                  'cloud.azure.integration.stepFive.applicationId'
                )}
                type="text"
                placeholder={i18next.t(
                  'cloud.azure.integration.stepFive.uuidFound'
                )}
                required={true}
                name="clientId"
                onChange={handleInputChange}
              />
              <PrimaryInput
                text={i18next.t('cloud.azure.integration.stepFive.directoryId')}
                type="text"
                placeholder={i18next.t(
                  'cloud.azure.integration.stepFive.uuidFound'
                )}
                required={true}
                name="tenantId"
                onChange={handleInputChange}
              />

              <PrimaryInput
                text={i18next.t('cloud.azure.integration.stepFive.secretValue')}
                type="text"
                placeholder={i18next.t(
                  'cloud.azure.integration.stepFive.randomValue'
                )}
                required={true}
                name="secret"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="w-full mt-10 md:w-56 md:ml-auto">
            <PrimaryButton
              text={i18next.t(
                'cloud.azure.integration.stepFive.monitorMyAccount'
              )}
              type="submit"
              theme="blue"
              loading={isLoading(loadingState.azureIntegration, false)}
            />
          </div>
        </form>
      </div>
    </Page>
  )
}

Integration.propTypes = {
  history: PropTypes.object,
}

export default Integration
