import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import {useSelector} from 'react-redux'
import {useParams, Link} from 'react-router-dom'

import {allowedStatuses} from 'constant'
import {i18next} from 'translate/i18n'

import ContentBox from 'components/partials/ContentBox'
import GenericFinding from 'components/GenericFinding'
import NewTabLink from 'components/partials/NewTabLink'
import SeverityChip from 'components/partials/SeverityChipV2'
import WrappedProperty from 'components/partials/WrappedProperty'

import {
  fetchWebFinding,
  fetchWebFindingActivity,
  updateWebFinding,
} from 'actions'

import EyeIcon from 'res/icons/eyeIcon'
import FireIcon from 'res/icons/fireIcon'
import InfoIcon from 'res/icons/infoIcon'
import WebIcon from 'res/icons/webIcon'
import {getPropByLocale} from 'utils'
import Timestamp from 'components/partials/Timestamp'

export default function WebFinding(props) {
  const webFindingState = useSelector(state => state.webFinding)
  const webFindingActivityState = useSelector(state => state.webFindingActivity)

  const [finding, setFinding] = useState(null)
  const [findingActivity, setFindingActivity] = useState(null)
  const params = useParams()

  useEffect(() => {
    if (webFindingState && webFindingState.id === params.id) {
      setFinding(webFindingState)
    }
  }, [webFindingState])

  useEffect(() => {
    if (webFindingActivityState) setFindingActivity(webFindingActivityState)
  }, [webFindingActivityState])

  return (
    <GenericFinding
      {...props}
      allowedStatuses={allowedStatuses}
      fetchFinding={fetchWebFinding}
      fetchFindingActivity={fetchWebFindingActivity}
      finding={finding}
      findingActivity={findingActivity}
      findingBody={FindingBody}
      findingDetails={FindingDetails}
      findingIcon={FindingIcon}
      findingType="web"
      //helpPath={'web'} there is no Web help yet
      setFinding={setFinding}
      setFindingActivity={setFindingActivity}
      title={i18next.t('pageTitles.web')}
      updateFinding={updateWebFinding}
    />
  )
}

const FindingIcon = () => {
  return (
    <div className="w-20 p-4">
      <WebIcon width="60" height="60" color="#90A4AE" />
    </div>
  )
}

const FindingDetails = finding => {
  return (
    <>
      <div className="p-4 md:flex bg-white rounded-lg">
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <InfoIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">{i18next.t('web.host')}:</span>
            <Link
              className="hover:underline"
              to={`/web/app/${finding.webAppId}`}>
              {finding && finding.final && finding.final.host}
            </Link>
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">{i18next.t('web.port')}:</span>
            <Link
              className="hover:underline"
              to={`/web/app/${finding.webAppId}`}>
              {finding && finding.final && finding.final.port}
            </Link>
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('web.protocol')}:
            </span>
            <span className="uppercase">
              <Link
                className="hover:underline"
                to={`/web/app/${finding.webAppId}`}>
                {finding && finding.final && finding.final.protocol}
              </Link>
            </span>
          </div>
          {finding &&
          finding.final &&
          finding.final.path &&
          finding.final.path !== '/' ? (
            <div className="py-1 px-2">
              <span className="font-semibold mr-2">
                {i18next.t('web.path')}:
              </span>
              {finding.final.path}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 px-2 ml-1">
            <FireIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div>
            <WrappedProperty
              label={i18next.t('finding.severity')}
              helpPath="score"
              value={<SeverityChip severity={finding && finding.severity} />}
            />
          </div>
          <div>
            {finding && finding.cvss ? (
              <WrappedProperty
                label="CVSS v3"
                helpPath="cvss-cwe"
                value={finding.cvss}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <EyeIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.firstSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.createdAt} />
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.lastSeen')}
            </span>
            <Timestamp format="LLL" date={finding?.checkedAt} />
          </div>
        </div>
      </div>
    </>
  )
}

const FindingBody = finding => {
  if (!finding) return <></>

  const Description = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? getPropByLocale(finding.template.description)
            : getPropByLocale(finding.description)}
        </ReactMarkdown>
      </div>
    )
  }

  const Solution = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? getPropByLocale(finding.template.solution)
            : getPropByLocale(finding.solution)}
        </ReactMarkdown>
      </div>
    )
  }

  const References = () => {
    return (
      <div className="py-4 rounded-lg template-markdown overflow-auto">
        <ReactMarkdown remarkPlugins={[gfm]} components={{a: NewTabLink}}>
          {finding && finding.template
            ? finding.template.references
            : finding.references}
        </ReactMarkdown>
      </div>
    )
  }

  return (
    <>
      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.about')}
          content={<Description />}
        />
      </div>

      {finding.solution ? (
        <div className="mb-6">
          <ContentBox
            header={i18next.t('titles.solution')}
            content={<Solution />}
          />
        </div>
      ) : (
        <></>
      )}

      {finding.references ? (
        <div className="mb-6">
          <ContentBox
            header={i18next.t('titles.references')}
            content={<References />}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
