import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'
import images from 'res'
import {useDispatch, useSelector} from 'react-redux'
import {fetchFindingsBulkResult, loading, updateFindingsBulk} from 'actions'
import {getSeverityColor} from 'utils/score'
import {sideral300, statusList} from 'constant'
import PrimaryTable from 'components/partials/tables/PrimaryTable'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import config from 'config'
import Spinner from 'components/partials/Spinner'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {getPropByLocale, isLoading} from 'utils'

const StepThree = ({bulkForm, setProcessCompleted}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const bulkFindingsState = useSelector(state => state.bulkFindings)
  const updateFindingsBulkState = useSelector(state => state.updateFindingsBulk)
  const bulkFindingsResultState = useSelector(state => state.bulkFindingsResult)

  const [findingIdsToBulkChange, setFindingIdsToBulkChange] = useState([])
  const [findingsBulkChanged, setFindingsBulkChanged] = useState([])
  const [progress, setProgress] = useState(0)
  const [showResultDelay, setShowResultDelay] = useState(false)
  const [showResult, setShowResult] = useState(false)

  // prevent the user from accessing step 3 when refreshing
  useEffect(() => window.history.replaceState({}, '', '/management'), [])

  useEffect(() => {
    if (findingIdsToBulkChange.length > 0)
      dispatch(
        updateFindingsBulk({
          findingIds: findingIdsToBulkChange,
          action: {
            justification: bulkForm.acceptRiskJustification,
            status: bulkForm.newStatus,
            type: 'updateStatus',
          },
          _csrf: csrfState,
        })
      )
  }, [findingIdsToBulkChange])

  useEffect(() => {
    if (bulkFindingsState) {
      setFindingIdsToBulkChange(
        bulkFindingsState.findings.map(finding => finding.id)
      )
    }
  }, [bulkFindingsState])

  useEffect(() => {
    if (updateFindingsBulkState) {
      const jobId = updateFindingsBulkState.jobId

      const endpoint = `${config.SERVER_URL}/findings/bulkChange/${jobId}/progress`
      const source = new EventSource(endpoint, {
        withCredentials: true,
      })

      source.onmessage = e => {
        const progress = e.data

        setProgress(progress)

        if (progress === '100') {
          dispatch(loading({bulkFindingsResult: true}))
          dispatch(fetchFindingsBulkResult({jobId}))

          setShowResultDelay(true)
        }
      }

      return () => source.close()
    }
  }, [updateFindingsBulkState])

  useEffect(() => {
    if (bulkFindingsResultState) setFindingsBulkChanged(bulkFindingsResultState)
  }, [bulkFindingsResultState])

  useEffect(() => {
    if (!isLoading(loadingState.bulkFindingsResult) && showResultDelay) {
      setShowResult(true)
      setProcessCompleted(true)
    }
  }, [loadingState.bulkFindingsResult, showResultDelay])

  const columns = useMemo(
    () => [
      {
        id: 'title',
        Header: i18next.t('management.bulkChange.stepThree.table.title'),
        accessor: 'title',
        minWidth: 250,
        Cell: cell => (
          <div className="flex items-center">
            <div
              className={`${getSeverityColor(
                cell.row.original.severity
              )} severity-indicator flex-shrink-0 mr-2`}
            />
            <div className="break-all truncate">
              {getPropByLocale(cell.value)}
            </div>
          </div>
        ),
      },
      {
        id: 'previousStatus',
        Header: i18next.t(
          'management.bulkChange.stepThree.table.previousStatus'
        ),
        accessor: 'before',
        maxWidth: 80,
        Cell: cell => statusList[cell.value],
      },
      {
        id: 'currentStatus',
        Header: i18next.t(
          'management.bulkChange.stepThree.table.currentStatus'
        ),
        accessor: 'after',
        maxWidth: 80,
        Cell: cell => statusList[cell.value],
      },
      {
        id: 'changeStatus',
        Header: i18next.t('management.bulkChange.stepThree.table.changeStatus'),
        accessor: 'err',
        maxWidth: 100,
        Cell: cell => (
          <div className="flex justify-center">
            {cell.value ? (
              <div className="w-20 bg-red-100 text-red-700 border border-red-400 rounded-md">
                {i18next.t('management.bulkChange.stepThree.fail')}
              </div>
            ) : (
              <div className="w-20 bg-green-100 text-green-700 border border-green-400 rounded-md">
                {i18next.t('management.bulkChange.stepThree.success')}
              </div>
            )}
          </div>
        ),
      },
      {
        id: 'observations',
        Header: i18next.t('management.bulkChange.stepThree.table.observations'),
        accessor: 'err',
        minWidth: 160,
        alignClass: 'text-left',
        Cell: cell => {
          let observation

          if (cell.value) {
            switch (cell.value.code) {
              case 'INVALID_FLOW':
                observation = i18next.t(
                  'management.bulkChange.stepThree.errorMsg.invalidFlow',
                  {
                    oldStatus:
                      statusList[cell.row.original.before].toLowerCase(),
                    newStatus: statusList[bulkForm.newStatus].toLowerCase(),
                  }
                )
                break

              case 'FINDING_NOT_SUPPORTED':
                observation = i18next.t(
                  'management.bulkChange.stepThree.errorMsg.people'
                )
                break

              default:
                observation = cell.value.message
            }
          }

          return observation
        },
      },
    ],
    []
  )

  const getOperationSummaryMsg = () => {
    let successQty = 0
    let failQty = 0
    let finalMsg = ''

    findingsBulkChanged.forEach(item => {
      if (item.err) failQty++
      else successQty++
    })

    if (successQty > 0) {
      finalMsg += i18next.t('management.bulkChange.stepThree.changeSuccess', {
        qty: successQty,
      })
    } else
      finalMsg += i18next.t('management.bulkChange.stepThree.noFindingsChanged')

    if (failQty > 0)
      finalMsg += i18next.t('management.bulkChange.stepThree.changeFailure', {
        qty: failQty,
      })

    return finalMsg
  }

  return (
    <div id="body">
      <div className="relative h-80 bg-astral-50 border-b py-20 text-center">
        <div
          id="in-progress-container"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-out ${
            showResult ? 'opacity-0' : 'opacity-1'
          } `}>
          <div>
            <div className="font-medium text-5xl">{progress}%</div>
            <div className="m-auto w-64">
              <div className="h-3 bg-sky-900/25 rounded-lg">
                <div
                  className="h-3 bg-sky-900 rounded-lg transition-width duration-300 ease-out"
                  style={{width: `${progress}%`}}
                />
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="font-medium text-xl">
              {i18next.t('management.bulkChange.stepThree.title', {
                qty: findingIdsToBulkChange.length,
              })}
            </div>
            <div className="text-xs mt-1">
              {i18next.t('management.bulkChange.stepThree.subtitle')}
            </div>
          </div>
        </div>

        <div
          id="progress-completed-container"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 transition-all duration-300 ease-out ${
            showResult
              ? 'opacity-1 -translate-y-1/2'
              : 'opacity-0 -translate-y-1/3'
          } `}>
          <i className="icofont-check-circled text-8xl text-sky-900" />
          <div className="mt-4">
            <div className="font-medium text-xl text-sky-900">
              <div className="text-2xl">
                {i18next.t(
                  'management.bulkChange.stepThree.operationCompleted'
                )}
              </div>
              <div>{getOperationSummaryMsg()}</div>
            </div>
            <div className="text-xs mt-1">
              {i18next.t('management.bulkChange.stepThree.seeDetails')}
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <PrimaryButton
              text={i18next.t('management.bulkChange.stepThree.goToFindings')}
              onClick={() => navigate('/management')}
              size="sm"
            />
          </div>
        </div>
      </div>

      {!isLoading(loadingState.bulkFindingsResult) &&
        (showResult ? (
          <div className="mt-10 px-8">
            <PrimaryTable
              data={findingsBulkChanged}
              columns={columns}
              pageNumber={0}
              pageSize={10}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.ManagementIcon width="50" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin="m-2 mt-4"
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.ManagementIcon width="50" color={sideral300} />}
                  title={i18next.t('tables.nothingFound')}
                  body={i18next.t('management.bulkChange.nothingFound')}
                  margin="m-2 mt-4"
                />
              }
            />
          </div>
        ) : (
          <div className="pt-8">
            <Spinner />
          </div>
        ))}
    </div>
  )
}

export default StepThree

StepThree.propTypes = {
  bulkForm: PropTypes.object,
  processCompleted: PropTypes.bool,
  setProcessCompleted: PropTypes.func,
}
