import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'

import AwesomeDebouncePromise from 'awesome-debounce-promise'

import Spinner from 'components/partials/Spinner'

import {
  clearCloudIntegrationFilters,
  fetchAwsStats,
  fetchAzureStats,
  fetchCloudIntegrations,
  fetchGcpStats,
} from 'actions'

import {
  astral900,
  coral400,
  mars300,
  sideral300,
  sky300,
  sky50,
  summer300,
  summer700,
} from 'constant'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import IntegrationFailed from './IntegrationFailed'
import IntegrationStarted from './IntegrationStarted'
import Pagination from 'components/partials/tables/Pagination'
import ProviderIntegration from './ProviderIntegration'
import Select from 'react-select'
import images from 'res'
import {reactSelectStyles, reactSelectTheme} from 'constant'
import {isLoading} from 'utils'

const PAGE_INDEX = 0
const PAGE_SIZE = 3

export default function Providers({
  searchFilter,
  setSearchFilter,
  selectedProviderFilter,
  setSelectedProviderFilter,
}) {
  const dispatch = useDispatch()

  const loadingState = useSelector(state => state.loading)
  const cloudIntegrationsState = useSelector(state => state.cloudIntegrations)
  const clearCloudIntegrationFiltersState = useSelector(
    state => state.clearCloudIntegrationFilters
  )

  const [loading, setLoading] = useState(true)
  const [pageIndex, setPageIndex] = useState(PAGE_INDEX)
  const [componentLoaded, setComponentLoaded] = useState(false)

  const chartData = {
    options: {
      tooltip: {
        marker: {show: false},
        x: {show: false},
        style: {
          colors: sideral300,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          fontSize: 12,
        },
      },
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      title: {
        text: i18next.t('titles.unresolvedFindingsBySeverity'),
        offsetX: 10,
        style: {
          fontWeight: 'regular',
          color: astral900,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        },
      },
      yaxis: {
        show: false,
      },
      colors: [coral400, mars300, summer700, summer300, sky300],
      dataLabels: {enabled: false},
      grid: {
        borderColor: sky50,
      },
    },
  }

  const providersProperties = {
    aws: {
      text: i18next.t('cloud.accountId'),
      prop: 'accountId',
    },
    gcp: {
      text: i18next.t('cloud.projectId'),
      prop: 'projectId',
    },
    azure: {
      text: i18next.t('cloud.directoryId'),
      prop: 'tenantId',
    },
  }

  const styles = {
    providerContainer: 'border border-sideral-50 rounded-lg mt-4',
    logoContainer: 'w-10 mr-4',
    idContainer: 'flex justify-between items-center border-b text-sm px-4 h-16',
    idText: 'font-light ml-1',
    providerBody:
      'flex flex-wrap md:flex-nowrap items-center p-4 min-h-[200px]',
    statsBoxesContainer:
      'flex items-center md:justify-start justify-around w-full md:w-auto mt-4 md:mt-0 ml-auto',
    statsBox:
      'flex flex-wrap md:w-20 items-center justify-center rounded-lg border p-2 mr-4',
    statsBoxText: 'w-full text-center text-sm font-light',
    chartContainer: 'w-full -ml-4 mr-6 h-32',
  }

  // WILL BE IMPLEMENTED LATER IN THE API
  // const handleChangeSortBy = selectedItem => {
  //   setSelectedSortBy(selectedItem)
  //   setSortBy([{id: 'status', desc: selectedItem.value === 'desc'}])

  //   setPageIndex(PAGE_INDEX)
  // }

  const getCloudIntegrations = (
    searchFilter,
    selectedProviderFilter,
    pageIndex = PAGE_INDEX
  ) => {
    setLoading(true)

    const params = {
      filterValue: searchFilter,
      filterOptions: {
        providerFilter: selectedProviderFilter.map(item => item.value),
      },
      limit: PAGE_SIZE,
      pageIndex,
    }

    dispatch(fetchCloudIntegrations(params))
  }

  const handleChangeProviderFilter = selectedItems => {
    setSelectedProviderFilter(selectedItems)
    getCloudIntegrations(searchFilter, selectedItems)
  }

  const handlePrevious = () => {
    const prevPageIndex = pageIndex - 1
    getCloudIntegrations(searchFilter, selectedProviderFilter, prevPageIndex)
  }

  const handleNext = () => {
    const nextPageIndex = pageIndex + 1
    getCloudIntegrations(searchFilter, selectedProviderFilter, nextPageIndex)
  }

  const debouncedGetCloudIntegrations = useMemo(() => {
    return AwesomeDebouncePromise(getCloudIntegrations, 1000)
  }, [])

  useEffect(() => setComponentLoaded(true), [])

  useEffect(() => {
    setPageIndex(cloudIntegrationsState.currentPage - 1)
    setLoading(false)
  }, [cloudIntegrationsState])

  useEffect(() => {
    if (clearCloudIntegrationFiltersState) {
      setSearchFilter('')
      setSelectedProviderFilter([])

      dispatch(clearCloudIntegrationFilters(false))
    }

    cloudIntegrationsState?.data?.forEach(integration => {
      if (integration.status !== 'active') return

      switch (integration.provider) {
        case 'aws':
          dispatch(fetchAwsStats(integration.integrationName))
          break
        case 'azure':
          dispatch(fetchAzureStats(integration.integrationName))
          break
        case 'gcp':
          dispatch(fetchGcpStats(integration.integrationName))
          break
      }
    })
  }, [cloudIntegrationsState])

  useEffect(() => {
    if (!componentLoaded) return

    setLoading(true)

    debouncedGetCloudIntegrations(searchFilter, selectedProviderFilter)
  }, [searchFilter])

  const providerFilterOptions = [
    {
      label: i18next.t('cloud.filterByProvider.options.aws'),
      value: 'aws',
    },
    {
      label: i18next.t('cloud.filterByProvider.options.gcp'),
      value: 'gcp',
    },
    {
      label: i18next.t('cloud.filterByProvider.options.azure'),
      value: 'azure',
    },
  ]

  // WILL BE IMPLEMENTED LATER IN THE API
  // const sortByOptions = [
  //   {
  //     label: i18next.t('cloud.sortBy.options.bestScore'),
  //     value: 'asc',
  //   },
  //   {
  //     label: i18next.t('cloud.sortBy.options.worstScore'),
  //     value: 'desc',
  //   },
  // ]

  return isLoading(loadingState.cloudIntegrations) ? (
    <Spinner />
  ) : (
    <div className="p-6 mb-2">
      <div className="flex justify-between">
        <div className="left-side flex">
          <div id="integration-search">
            <GlobalFilter
              globalFilter={searchFilter}
              customHandleFilter={e => setSearchFilter(e.target.value)}
              totalPages={cloudIntegrationsState.total}
            />
          </div>
          <div id="provider-filter" className="mx-3 text-xs">
            <Select
              isMulti
              placeholder={i18next.t('cloud.filterByProvider.placeholder')}
              styles={reactSelectStyles}
              options={providerFilterOptions}
              theme={reactSelectTheme}
              value={selectedProviderFilter}
              onChange={handleChangeProviderFilter}
              className="w-48"
            />
          </div>
          {/* WILL BE IMPLEMENTED LATER IN THE API */}
          {/* <div id="sort-by" className="text-xs">
          <Select
            placeholder={i18next.t('cloud.sortBy.placeholder')}
            styles={reactSelectStyles}
            options={sortByOptions}
            theme={reactSelectTheme}
            value={selectedSortBy}
            onChange={handleChangeSortBy}
          />
        </div> */}
        </div>
        <div className="right-side">
          <div id="pagination">
            <Pagination
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              canPreviousPage={
                cloudIntegrationsState.total !== 0 && pageIndex !== 0
              }
              canNextPage={
                cloudIntegrationsState.total !== 0 &&
                pageIndex + 1 < cloudIntegrationsState.pages
              }
              pageIndex={pageIndex}
              pageCount={cloudIntegrationsState.pages}
              total={cloudIntegrationsState.total}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div style={{height: '745px', marginTop: '100px'}}>
          <Spinner />
        </div>
      ) : cloudIntegrationsState.data.length ? (
        cloudIntegrationsState.data.map(integration => (
          <ProviderIntegration
            key={integration.id}
            integration={integration}
            IntegrationStarted={IntegrationStarted}
            IntegrationFailed={IntegrationFailed}
            providersProperties={providersProperties}
            chartData={chartData}
            styles={styles}
          />
        ))
      ) : (
        <GenericEmptyState
          icon={<img src={images.SearchIcon} className="w-12" />}
          title={i18next.t('tables.nothingFoundWithSearch')}
          body={i18next.t('tables.searchSomethingElse')}
          margin="mt-6"
        />
      )}
    </div>
  )
}

Providers.propTypes = {
  searchFilter: PropTypes.string,
  setSearchFilter: PropTypes.func,
  selectedProviderFilter: PropTypes.array,
  setSelectedProviderFilter: PropTypes.func,
}
